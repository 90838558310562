import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core'
import { Router } from '@angular/router'
import { UserLogin } from '@models/api'
import { ResponseStatus } from '@models/misc/Response'
import { ApiService } from '@services/api.service'
import { CacheService } from '@services/cache.service'
import { log } from '@utils/log'
import { isAfter, isValid } from 'date-fns'
import { Observable, Subject, firstValueFrom, interval, takeUntil } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class SessionService implements OnDestroy {
	@Output()
	public userAuthRequired = new EventEmitter<null>()

	@Output()
	public userLoggedIn = new EventEmitter<null>()

	@Output()
	public userLoggedOut = new EventEmitter<null>()

	private _authTimer$?: Observable<number>

	private _stopAuthTimer$?: Subject<boolean>

	// Computed Properties
	// --------------------------------------------------

	public get refreshToken() {
		return this._getStorageItem('refresh-token')
	}

	public set refreshToken(refreshToken: string | null) {
		this._setStorageItem('refresh-token', refreshToken)
	}

	public get token() {
		return this._getStorageItem('token')
	}

	public set token(token: string | null) {
		this._setStorageItem('token', token)
	}

	public get tokenExpires() {
		return this._getStorageItem('token-expires')
	}

	public set tokenExpires(date: string | null) {
		this._setStorageItem('token-expires', date)
	}

	public get username() {
		return this._getStorageItem('username')
	}

	public set username(username: string | null) {
		this._setStorageItem('username', username)
	}

	// Lifecycle Methods
	// --------------------------------------------------

	constructor(
		private _apiService: ApiService,
		private _cacheService: CacheService,
		private _router: Router,
	) {
		if (this.isLoggedIn()) {
			this._startAuthTimer()
		}
	}

	public ngOnDestroy() {
		this._stopAuthTimer()
	}

	// Public Methods
	// --------------------------------------------------

	public isLoggedIn() {
		const hasUsername = this.username ? true : false
		const hasValidToken = this.isTokenValid()

		return hasUsername && hasValidToken
	}

	public isTokenValid() {
		const expires = this.tokenExpires

		if (!expires) return false

		const expiryDate = new Date(expires)

		if (!isValid(expiryDate)) return false

		return isAfter(expiryDate, new Date())
	}

	public async login(
		username: string,
		password: string,
	): Promise<ResponseStatus> {
		try {
			if (!username || !password) {
				throw new Error('Username or password is missing')
			}

			const res$ = this._apiService.post<UserLogin>(`/users/login`, {
				username,
				password,
				grantType: 'password',
			})

			const data = await firstValueFrom(res$)
			log(data)

			this.refreshToken = data.refreshToken
			this.token = data.token
			this.tokenExpires = data.expires
			this.username = data.username

			this.userLoggedIn.emit()
			this._startAuthTimer()

			return 'success'
		} catch (error: any) {
			return 'fail'
		}
	}

	public logout(redirect = true) {
		this._clearStorage()
		this._stopAuthTimer()
		this._cacheService.clear()
		this.userLoggedOut.emit()

		if (redirect) {
			this._router.navigate(['/login'])
		}
	}

	public async renewToken(): Promise<ResponseStatus> {
		try {
			if (!this.username || !this.refreshToken) {
				throw new Error('Username or refresh token is missing')
			}

			const res$ = this._apiService.post<UserLogin>(`/users/refresh-token`, {
				username: this.username,
				refreshToken: this.refreshToken,
			})

			const data = await firstValueFrom(res$)
			log(data)

			this.refreshToken = data.refreshToken
			this.token = data.token
			this.tokenExpires = data.expires
			this.username = data.username

			return 'success'
		} catch (error: any) {
			return 'fail'
		}
	}

	// Private Methods
	// --------------------------------------------------

	private _clearStorage() {
		this.refreshToken = null
		this.token = null
		this.tokenExpires = null
		this.username = null
	}

	private _getStorageItem(key: string) {
		return localStorage.getItem(key)
	}

	private _setStorageItem(key: string, val: string | null) {
		if (val) {
			localStorage.setItem(key, val)
		} else {
			localStorage.removeItem(key)
		}
	}

	private _startAuthTimer() {
		if (this._authTimer$) {
			this._stopAuthTimer()
		}

		log('Starting auth timer')

		this._stopAuthTimer$ = new Subject()

		this._authTimer$ = interval(900000).pipe(takeUntil(this._stopAuthTimer$))

		this._authTimer$.subscribe(async () => {
			if (!this.username || !this.refreshToken) return

			const res = await this.renewToken()

			if (res !== 'success') {
				this.userAuthRequired.emit()
				this._stopAuthTimer()
			}
		})
	}

	private _stopAuthTimer() {
		log('Stopping auth timer')

		this._stopAuthTimer$?.next(true)
		this._stopAuthTimer$?.complete()
		this._stopAuthTimer$ = undefined
		this._authTimer$ = undefined
	}
}
