import { isPlainObject } from 'lodash-es'

export class TemplateFns {
	/*
	* Used to track *ngFor items for improved performance.
	* Import into a component and bind to an *ngFor loop:

	<li *ngFor="let x of x; trackBy: util.trackBy">
		...
	</li>
	*/

	public static trackBy(index: number, item: any): number | string {
		const isObj = isPlainObject(item)

		if (isObj && item.id) {
			return item.id
		}

		if (isObj && item.code) {
			return item.code
		}

		return index
	}

	/*
	* Used to match the value of NgModel with the correct options in a select input.
	* Import into a component and bind to a select input:

	<select [(ngModel)]="XXX" [compareWith]="compare.XXX">
		...
	</select>
	*/

	public static compareByValue(c1: any, c2: any) {
		return c1 === c2
	}

	public static compareByCode(c1: any, c2: any) {
		return c1?.code && c2?.code ? c1.code === c2.code : c1 === c2
	}

	public static compareById(c1: any, c2: any) {
		return c1?.id && c2?.id ? c1.id === c2.id : c1 === c2
	}
}
