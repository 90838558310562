import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { SessionService } from '@services/session.service'

@Component({
	selector: 'ms-header',
	host: {
		class: 'header',
	},
	template: `
		<header class="header__content">
			<ms-overscroll [enableScrollX]="true" [enableScrollY]="false">
				<ms-portal-outlet name="header"></ms-portal-outlet>
			</ms-overscroll>
		</header>

		<nav class="header__controls">
			<ms-search class="header__search"></ms-search>

			<a
				btn-link-icon
				[routerLink]="['/profile']"
				[routerLinkActive]="'btn--active'"
				[tooltip]="$t('common.profile')"
			>
				<ms-icon name="menu-account" />
			</a>

			<button
				btn-link-icon
				type="button"
				[tooltip]="$t('common.logout')"
				(click)="logout()"
			>
				<ms-icon name="menu-logout" />
			</button>
		</nav>
	`,
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent {
	private _sessionService = inject(SessionService)

	public logout() {
		this._sessionService.logout(true)
	}
}
