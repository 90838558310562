import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-btn-toolbar',
	host: {
		class: 'btn-toolbar',
		role: 'toolbar',
	},
	template: `<ng-content />`,
	styleUrls: ['./toolbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToolbarComponent extends BaseComponent implements OnInit {
	@Input('position')
	private _position?: 'start' | 'middle' | 'end' = 'start'

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (this._position) {
			this.renderer.addClass(this.el, `btn-toolbar--${this._position}`)
		}
	}
}
