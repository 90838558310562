import { ProgressBarComponent } from './progress-bar.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [ProgressBarComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class ProgressBarModule {}
