import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class CompressionInterceptor implements HttpInterceptor {
	constructor() {}

	public intercept(request: HttpRequest<any>, next: HttpHandler) {
		// Skip non-api requests
		if (request.url.indexOf('cloudapi.tepari.com') === -1) {
			return next.handle(request)
		}

		request = this._updateHeaders(request)

		return next.handle(request)
	}

	private _updateHeaders(request: HttpRequest<any>) {
		return request.clone({
			// Levels: 0 to 11
			// Types: gzip, deflate, br, none

			setHeaders: {
				'compression-level': '4',
				'compression-type': 'br',
			},
		})
	}
}
