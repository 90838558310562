import { BaseComponent } from '../../abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-counter',
	host: { class: 'counter' },
	template: `
		<div class="counter__count">
			{{ count || 0 }}
		</div>
		@if (label) {
			<div class="counter__label">
				{{ label }}
			</div>
		}
	`,
	styleUrls: ['./counter.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent extends BaseComponent {
	@Input()
	public count?: number

	@Input()
	public label?: string
}
