import { LogoComponent } from './components/logo/logo.component'
import { TextLogoComponent } from './components/text-logo/text-logo.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

const components = [LogoComponent, TextLogoComponent]

@NgModule({
	exports: [...components],
	declarations: [...components],
	imports: [CommonModule, FormsModule, RouterModule],
})
export class LogoModule {}
