import { FormGroupComponent } from '../form-group/form-group.component'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-radio',
	host: {
		class: 'radio',
	},
	template: `
		<label class="radio__wrapper">
			<ng-content
				select="input[type='radio'], input[type='checkbox']"
			></ng-content>

			<ng-content select="ms-visually-hidden, [visually-hidden]"></ng-content>

			<span class="radio__btn"></span>

			<span class="radio__indicator"></span>

			@if (label) {
				<span class="radio__label">
					<ms-label>{{ label }}</ms-label>
				</span>
			}
		</label>
	`,
	styleUrls: ['./radio.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent extends BaseComponent {
	@Input()
	public label?: string
}

@Component({
	selector: 'ms-radio-list',
	host: {
		class: 'radio-list',
	},
	template: `
		@if (label) {
			<ms-label [required]="required">{{ label }}</ms-label>
		}

		<ng-content select="ms-radio"></ng-content>
	`,
	styleUrls: ['./radio.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioListComponent {
	@Input()
	public label?: string

	@Input()
	public required = false
}
