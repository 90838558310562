import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-table-toggle-col',
	host: {
		class: 'toggle-col',
	},
	templateUrl: './table-toggle-col.component.html',
	styleUrls: ['./table-toggle-col.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableToggleColComponent extends BaseComponent implements OnInit {
	private _initialVal: MS.Table.Column[] = []

	@Input()
	public columns: MS.Table.Column[] = []

	@Output()
	public columnsChange = new EventEmitter<MS.Table.Column[]>()

	public orderedCols: MS.Table.Column[] = []

	public selectedCols: MS.Table.Column[] = []

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._initialVal = [...this.columns]
		this.orderedCols = [...this.columns]
		this.selectedCols = [...this.columns]
	}

	public onReorder() {
		this.selectedCols = this._sortCols([...this.selectedCols])
	}

	public apply() {
		this.columnsChange.emit([...this.selectedCols])
	}

	public reset() {
		this.orderedCols = [...this._initialVal]
		this.selectedCols = [...this._initialVal]
		this.apply()
	}

	// Public Methods
	// ----------------------------------------

	public toggleCol(col: MS.Table.Column) {
		const index = this.selectedCols.findIndex((c) => c.field === col.field)

		if (index === -1) {
			this.selectedCols = this._sortCols([...this.selectedCols, col])
		} else {
			this.selectedCols = this.selectedCols.filter((c) => c.field !== col.field)
		}
	}

	public toggleAllCols() {
		if (this.isAllColsSelected()) {
			this.selectedCols = this.orderedCols.filter((c) => this.isColDisabled(c))
		} else {
			this.selectedCols = [...this.orderedCols]
		}
	}

	public isColSelected(col: MS.Table.Column) {
		return this.selectedCols.findIndex((c) => c.field === col.field) !== -1
	}

	public isColDisabled(col: MS.Table.Column) {
		return col.toggleable !== undefined && !col.toggleable
	}

	public isAllColsSelected() {
		return this.selectedCols.length === this._initialVal.length
	}

	public isToggleAllIndeterminate() {
		return (
			this.selectedCols.length > 0 &&
			this.selectedCols.length < this._initialVal.length
		)
	}

	public isToggleAllChecked() {
		return (
			this.selectedCols.length > 0 &&
			this.selectedCols.length === this._initialVal.length
		)
	}

	// Private Methods
	// ----------------------------------------

	private _sortCols(cols: MS.Table.Column[]) {
		return cols.sort((a, b) => {
			const indexA = this.orderedCols.findIndex((c) => c.field === a.field)
			const indexB = this.orderedCols.findIndex((c) => c.field === b.field)

			return indexA - indexB
		})
	}
}
