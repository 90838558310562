import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	Optional,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { RouterLinkActive } from '@angular/router'

@Component({
	selector: 'ms-navbar-link, [navbar-link]',
	host: {
		'class': 'navbar-link',
		'[class.navbar-link--active]': 'active',
		'[class.navbar-link--disabled]': 'disabled',
	},
	template: `<ng-content />`,
	styleUrls: ['./navbar-link.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarLinkComponent extends BaseComponent implements OnInit {
	@Input()
	public active = false

	@Input()
	public disabled = false

	constructor(@Optional() public routerLinkActive?: RouterLinkActive) {
		super()
	}

	public ngOnInit() {
		if (this.routerLinkActive) {
			this.active = this.routerLinkActive.isActive
		}

		this.routerLinkActive?.isActiveChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.active = value
			})
	}
}
