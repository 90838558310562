import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
	input,
} from '@angular/core'

@Component({
	selector: 'ms-stat',
	host: {
		class: 'stat',
	},
	template: `
		@if (stat(); as stat) {
			<div class="stat__value">
				{{ stat.value | number }}

				@if (stat.unit) {
					<small class="stat__unit"> {{ stat.unit }} </small>
				}
			</div>

			<div class="stat__label">
				{{ stat.label }}
			</div>
		}
	`,
	styles: `
		@import 'global/index';

		.stat {
			flex: 0 0 auto;
			min-width: 125px;
			display: block;
			text-align: center;
			white-space: nowrap;
			padding: 20px;

			&__value {
				font-size: 2.4rem;
				font-weight: $font-weight-black;
				color: $color-white;
			}

			&__unit {
				font-size: 1.4rem;
				color: $color-brand-light;
			}

			&__label {
				font-size: 1.4rem;
				font-weight: $font-weight-black;
				color: $color-brand-light;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatComponent extends BaseComponent {
	public stat = input.required<Stat>()
}
