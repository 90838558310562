import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CacheService } from '@services/cache.service'
import { of, share, tap } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
	constructor(private _cacheService: CacheService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.method !== 'GET') {
			return next.handle(req)
		}

		if (req.headers.get('no-cache')) {
			this._cacheService.delete(req.url)
		}

		const cachedResponse: HttpResponse<any> | undefined =
			this._cacheService.get(req.url)

		if (cachedResponse) {
			return of(cachedResponse.clone())
		}

		return next.handle(req).pipe(
			tap((stateEvent) => {
				if (stateEvent instanceof HttpResponse) {
					this._cacheService.set(req.url, stateEvent.clone())
				}
			}),
			share(),
		)
	}
}
