import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	ViewEncapsulation,
	inject,
} from '@angular/core'

@Component({
	selector: 'th[tableExpansion], td[tableExpansion]',
	host: {
		'class': 'table-expansion',
		'[attr.colspan]': 'colspan',
	},
	template: `
		<div class="table-expansion__content" [style.width]="width">
			<ng-content />
		</div>
	`,
	styleUrls: ['./table-expansion.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpansionComponent extends BaseComponent {
	private _tableService = inject(TableService)

	@Input()
	public columnCount: number = 100

	// Computed Properties
	// ----------------------------------------

	public get colspan() {
		let offset = 0

		if (this.enableSelect) offset++
		if (this.enableExpand) offset++

		return this.columnCount + offset
	}

	public get enableExpand() {
		return this._tableService.isRowExpandEnabled
	}

	public get enableSelect() {
		return this._tableService.isRowSelectEnabled
	}

	public get pTable() {
		return this._tableService.pTable
	}

	public get width() {
		const tableWidth = this.pTable?.el?.nativeElement?.offsetWidth ?? 0
		return `${tableWidth}px`
	}

	// Lifecycle Methods
	// ----------------------------------------

	@HostListener('window:resize')
	public onResize() {
		this.cdRef.detectChanges()
	}
}
