import { isNullOrUndefined } from '@utils/is-null-or-undefined'
import { isDate } from 'date-fns'
import { isArray, isFunction, isNumber, isObject, isString } from 'lodash-es'

/**
 * Use this to get the type of a value.
 * Provides a more accurate type than `typeof`.
 *
 * @param value Any value
 * @returns The type of the value
 */
export function getTypeOf(value: any) {
	if (isArray(value)) return 'array'
	if (isDate(value)) return 'date'
	if (isFunction(value)) return 'function'
	if (isObject(value)) return 'object'
	if (isString(value)) return 'string'
	if (isNullOrUndefined(value)) return 'null'
	if (isNumber(value)) return 'number'
	return 'unknown'
}
