import { Injectable } from '@angular/core'
import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	formatISO,
} from 'date-fns'

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor() {}

	public getItem<T>(key: string) {
		try {
			const value = localStorage.getItem(key)

			if (value) {
				return StorageData.revive<T>(JSON.parse(value))
			} else {
				return null
			}
		} catch (error) {
			return null
		}
	}

	public setItem<T>(key: string, value: T) {
		const data = new StorageData<T>(key, value, null)
		data.set()
		return data
	}

	public removeItem(key: string) {
		localStorage.removeItem(key)
	}

	public clear() {
		localStorage.clear()
	}
}

export class StorageData<T> {
	public key: string
	public value: T
	public timestamp: string

	constructor(key: string, value: any, timestamp: string | null) {
		this.key = key
		this.value = value
		this.timestamp = timestamp || this.getTimestamp()
	}

	public static revive<T>(item: StorageData<T>) {
		return new StorageData<T>(item.key, item.value, item.timestamp)
	}

	public set() {
		this.timestamp = this.getTimestamp()
		localStorage.setItem(this.key, JSON.stringify(this))
	}

	public remove() {
		localStorage.removeItem(this.key)
	}

	public getAge(uot: 'seconds' | 'minutes' | 'hours' | 'days' = 'seconds') {
		const lastUpdated = new Date(this.timestamp)

		switch (uot) {
			case 'days':
				return differenceInDays(new Date(), lastUpdated)

			case 'hours':
				return differenceInHours(new Date(), lastUpdated)

			case 'minutes':
				return differenceInMinutes(new Date(), lastUpdated)

			default:
				return differenceInSeconds(new Date(), lastUpdated)
		}
	}

	private getTimestamp(): string {
		return formatISO(new Date())
	}
}
