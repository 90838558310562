import { MenuService } from '../../services/menu.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'
import { SessionService } from '@services/session.service'
import { isLG } from '@utils/breakpoints'

@Component({
	selector: 'ms-menu',
	host: {
		class: 'menu',
	},
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends BaseComponent implements OnInit {
	public collapsed = false

	private _menuService = inject(MenuService)

	private _sessionService = inject(SessionService)

	// Computed Properties
	// ----------------------------------------

	@ViewChild('overlayMenu')
	public get overlayMenu() {
		return this._menuService.overlayMenu
	}

	public set overlayMenu(overlayMenu: OverlayComponent) {
		this._menuService.overlayMenu = overlayMenu
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._menuService.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) => {
				this.collapsed = collapsed
				this.collapsed
					? this.renderer.addClass(this.el, 'menu--collapsed')
					: this.renderer.removeClass(this.el, 'menu--collapsed')
				this.cdRef.markForCheck()
			})
	}

	// Public Methods
	// ----------------------------------------

	public logout() {
		this._sessionService.logout(true)
	}

	@HostListener('window:resize', ['$event'])
	public onResize(e: any) {
		if (isLG() && this.overlayMenu.active) {
			this.overlayMenu.close()
		}

		if (!isLG() && this._menuService.collapsed) {
			this._menuService.collapsed = false
		}
	}
}
