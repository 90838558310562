import { StepComponent } from './components/step/step.component'
import { StepperControlsComponent } from './components/stepper-controls/stepper-controls.component'
import { StepperProgressComponent } from './components/stepper-progress/stepper-progress.component'
import { StepperComponent } from './components/stepper/stepper.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { OverscrollModule } from '@components/overscroll/overscroll.module'

const components = [
	StepComponent,
	StepperControlsComponent,
	StepperProgressComponent,
	StepperComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, ButtonModule, IconModule, OverscrollModule],
})
export class StepperModule {}
