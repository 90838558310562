import { BaseComponent } from '../../abstract/base.component'
import { transition, trigger, useAnimation } from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core'
import { fadeIn, fadeOut } from '@animations/fade.animation'

type InputTheme = 'brand' | 'success' | 'info' | 'warning' | 'danger'

const progressBarAnimation = trigger('progressBarAnimation', [
	transition(
		':leave',
		useAnimation(fadeOut, { params: { timings: '200ms 400ms ease' } }),
	),
	transition(
		':enter',
		useAnimation(fadeIn, { params: { timings: '200ms ease' } }),
	),
])

@Component({
	selector: 'ms-progress-bar',
	host: {
		class: 'progress',
	},
	template: `
		@if (visible) {
			<div [@progressBarAnimation] class="progress__inner">
				<div class="progress__bar"></div>
			</div>
		}
	`,
	styleUrls: ['./progress-bar.component.scss'],
	animations: [progressBarAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent
	extends BaseComponent
	implements OnInit, OnChanges
{
	@Input()
	public progress?: number

	@Input()
	public theme?: InputTheme

	@Input()
	public type: 'determinate' | 'indeterminate' = 'indeterminate'

	@Input()
	public visible = true

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
		this._setProgress()
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.progress) {
			this._setProgress()
		}
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		if (this.theme) {
			this.renderer.addClass(this.el, `progress--${this.theme}`)
		}

		if (this.type) {
			this.renderer.addClass(this.el, `progress--${this.type}`)
		}
	}

	private _setProgress() {
		if (this.type === 'indeterminate') return
		if (!this.progress && this.progress !== 0) return

		this.el.style.setProperty('--progress-percent', `${this.progress}%`)
	}
}
