import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-visually-hidden, [visually-hidden]',
	host: {
		class: 'visually-hidden',
	},
	template: `<ng-content />`,
	styleUrls: ['./visually-hidden.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisuallyHiddenComponent extends BaseComponent {}
