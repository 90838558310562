import { BaseComponent } from '../../abstract/base.component'
import {
	animate,
	animation,
	style,
	transition,
	trigger,
} from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NotificationsService } from '@services/notifications.service'

const notificationAnimation = trigger('notificationAnimation', [
	transition(
		':enter',
		animation([
			style({ opacity: 0, transform: 'translateX(-100px)' }),
			animate(
				'300ms ease-in-out',
				style({ opacity: 1, transform: 'translateX(0%)' }),
			),
		]),
	),
	transition(
		':leave',
		animation([
			animate(
				'300ms ease-in-out',
				style({ opacity: 0, transform: 'translateX(-100px)' }),
			),
			animate('300ms ease', style({ height: 0 })),
		]),
	),
])

@Component({
	selector: 'ms-notifications',
	host: {
		class: 'notifications',
	},
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	animations: [notificationAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent extends BaseComponent implements OnInit {
	constructor(public notificationsService: NotificationsService) {
		super()
	}

	// Computed Properties
	// ----------------------------------------

	public get notifications$() {
		return this.notificationsService.notifications$
	}

	// Lifecycle Properties
	// ----------------------------------------

	public ngOnInit() {
		this.notificationsService.notifications$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.detectChanges()
			})
	}

	// Public Methods
	// ----------------------------------------

	public dismissByIndex(index: number) {
		this.notificationsService.destroy(index)
	}
}
