import { SearchComponent } from './search.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { A11yModule } from '@components/a11y/a11y.module'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { TranslateModule } from '@ngx-translate/core'

const components = [SearchComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		FormsModule,
		A11yModule,
		ButtonModule,
		IconModule,
		TranslateModule,
	],
})
export class SearchModule {}
