import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	ViewEncapsulation,
} from '@angular/core'

type ButtonColor =
	| 'brand'
	| 'success'
	| 'warning'
	| 'danger'
	| 'default'
	| 'none'

@Component({
	template: ``,
	host: {
		'class': 'btn',
		'[attr.aria-disabled]': 'disabled || loading ? "true" : "false"',
		'[attr.disabled]': 'disabled || loading || null',
		'[attr.tabindex]': 'disabled || loading ? -1 : (tabIndex || 0)',
	},
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBaseComponent extends BaseComponent {
	@Input('btn-color')
	public color: ButtonColor = 'default'

	@Input()
	@HostBinding('class.btn--active')
	public active = false

	@Input()
	@HostBinding('class.btn--disabled')
	public disabled = false

	@Input()
	@HostBinding('class.btn--loading')
	public loading = false
}
