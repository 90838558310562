@if (icon) {
	<div class="alert__icon">
		<ms-icon [name]="icon" />
	</div>
}

<div class="alert__content">
	<ng-content />
</div>

@if (dismissable) {
	<button class="alert__close" (click)="dismiss()">
		<ms-icon name="close" />
	</button>
}
