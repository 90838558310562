import { EmbedItemComponent } from './components/embed-item/embed-item.component'
import { EmbedComponent } from './components/embed/embed.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ProgressSpinnerModule } from '@components/progress-spinner/progress-spinner.module'

const components = [EmbedComponent, EmbedItemComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, ProgressSpinnerModule],
})
export class EmbedModule {}
