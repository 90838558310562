import { OverlayComponent } from '../components/overlay/overlay.component'
import { DOCUMENT } from '@angular/common'
import {
	ApplicationRef,
	Inject,
	Injectable,
	Renderer2,
	RendererFactory2,
	ViewContainerRef,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class OverlayService {
	public overlays$ = new BehaviorSubject([] as OverlayComponent[])

	public vcRef?: ViewContainerRef

	private _renderer: Renderer2

	// Computed Properties
	// ----------------------------------------

	public get count() {
		return this.overlays$.value.length
	}

	public get overlays() {
		return this.overlays$.value
	}

	public set overlays(overlays: OverlayComponent[]) {
		this.overlays$.next(overlays)
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		public appRef: ApplicationRef,
		@Inject(DOCUMENT) public document: Document,
		public rendererFactory: RendererFactory2,
	) {
		this._renderer = rendererFactory.createRenderer(null, null)
		this._renderer.listen(this.document, 'keydown', (e) => this._onKeydown(e))
	}

	// Public Methods
	// ----------------------------------------

	public toggle(overlay: OverlayComponent) {
		if (overlay.active) {
			this.close(overlay)
		} else {
			this.open(overlay)
		}
	}

	public open(overlay: OverlayComponent) {
		if (overlay.disabled || overlay.state !== 'closed') return

		overlay.active = true
		overlay.zIndex =
			(this.count >= 1 ? this.overlays[this.count - 1].zIndex : 0) + 1

		this.overlays = [...this.overlays, overlay]
		this._setBodyClass()
	}

	public close(overlay: OverlayComponent) {
		if (overlay.disabled || overlay.state !== 'open') return

		overlay.active = false
		overlay.zIndex = -1

		this.overlays = this.overlays.filter((o) => o.id !== overlay.id)
		this._setBodyClass()
	}

	// Private Methods
	// ----------------------------------------

	private _onKeydown(e: KeyboardEvent) {
		if (e.code !== 'Escape') return

		const overlay = this.overlays[this.count - 1]

		if (overlay) {
			this.close(overlay)
		}
	}

	private _setBodyClass() {
		if (this.overlays.length) {
			this._renderer.addClass(this.document.body, 'overlay-open')
		} else {
			this._renderer.removeClass(this.document.body, 'overlay-open')
		}
	}
}
