import { NotificationsComponent } from './notifications.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AlertModule } from '@components/alert/alert.module'

const components = [NotificationsComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, AlertModule],
})
export class NotificationsModule {}
