import { HeaderComponent } from './components/header/header.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { OverscrollModule } from '@components/overscroll/overscroll.module'
import { PortalModule } from '@components/portal/portal.module'
import { SearchModule } from '@components/search/search.module'
import { TooltipModule } from '@components/tooltip/tooltip.module'

@NgModule({
	declarations: [HeaderComponent],
	exports: [HeaderComponent],
	imports: [
		CommonModule,
		RouterModule,
		ButtonModule,
		IconModule,
		OverscrollModule,
		PortalModule,
		SearchModule,
		TooltipModule,
	],
})
export class HeaderModule {}
