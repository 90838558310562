import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-text-logo',
	host: {
		class: 'text-logo',
	},
	templateUrl: './text-logo.component.html',
	styleUrls: ['./text-logo.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextLogoComponent extends BaseComponent {}
