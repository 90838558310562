import { BaseComponent } from '@abstract/base.component'
import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	inject,
} from '@angular/core'
import { NgForm } from '@angular/forms'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'
import { useCollection } from '@services/composables/collection'
import { NotificationsService } from '@services/notifications.service'
import { log } from '@utils/log'
import { BehaviorSubject } from 'rxjs'

@Component({
	host: {
		class: 'form',
	},
	template: ``,
})
export class FormComponent extends BaseComponent {
	@Input()
	public closeOverlay = false

	@Input()
	public collection$?: BehaviorSubject<any[]>

	@ViewChild(NgForm)
	public form: NgForm

	@Input()
	public loading$ = new BehaviorSubject(false)

	public notifications: NotificationsService = inject(NotificationsService)

	@Input()
	public overlay?: OverlayComponent

	@Input()
	public redirect = false

	@Output()
	public onSubmit = new EventEmitter()

	// Computed Properties
	// ----------------------------------------

	public get loading() {
		return this.loading$.value
	}

	@Input()
	public set loading(value) {
		this.loading$.next(value)
	}

	// Public Methods
	// ----------------------------------------

	public async submit() {
		if (this.form.invalid || this.loading) return

		try {
			this.loading = true
			this.overlay?.disable()

			if (this._submit) {
				const res = await this._submit()
				log('submit:', res)
			}

			this.loading = false
			this.overlay?.enable()
			this.reset()

			if (this.closeOverlay) {
				this.overlay?.close()
			}

			if (this.redirect && this._redirect) {
				this._redirect()
			}
		} catch (error: any) {
			this.loading = false

			this.overlay?.enable()

			this.notifications.create({
				type: 'danger',
				message: error,
			})
		}
	}

	public reset() {
		if (this._reset) {
			this._reset()
		} else {
			this.form.resetForm(this.form.value)
		}
	}

	// Protected Methods
	// ----------------------------------------

	protected _updateCollection<T>(
		method: 'create' | 'update' | 'delete',
		key: keyof T,
		...items: T[]
	) {
		if (!this.collection$ || !method || !key || !items.length) return

		const collection = new useCollection(this.collection$.value).mutate(
			method,
			key,
			...items,
		)
		this.collection$.next([...collection])
	}

	protected _redirect?(): void

	protected _reset?(): void

	protected _submit?(): Promise<any>
}
