import { VerifyUserOverlayComponent } from './components/verify-user-overlay/verify-user-overlay.component'
import { VerifyUserComponent } from './components/verify-user/verify-user.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { SharedModule } from '@modules/shared.module'

const components = [VerifyUserOverlayComponent, VerifyUserComponent]

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule],
	declarations: [...components],
	exports: [...components],
})
export class VerifyUserModule {}
