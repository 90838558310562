import { TooltipComponent } from './components/tooltip/tooltip.component'
import { TooltipDirective } from './directives/tooltip.directive'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [TooltipComponent, TooltipDirective]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class TooltipModule {}
