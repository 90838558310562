import { StepComponent } from '../step/step.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	QueryList,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-stepper',
	host: {
		class: 'stepper',
	},
	template: `<ng-content select="ms-step"></ng-content>`,
	styleUrls: ['./stepper.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements AfterContentInit {
	@ContentChildren(StepComponent, { descendants: false })
	public steps: QueryList<StepComponent>

	public disabled = false

	public onStepChange = new EventEmitter<number>()

	public realIndex = 0

	constructor() {}

	// Computed Properties
	// --------------------------------------------------

	public get activeIndex() {
		return this.realIndex + 1
	}

	public get activeStep() {
		return this.steps.find((s) => s.active)
	}

	public get stepCount() {
		return this.steps.length
	}

	public get isFirstStep() {
		return this.activeIndex === 1
	}

	public get isLastStep() {
		return this.activeIndex === this.stepCount
	}

	// Lifecycle Methods
	// --------------------------------------------------

	public ngAfterContentInit() {
		if (!this.steps) {
			throw new Error(
				'StepperComponent: @ContentChildren did not find any instances of StepComponent.',
			)
		}

		this._checkActiveStep()
	}

	// Public Methods
	// --------------------------------------------------

	public next() {
		if (this.disabled) return
		if (this.activeIndex >= this.stepCount) return

		this.realIndex++
		this._updateActiveStep()
	}

	public prev() {
		if (this.disabled) return
		if (this.activeIndex <= 1) return

		this.realIndex--
		this._updateActiveStep()
	}

	public reset() {
		if (this.disabled) return

		this.realIndex = 0
		this._updateActiveStep()
	}

	// Private Methods
	// --------------------------------------------------

	private _updateActiveStep() {
		this.steps.forEach((step, i) => {
			if (i === this.realIndex) {
				step.active = true
			} else {
				step.active = false
			}
		})

		this.onStepChange.emit(this.realIndex)
	}

	private _checkActiveStep() {
		const activeIndex = Array.from(this.steps).findIndex((s) => s.active)

		if (activeIndex >= 0) {
			this.realIndex = activeIndex
		} else {
			this.realIndex = 0
			this.steps.first.active = true
		}

		this.onStepChange.emit(this.realIndex)
	}
}
