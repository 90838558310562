import { animate, animation, style } from '@angular/animations'

export const slideInX = animation([
	style({ transform: 'translateX({{ offset }})' }),
	animate('{{ timings }}', style({ transform: 'translateX(0)' })),
])

export const slideOutX = animation([
	style({ transform: 'translateX(0)' }),
	animate('{{ timings }}', style({ transform: 'translateX({{ offset }})' })),
])
