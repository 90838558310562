import { BaseComponent } from '@abstract/base.component'
import { transition, trigger, useAnimation } from '@angular/animations'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { fadeIn, fadeOut } from '@animations/fade.animation'
import {
	Placement,
	arrow,
	autoUpdate,
	computePosition,
	flip,
	offset,
	shift,
} from '@floating-ui/dom'
import { uniqueId } from 'lodash-es'

const tooltipAnimation = trigger('tooltipAnimation', [
	transition(
		':leave',
		useAnimation(fadeOut, { params: { timings: '200ms ease-out' } }),
	),
	transition(
		':enter',
		useAnimation(fadeIn, { params: { timings: '200ms ease-in' } }),
	),
])

@Component({
	selector: 'ms-tooltip',
	host: {
		class: 'tooltip',
		role: 'tooltip',
	},
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	animations: [tooltipAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent extends BaseComponent implements AfterViewInit {
	@ViewChild('arrow', { static: true })
	private _arrowRef: ElementRef<HTMLElement>

	private _updateFn?: () => void

	@HostBinding('@tooltipAnimation')
	@Input()
	animate = true

	@Input({ required: true })
	label: string

	@Input()
	offset = [0, 6]

	@Input({ required: true })
	placement: Placement

	@Input({ required: true })
	target: HTMLElement

	id = uniqueId('tooltip-')

	// Computed Properties
	// ----------------------------------------

	public get arrowEl() {
		return this._arrowRef.nativeElement
	}

	// Lifecycle Methods
	// ----------------------------------------

	ngAfterViewInit() {
		this.renderer.setAttribute(this.el, 'id', this.id)

		const [crossAxis, mainAxis] = this.offset

		this._updateFn = autoUpdate(this.target, this.el, async () => {
			const { x, y, placement, middlewareData } = await computePosition(
				this.target,
				this.el,
				{
					placement: this.placement,
					strategy: 'fixed',
					middleware: [
						offset({ mainAxis, crossAxis }),
						flip(),
						shift(),
						arrow({ element: this.arrowEl, padding: 5 }),
					],
				},
			)

			this.renderer.setStyle(this.el, 'top', `${y}px`)
			this.renderer.setStyle(this.el, 'left', `${x}px`)

			if (middlewareData.arrow) {
				// Accessing the data
				const { x: arrowX, y: arrowY } = middlewareData.arrow

				const staticSide = {
					top: 'bottom',
					right: 'left',
					bottom: 'top',
					left: 'right',
				}[placement.split('-')[0]]

				this.renderer.setStyle(
					this.arrowEl,
					'top',
					arrowY != null ? `${arrowY}px` : '',
				)
				this.renderer.setStyle(this.arrowEl, 'right', '')
				this.renderer.setStyle(this.arrowEl, 'bottom', '')
				this.renderer.setStyle(
					this.arrowEl,
					'left',
					arrowX != null ? `${arrowX}px` : '',
				)

				if (staticSide) {
					this.renderer.setStyle(
						this.arrowEl,
						staticSide,
						`${(this.arrowEl.offsetWidth / 2) * -1}px`,
					)
				}
			}
		})
	}

	@HostListener('@tooltipAnimation.done', ['$event'])
	onDestroy($event: any) {
		if ($event.toState !== 'void') return
		this._updateFn?.()
	}
}
