import { TableStatCalc } from '../table-stat/table-stat.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-table-stat-picker',
	templateUrl: './table-stat-picker.component.html',
	styleUrls: ['./table-stat-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableStatPickerComponent extends BaseComponent {
	@Output()
	public calcChange = new EventEmitter<TableStatCalc>()

	@Input('calc')
	private _calc: TableStatCalc = 'avg'

	// Computed Properties
	// ----------------------------------------

	public get calc() {
		return this._calc
	}

	public set calc(value) {
		this._calc = value
		this.calcChange.emit(value)
		this.cdRef.markForCheck()
	}
}
