import { MenuCollapseComponent } from './components/menu-collapse/menu-collapse.component'
import { MenuHamburgerComponent } from './components/menu-hamburger/menu-hamburger.component'
import { MenuNavComponent } from './components/menu-nav/menu-nav.component'
import { MenuTitleComponent } from './components/menu-title/menu-title.component'
import { MenuComponent } from './components/menu/menu.component'
import { NavDropdownComponent } from './components/nav-dropdown/nav-dropdown.component'
import { NavItemComponent } from './components/nav-item/nav-item.component'
import { NavLinkComponent } from './components/nav-link/nav-link.component'
import { NavToggleComponent } from './components/nav-toggle/nav-toggle.component'
import { NavComponent } from './components/nav/nav.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ButtonModule } from '@components/button/button.module'
import { DividerModule } from '@components/divider/divider.module'
import { IconModule } from '@components/icon/icon.module'
import { LogoModule } from '@components/logo/logo.module'
import { OverlayModule } from '@components/overlay/overlay.module'
import { SearchModule } from '@components/search/search.module'
import { TooltipModule } from '@components/tooltip/tooltip.module'
import { DirectivesModule } from '@modules/directives.module'

const components = [
	MenuCollapseComponent,
	MenuHamburgerComponent,
	MenuTitleComponent,
	MenuNavComponent,
	MenuComponent,
	NavComponent,
	NavDropdownComponent,
	NavItemComponent,
	NavLinkComponent,
	NavToggleComponent,
]

@NgModule({
	exports: [...components],
	declarations: [...components],
	imports: [
		CommonModule,
		RouterModule,
		ButtonModule,
		DividerModule,
		IconModule,
		OverlayModule,
		TooltipModule,
		DirectivesModule,
		LogoModule,
		SearchModule,
	],
})
export class MenuModule {}
