<nav class="tab-nav__tablist" role="tablist">
	<ms-overscroll [enableScrollX]="true" [enableScrollY]="false">
		<ms-navbar [tabs]="true">
			@for (panel of content?.tabs; track panel) {
				@if (
					{
						disabled: panel.disabled$ | async,
						selected: panel.selected$ | async
					};
					as state
				) {
					<ms-navbar-item>
						<button
							navbar-link
							role="tab"
							type="button"
							[active]="!!state.selected"
							[attr.aria-controls]="panel.id"
							[disabled]="!!state.disabled"
							[id]="panel.id + '-btn'"
							(click)="content?.selectTab(panel)"
						>
							{{ panel.name }}
						</button>
					</ms-navbar-item>
				}
			}
		</ms-navbar>
	</ms-overscroll>
</nav>

<div class="tab-nav__select">
	<ms-select>
		<select #nav (change)="content?.selectTabById(nav.value)">
			@for (panel of content?.tabs; track panel) {
				@if (
					{
						disabled: panel.disabled$ | async,
						selected: panel.selected$ | async
					};
					as state
				) {
					<option
						[disabled]="state.disabled"
						[selected]="state.selected"
						[value]="panel.id"
					>
						{{ panel.name }}
					</option>
				}
			}
		</select>
	</ms-select>
</div>
