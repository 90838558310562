import { TableService } from '../../services/table.service'
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'tr[tableRow]',
	host: {
		'class': 'table-row',
		'[class.table-row--clickable]': 'isClickable',
		'[class.table-row--even]': 'isEven',
		'[class.table-row--odd]': '!isEven',
		'[class.table-row--expanded]': 'isExpanded',
		'[class.table-row--hoverable]': 'isHoverable',
		'[class.table-row--selected]': 'isSelected',
	},
	template: ` <ng-content /> `,
	styleUrls: ['./table-row.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowComponent implements OnInit {
	@Input()
	public isClickable = false

	@Input()
	public isHoverable = false

	@Input()
	public row?: any

	@Input()
	public rowIndex?: number

	@Input()
	public rowSpan?: number

	@Output()
	public rowClick = new EventEmitter<any>()

	// Computed Properties
	// ----------------------------------------

	public get isEven() {
		return this.rowIndex !== undefined ? this.rowIndex % 2 === 0 : false
	}

	public get isExpanded() {
		return this.row ? this.pTable?.isRowExpanded(this.row) : false
	}

	public get isSelected() {
		return this.row ? this.pTable?.isSelected(this.row) : false
	}

	public get pTable() {
		return this._tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		private _elRef: ElementRef,
		private _renderer: Renderer2,
		private _tableService: TableService,
	) {}

	public ngOnInit() {
		if (this.isClickable) {
			this._renderer.listen(this._elRef.nativeElement, 'click', (e) =>
				this._handleRowClick(e),
			)
		}
	}

	// Private Methods
	// ----------------------------------------

	private _handleRowClick(e: Event) {
		const target = e.target

		if (!target || !(target instanceof HTMLElement)) return

		const tableCell = target.closest('.table-col')

		if (!tableCell || tableCell.classList.contains('table-col--control')) return

		this.rowClick.emit(this.row)
	}
}
