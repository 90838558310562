import { OverlayWrapperComponent } from '../components/overlay-wrapper/overlay-wrapper.component'
import { OverlayComponent } from '../components/overlay/overlay.component'
import { BaseDirective } from '@abstract/base.directive'
import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core'

@Directive({
	selector: '[overlay-toggle]',
})
export class OverlayToggleDirective extends BaseDirective {
	@Input('overlay-toggle')
	public overlay?: OverlayComponent | OverlayWrapperComponent

	@Output()
	public onOverlayToggle = new EventEmitter()

	// Lifecycle Methods
	// ----------------------------------------

	@HostListener('click')
	public onMouseClick() {
		if (!this.overlay) return

		this.overlay.toggle()
		this.onOverlayToggle.emit()
	}
}
