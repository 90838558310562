import { LayoutOption } from './types'
import { BaseComponent } from '@abstract/base.component'
import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'

const layoutSwitcherAnimation = trigger('layoutSwitcherAnimation', [
	state('grid-view', style({ left: '2px' })),
	state('list-view', style({ left: 'calc(100% - 32px)' })),
	transition(
		'grid-view => list-view',
		animate(
			'400ms ease',
			keyframes([
				style({ left: '2px', transform: 'scaleX(1)', offset: 0 }),
				style({
					left: 'calc(50% - 16px)',
					transform: 'scaleX(1.4)',
					offset: 0.5,
				}),
				style({ left: 'calc(100% - 32px)', transform: 'scaleX(1)', offset: 1 }),
			]),
		),
	),
	transition(
		'list-view => grid-view',
		animate(
			'400ms ease',
			keyframes([
				style({ left: 'calc(100% - 32px)', transform: 'scaleX(1)', offset: 0 }),
				style({
					left: 'calc(50% - 16px)',
					transform: 'scaleX(1.4)',
					offset: 0.5,
				}),
				style({ left: '2px', transform: 'scaleX(1)', offset: 1 }),
			]),
		),
	),
])

@Component({
	selector: 'ms-layout-switcher',
	host: {
		class: 'layout-switcher',
	},
	template: `
		<button
			class="layout-switcher__wrapper"
			type="button"
			(click)="switchLayout()"
		>
			<div class="layout-switcher__options">
				<div
					[@layoutSwitcherAnimation]="layout"
					class="layout-switcher__indicator"
				></div>

				<div
					class="layout-switcher__option"
					[class.layout-switcher__option--active]="layout === 'grid-view'"
				>
					<ms-icon name="card" />
				</div>

				<div
					class="layout-switcher__option"
					[class.layout-switcher__option--active]="layout === 'list-view'"
				>
					<ms-icon name="list" />
				</div>
			</div>
		</button>
	`,
	styleUrls: ['./layout-switcher.component.scss'],
	animations: [layoutSwitcherAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutSwitcherComponent extends BaseComponent {
	@Input()
	public layout: LayoutOption = 'grid-view'

	@Output()
	public onSwitch = new EventEmitter<LayoutOption>()

	// Public Methods
	// ----------------------------------------

	public switchLayout() {
		if (this.layout === 'grid-view') {
			this.layout = 'list-view'
		} else {
			this.layout = 'grid-view'
		}

		this.onSwitch.emit(this.layout)
	}
}
