import { StepperComponent } from '../stepper/stepper.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-stepper-progress',
	host: {
		class: 'stepper-progress',
	},
	template: `
		<ms-overscroll [enableScrollX]="true" [enableScrollY]="false">
			<nav class="stepper-progress__nav">
				@for (step of stepper?.steps; track step; let i = $index) {
					<span
						class="stepper-progress__item"
						[class.stepper-progress__item--active]="stepper?.realIndex === i"
					>
						{{ step.name }}

						<ms-icon name="chevron" />
					</span>
				}
			</nav>
		</ms-overscroll>
	`,
	styleUrls: ['./stepper-progress.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperProgressComponent extends BaseComponent implements OnInit {
	@Input()
	public stepper?: StepperComponent

	// Lifecycle Methods
	// --------------------------------------------------

	public ngOnInit() {
		this.stepper?.onStepChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}
}
