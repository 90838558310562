import { PortalService } from '../../services/portal.service'
import { Portal, TemplatePortal } from '@angular/cdk/portal'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-portal',
	template: `
		<ng-template #content>
			<ng-content />
		</ng-template>
	`,
	styleUrls: ['./portal.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalComponent implements AfterViewInit, OnDestroy {
	@Input('outlet')
	public outletName: string

	@ViewChild('content')
	private _content: TemplateRef<any>

	public portal: TemplatePortal<any> | null = null

	public outlet$: BehaviorSubject<Portal<any> | null>

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		private _portalService: PortalService,
		private _vcRef: ViewContainerRef,
	) {}

	public ngAfterViewInit() {
		if (!this.outletName) {
			throw new Error('PortalComponent: outlet is required')
		}

		this.portal = new TemplatePortal(this._content, this._vcRef)
		this.outlet$ = this._portalService.useOutlet(this.outletName)
		this.outlet$.next(this.portal)
	}

	public ngOnDestroy() {
		if (this.outlet$.value === this.portal) {
			this.outlet$.next(null)
		}
	}
}
