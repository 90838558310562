import { IconDefinitionsComponent } from './components/icon-definitions/icon-definitions.component'
import { IconFilterComponent } from './components/icon-filter/icon-filter.component'
import { IconSortComponent } from './components/icon-sort/icon-sort.component'
import { IconComponent } from './components/icon/icon.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [
	IconDefinitionsComponent,
	IconFilterComponent,
	IconSortComponent,
	IconComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class IconModule {}
