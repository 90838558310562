import { BaseComponent } from '../../../../abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-content',
	host: {
		class: 'content',
	},
	template: `
		<div class="content__loader" [style.opacity]="!loading ? 0 : 1">
			<ms-progress-spinner></ms-progress-spinner>
		</div>

		<div class="content__body" [style.opacity]="loading ? 0 : 1">
			<ng-content />
		</div>
	`,
	styleUrls: ['./content.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent extends BaseComponent {
	@Input()
	public loading? = false
}
