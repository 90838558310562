import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-card-subtitle, [card-subtitle]',
	host: {
		class: 'card-subtitle',
	},
	template: `<ng-content />`,
	styleUrls: ['./card-subtitle.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSubtitleComponent {}
