import { animate, animation, style } from '@angular/animations'

export const expand = animation([
	style({
		height: 0,
		overflow: 'hidden',
	}),
	animate(
		'{{ timings }}',
		style({
			height: '*',
		}),
	),
])

export const collapse = animation([
	style({
		height: '*',
		overflow: 'hidden',
	}),
	animate(
		'{{ timings }}',
		style({
			height: 0,
		}),
	),
])
