import { FormComponent } from '@abstract/form.component'
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { OverlayWrapperComponent } from '@components/overlay/components/overlay-wrapper/overlay-wrapper.component'
import { ResponseStatus } from '@models/misc/Response'
import { SessionService } from '@services/session.service'

@Component({
	selector: 'ms-verify-user-overlay',
	templateUrl: './verify-user-overlay.component.html',
	styleUrls: ['./verify-user-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class VerifyUserOverlayComponent
	extends OverlayWrapperComponent
	implements OnInit
{
	@ViewChild('form')
	public form?: FormComponent

	@Output()
	public onSubmit = new EventEmitter<ResponseStatus>()

	constructor(
		private _sessionService: SessionService,
		public cdRef: ChangeDetectorRef,
		public elRef: ElementRef,
		public renderer: Renderer2,
	) {
		super()
	}

	public ngOnInit() {
		this._sessionService.userAuthRequired
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.open()
				this.disable()
			})
	}

	public verifyUser(res: ResponseStatus) {
		if (res === 'success') {
			this.enable()
			this.close()
		}

		this.onSubmit.emit(res)
	}
}
