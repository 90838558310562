import {
	CardButtonComponent,
	CardComponent,
	CardLinkComponent,
	CardSubtitleComponent,
	CardTitleComponent,
} from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ButtonModule } from '@components/button/button.module'

const components = [
	CardButtonComponent,
	CardComponent,
	CardLinkComponent,
	CardSubtitleComponent,
	CardTitleComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, RouterModule, ButtonModule],
})
export class CardModule {}
