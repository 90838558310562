export class useCollection<T> {
	collection: T[]

	constructor(collection: T[]) {
		this.collection = collection
	}

	public mutate(
		method: 'create' | 'update' | 'delete',
		key: keyof T,
		...items: T[]
	) {
		switch (method) {
			case 'create':
				this.collection = this.add(...items)
				break

			case 'update':
				this.collection = this.update(key, ...items)
				break

			case 'delete':
				this.collection = this.delete(key, ...items)
				break
		}

		return this.collection
	}

	public add(...items: T[]) {
		return [...this.collection, ...items]
	}

	public update(key: keyof T, ...items: T[]) {
		return this.collection.map((item) => {
			const index = items.findIndex((i) => i[key] === item[key])
			return index > -1 ? { ...items[index], ...item } : item
		})
	}

	public delete(key: keyof T, ...items: T[]) {
		const keys = items.map((i: T) => i[key])
		return this.collection.filter((item) => !keys.includes(item[key]))
	}
}
