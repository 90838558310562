import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-form-required',
	host: {
		class: 'form-required',
	},
	template: `
		<span class="form-required__inner">
			{{ 'common.required' | translate }}
		</span>
	`,
	styleUrls: ['./form-required.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRequiredComponent extends BaseComponent {}
