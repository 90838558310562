import { isNullOrUndefined } from '@utils/is-null-or-undefined'
import { isNumber } from 'lodash-es'

export function isNumeric(value: any) {
	if (isNullOrUndefined(value)) return false

	value = Number(value)

	const isValNumber = isNumber(value)
	const isValFinite = isFinite(value)
	const isValNaN = isNaN(value)

	return isValNumber && isValFinite && !isValNaN
}
