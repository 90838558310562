import { NavItem } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-nav',
	host: {
		class: 'nav',
	},
	template: `
		@for (navItem of navItems; track navItem) {
			<ms-nav-item [navItem]="navItem"></ms-nav-item>
		}
	`,
	styleUrls: ['./nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent extends BaseComponent {
	@Input()
	public navItems: NavItem[]
}
