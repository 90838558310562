import { CardComponent } from '../card/card.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'
import { Params } from '@angular/router'

@Component({
	selector: 'ms-card-link',
	host: {
		class: 'card-link',
	},
	template: `
		<a
			class="card__inner"
			[attr.aria-label]="label"
			[queryParams]="params"
			[routerLink]="link"
		>
			@if (tempHeader) {
				<header class="card__header">
					<ng-container [ngTemplateOutlet]="tempHeader" />
				</header>
			}

			<div class="card__content">
				<ng-content />
				@if (tempContent) {
					<ng-container [ngTemplateOutlet]="tempContent" />
				}
			</div>

			@if (tempFooter) {
				<footer class="card__footer">
					<ng-container [ngTemplateOutlet]="tempFooter" />
				</footer>
			}
		</a>

		@if (tempControls) {
			<ms-btn-toolbar class="card__controls" position="middle">
				<ng-container [ngTemplateOutlet]="tempControls" />
			</ms-btn-toolbar>
		}
	`,
	styleUrls: ['../card/card.component.scss', './card-link.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLinkComponent extends CardComponent {
	@Input()
	public link?: string | any[] | null

	@Input()
	public params?: Params | null

	@Input()
	public label?: string
}
