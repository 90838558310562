import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-label',
	host: {
		class: 'label',
	},
	template: `
		<div class="label__wrapper">
			@if (required) {
				<span class="label__required"></span>
			}
			<span class="label__text">
				<ng-content />
			</span>
		</div>
	`,
	styleUrls: ['./label.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent extends BaseComponent {
	@Input()
	public required?: boolean
}
