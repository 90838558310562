<ms-modal
	#overlay
	class="verify-user-modal"
	(overlayDidClose)="overlayDidClose.emit()"
	(overlayDidOpen)="overlayDidOpen.emit()"
	(overlayWillClose)="overlayWillClose.emit()"
	(overlayWillOpen)="overlayWillOpen.emit()"
>
	<ng-template name="header">
		<ms-overlay-title>
			{{ 'components.verify_user.title' | translate }}
		</ms-overlay-title>

		<p class="mt-2">
			{{ 'components.verify_user.message' | translate }}
		</p>
	</ng-template>

	<ng-template name="content">
		<ms-verify-user #form (onSubmit)="verifyUser($event)" />
	</ng-template>

	<ng-template name="footer">
		<ms-btn-toolbar position="end">
			<ms-form-btns type="save" [form]="form" [label]="'Submit'" />
		</ms-btn-toolbar>
	</ng-template>
</ms-modal>
