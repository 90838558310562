import { Pipe, PipeTransform, inject } from '@angular/core'
import { FormatService } from '@services/format.service'
import { isNullOrUndefined } from '@utils/is-null-or-undefined'
import { getTypeOf } from '@utils/typeof'

@Pipe({
	name: 'format',
	standalone: true,
})
export class FormatPipe implements PipeTransform {
	formatService = inject(FormatService)

	public transform<T extends object>(
		value?: string | number | T,
		format?: string,
	) {
		if (isNullOrUndefined(value)) return value

		if (!format) {
			format = getTypeOf(value)
		}

		return this.formatService.format(value, format)
	}
}
