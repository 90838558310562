import { MenuService } from '../../services/menu.service'
import { NavItem } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-menu-nav',
	host: {
		class: 'menu-nav',
	},
	template: `
		<ms-nav class="nav--primary" [navItems]="primaryNav"></ms-nav>

		<ms-divider></ms-divider>

		<ms-nav class="nav--secondary" [navItems]="secondaryNav"></ms-nav>
	`,
	styleUrls: ['./menu-nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuNavComponent extends BaseComponent implements OnInit {
	private _menu = inject(MenuService)

	public primaryNav: NavItem[] = []

	public secondaryNav: NavItem[] = []

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._updateNav()

		this._menu.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) => {
				collapsed
					? this.renderer.addClass(this.el, 'menu-nav--collapsed')
					: this.renderer.removeClass(this.el, 'menu-nav--collapsed')
			})

		this.translate.onLangChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._updateNav()
			})
	}

	// Private Methods
	// ----------------------------------------

	private _updateNav() {
		this.primaryNav = [
			{
				path: '/animals/mobs',
				label: this.$t('common.animals'),
				icon: 'menu-animals',
				dropdownItems: [
					{
						path: '/animals/mobs',
						label: this.$t('common.mobs'),
					},
					{
						path: '/animals/groups',
						label: this.$t('common.groups'),
					},
					{
						path: '/animals/sales',
						label: this.$t('common.sales'),
					},
					{
						path: '/animals/purchases',
						label: this.$t('common.purchases'),
					},
					{
						path: '/animals',
						label: this.$t('common.animals'),
					},
				],
			},
			{
				path: '/sessions',
				label: this.$t('common.sessions'),
				icon: 'menu-measurements',
				dropdownItems: [
					{
						path: '/sessions/not-measured',
						label: this.$t('common.not_measured'),
					},
				],
			},
			// {
			// 	path: '/draft-sets',
			// 	label: this.$t('common.draft_sets'),
			// 	icon: 'menu-alerts',
			// },
			{
				path: '/alerts',
				label: this.$t('common.alerts'),
				icon: 'menu-alerts',
			},
		]

		this.secondaryNav = [
			{
				path: '/contacts',
				label: this.$t('common.contacts'),
				icon: 'menu-account',
			},
			{
				path: '/settings',
				label: this.$t('common.settings'),
				icon: 'menu-settings',
				dropdownItems: [
					{
						path: '/settings/breeds',
						label: this.$t('common.breeds'),
					},
					{
						path: '/settings/measurements',
						label: this.$t('common.measurements'),
					},
					{
						path: '/settings/treatments',
						label: this.$t('common.treatments'),
					},
				],
			},
			{
				path: '/support',
				label: this.$t('common.support'),
				icon: 'menu-help-support',
			},
			{
				path: '/import',
				label: this.$t('common.import'),
				icon: 'menu-import',
			},
		]

		this.cdRef.markForCheck()
	}
}
