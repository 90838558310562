import { ButtonBaseComponent } from '../../shared/button.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { logWarning } from '@utils/log'

@Component({
	selector: '[btn-link-icon], [btn-solid-icon], [btn-outline-icon]',
	host: {
		class: 'btn btn--icon-only',
	},
	templateUrl: './button-icon.component.html',
	styleUrls: ['../../shared/button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent extends ButtonBaseComponent implements OnInit {
	// Lifecycle
	// ----------------------------------------

	public ngOnInit() {
		// this._checkA11y()

		if (this.el.hasAttribute('btn-link-icon')) {
			this.renderer.addClass(this.el, `btn--link`)
		}

		if (this.el.hasAttribute('btn-solid-icon')) {
			this.renderer.addClass(this.el, `btn--solid`)
		}

		if (this.el.hasAttribute('btn-outline-icon')) {
			this.renderer.addClass(this.el, `btn--outline`)
		}

		if (this.color && this.color !== 'none') {
			this.renderer.addClass(this.el, `btn--theme-${this.color}`)
		}
	}

	// Private methods
	// ----------------------------------------

	private _checkA11y() {
		if (
			!this.el.hasAttribute('title') &&
			!this.el.hasAttribute('aria-label') &&
			!this.el.hasAttribute('tooltip')
		) {
			logWarning('Button does not have an accessible name.', this.el)
		}
	}
}
