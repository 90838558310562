import { BaseComponent } from '../../abstract/base.component'
import { DOCUMENT } from '@angular/common'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-overscroll',
	host: {
		'class': 'overscroll',
		'[class.overscroll--x]': 'enableScrollX',
		'[class.overscroll--y]': 'enableScrollY',
	},
	template: `
		<div
			#overscrollContent
			class="overscroll__content"
			observe-resize
			(onResize)="onResize()"
		>
			<ng-content />
		</div>
	`,
	styleUrls: ['./overscroll.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverscrollComponent
	extends BaseComponent
	implements AfterViewInit, OnDestroy
{
	@ViewChild('overscrollContent')
	private _overscrollContent: ElementRef<HTMLElement>

	private _listenerMouseMove?: () => void

	private _listenerMouseUp?: () => void

	private _listenerWindowBlur?: () => void

	private _xPos = 0

	private _yPos = 0

	private _scroll = {
		x: {
			start: 0,
			end: 0,
		},
		y: {
			start: 0,
			end: 0,
		},
	}

	@Input()
	public enableScrollX = true

	@Input()
	public enableScrollY = true

	constructor(@Inject(DOCUMENT) public document: Document) {
		super()
	}

	// Computed Properties
	// --------------------------------------------------

	public get contentEl() {
		return this._overscrollContent.nativeElement
	}

	public get scrollXTotal() {
		return this.contentEl.scrollWidth - this.contentEl.offsetWidth
	}

	public get scrollYTotal() {
		return this.contentEl.scrollHeight - this.contentEl.offsetHeight
	}

	// Lifecycle Methods
	// --------------------------------------------------

	public ngAfterViewInit() {
		this._bindClasses()

		this._calcScroll(0, 0)

		this.renderer.listen(this.contentEl, 'scroll', (e: Event) => {
			this._calcScroll(0, 0)
		})

		this.renderer.listen(this.contentEl, 'mousedown', (e: MouseEvent) => {
			this.onMouseDown(e)
		})

		this._listenerWindowBlur = this.renderer.listen(window, 'blur', () => {
			this._removeListeners()
		})
	}

	public ngOnDestroy() {
		this._removeListeners()
	}

	public onMouseDown(e: MouseEvent) {
		// Get the current mouse position
		this._xPos = e.clientX
		this._yPos = e.clientY

		// Attach the listeners
		this._listenerMouseMove = this.renderer.listen(
			this.document,
			'mousemove',
			(e: MouseEvent) => {
				this.onMouseMove(e)
			},
		)

		this._listenerMouseUp = this.renderer.listen(
			this.document,
			'mouseup',
			() => {
				this._removeListeners()
			},
		)
	}

	public onMouseMove(e: MouseEvent) {
		// How far the mouse has been moved
		const xPos = e.clientX - this._xPos
		const yPos = e.clientY - this._yPos

		this._calcScroll(xPos, yPos)

		// Set the scroll position of element
		if (this.enableScrollX) {
			this.contentEl.scrollLeft = this._scroll.x.start
		}

		if (this.enableScrollY) {
			this.contentEl.scrollTop = this._scroll.y.start
		}

		// Reassign the position of mouse
		this._xPos = e.clientX
		this._yPos = e.clientY
	}

	public onResize() {
		this._calcScroll(0, 0)
	}

	// Private Methods
	// --------------------------------------------------

	private _bindClasses() {
		this.enableScrollX && this.scrollXTotal > 0
			? this.renderer.addClass(this.el, 'overscroll--x-enabled')
			: this.renderer.removeClass(this.el, 'overscroll--x-enabled')

		this.enableScrollY && this.scrollYTotal > 0
			? this.renderer.addClass(this.el, 'overscroll--y-enabled')
			: this.renderer.removeClass(this.el, 'overscroll--y-enabled')
	}

	private _calcScroll(xPos: number, yPos: number) {
		let scrollX = this.contentEl.scrollLeft - xPos
		let scrollY = this.contentEl.scrollTop - yPos

		// Calc ScrollX
		if (scrollX < 0) {
			scrollX = 0
		}

		if (scrollX > this.scrollXTotal) {
			scrollX = this.scrollXTotal
		}

		this._scroll.x.start = scrollX
		this._scroll.x.end = this.scrollXTotal - scrollX
		this.el.style.setProperty('--scroll-x-start', `${this._scroll.x.start}px`)
		this.el.style.setProperty('--scroll-x-end', `${this._scroll.x.end}px`)

		// Calc ScrollY
		if (scrollY < 0) {
			scrollY = 0
		}

		if (scrollY > this.scrollYTotal) {
			scrollY = this.scrollYTotal
		}

		this._scroll.y.start = scrollY
		this._scroll.y.end = this.scrollYTotal - scrollY
		this.el.style.setProperty('--scroll-y-start', `${this._scroll.y.start}px`)
		this.el.style.setProperty('--scroll-y-end', `${this._scroll.y.end}px`)
	}

	private _removeListeners() {
		// Remove the event handlers
		if (this._listenerMouseMove) {
			this._listenerMouseMove()
		}

		if (this._listenerMouseUp) {
			this._listenerMouseUp()
		}

		if (this._listenerWindowBlur) {
			this._listenerWindowBlur()
		}
	}
}
