import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-overlay-title, [overlay-title]',
	host: {
		class: 'overlay-title',
	},
	template: `<ng-content />`,
	styleUrls: ['./overlay-title.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayTitleComponent {}
