import { animate, animation, style } from '@angular/animations'

export const zoomIn = animation([
	style({ opacity: 0, transform: 'scale({{ scale }})' }),
	animate('{{ timings }}', style({ opacity: 1, transform: 'scale(1)' })),
])

export const zoomOut = animation([
	style({ opacity: 1, transform: 'scale(1)' }),
	animate(
		'{{ timings }}',
		style({ opacity: 0, transform: 'scale({{ scale }})' }),
	),
])
