import { FormComponent } from '@abstract/form.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core'
import { ResponseStatus } from '@models/misc/Response'
import { SessionService } from '@services/session.service'

@Component({
	selector: 'ms-verify-user',
	templateUrl: './verify-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyUserComponent extends FormComponent implements OnInit {
	public formData = {
		username: '',
		password: '',
	}

	public message?: string

	@Output()
	public onSubmit = new EventEmitter<ResponseStatus>()

	constructor(private _sessionService: SessionService) {
		super()
	}

	// Public Methods
	// ----------------------------------------

	public ngOnInit() {
		const username = this._sessionService.username

		if (username) {
			this.formData.username = username
		}
	}

	// Protected Methods
	// ----------------------------------------

	protected async _submit() {
		const res = await this._sessionService.login(
			this.formData.username,
			this.formData.password,
		)

		this.message =
			res !== 'success' ? this.$t('components.verify_user.error') : undefined

		this.onSubmit.emit(res)

		return res
	}
}
