import { MenuNavComponent } from '../components/menu-nav/menu-nav.component'
import { Injectable } from '@angular/core'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	public collapsed$ = new BehaviorSubject(false)

	public menuNav: MenuNavComponent

	public overlayMenu: OverlayComponent

	public title$ = new BehaviorSubject('')

	constructor() {}

	// Computed Properties
	// ----------------------------------------

	public get collapsed() {
		return this.collapsed$.value
	}

	public set collapsed(value) {
		this.collapsed$.next(value)
	}

	public get title() {
		return this.title$.value
	}

	public set title(value) {
		this.title$.next(value)
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		if (this.collapsed) {
			this.open()
		} else {
			this.close()
		}
	}

	public open() {
		this.collapsed = false
	}

	public close() {
		this.collapsed = true
	}
}
