import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { TableService } from '@components/table/services/table.service'

@Component({
	selector: 'ms-table-header',
	template: `
		<tr tableRow [isClickable]="false" [isHoverable]="false">
			@if (enableExpand) {
				<th tableCol [isControl]="true"></th>
			}
			@if (enableSelect) {
				<th tableCol [isControl]="true">
					<ms-table-select-all></ms-table-select-all>
				</th>
			}
			@for (col of columns; track trackBy($index, col)) {
				<th tableCol>
					<span class="mr-auto">{{ col.header }}</span>

					@if (enableSort) {
						<ms-table-sort
							[field]="col.field"
							[type]="col.type"
						></ms-table-sort>
					}
					@if (enableFilter) {
						<ms-table-filter
							[field]="col.field"
							[type]="col.type"
						></ms-table-filter>
					}
				</th>
			}
			@if (!columns.length) {
				<th tableCol>&nbsp;</th>
			}
		</tr>
	`,
	styles: [
		`
			ms-table-header {
				display: contents;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent {
	private _tableService = inject(TableService)

	@Input()
	public columns: MS.Table.Column[]

	@Input()
	public enableFilter = false

	@Input()
	public enableSort = false

	// Computed Properties
	// ----------------------------------------

	public get enableExpand() {
		return this._tableService.isRowExpandEnabled
	}

	public get enableSelect() {
		return this._tableService.isRowSelectEnabled
	}

	public get pTable() {
		return this._tableService.pTable
	}

	// Public Methods
	// ----------------------------------------

	public trackBy(i: number, col: MS.Table.Column) {
		return col.field ?? i
	}
}
