import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-icon',
	host: {
		class: 'icon',
	},
	template: `
		<svg viewBox="0 0 30 30">
			<use [attr.xlink:href]="'#svg-' + name"></use>
		</svg>
	`,
	styleUrls: ['./icon.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
	@Input()
	public name: string

	@Input()
	public size: string

	@Input()
	public rotate: number

	constructor(
		private _renderer: Renderer2,
		public elRef: ElementRef,
	) {}

	public get el() {
		return this.elRef.nativeElement
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._bindStyles()
	}

	// Public Methods
	// ----------------------------------------

	public _bindStyles() {
		if (this.rotate) {
			this._renderer.setStyle(
				this.el,
				'--icon-transform',
				`rotate(${this.rotate}deg)`,
				2,
			)
		}

		if (this.size) {
			this._renderer.setStyle(this.el, '--icon-size', this.size, 2)
		}
	}
}
