import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	public query?: string

	constructor(private _router: Router) {}

	public search() {
		this._router.navigate(['/search'], {
			queryParams: {
				query: this.query,
			},
		})

		this.query = undefined
	}
}
