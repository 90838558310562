import { isArray, isObject, isString } from 'lodash-es'

export function isEmpty(value: any) {
	if (isArray(value)) {
		return value.length === 0
	}

	if (isObject(value)) {
		return Object.keys(value).length === 0
	}

	if (isString(value)) {
		value = value.trim()
	}

	return value === null || value === undefined || value === ''
}
