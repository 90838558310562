import { BaseComponent } from '@abstract/base.component'
import { Platform } from '@angular/cdk/platform'
import { DOCUMENT } from '@angular/common'
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Inject,
	OnInit,
	ViewContainerRef,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import {
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterEvent,
} from '@angular/router'
import { i18nService } from '@services/i18n.service'
import { Subject, filter, throttleTime } from 'rxjs'
import vhCheck from 'vh-check'

@Component({
	selector: 'ms-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent implements OnInit {
	public init = false

	public loading = true

	public loadingTimeout: any

	public language: string

	public preferredLocale: string | null

	public resize$ = new Subject<void>()

	public viewHeight: any

	public bodyWidth?: number

	public bodyHeight?: number

	public platform = inject(Platform)

	public router = inject(Router)

	public vcRef = inject(ViewContainerRef)

	public i18n = inject(i18nService)

	constructor(@Inject(DOCUMENT) public document: Document) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this.i18n.init()

		this._detectPlatform()
		this._detectScrollbarWidth()
		this._detectViewHeight()

		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter(
					(e: any) =>
						e instanceof NavigationStart ||
						e instanceof NavigationEnd ||
						e instanceof NavigationCancel ||
						e instanceof NavigationError,
				),
			)
			.subscribe((e: RouterEvent) => {
				this._loadingInterceptor(e)
			})

		this.bodyWidth = this.document.body.getBoundingClientRect().width
		this.bodyHeight = this.document.body.getBoundingClientRect().height
		this.renderer.addClass(document.body, 'ms-loaded')
		this.init = true

		this.resize$.pipe(throttleTime(100)).subscribe(() => {
			const rect = this.document.body.getBoundingClientRect()

			this.document.dispatchEvent(new CustomEvent('resize'))

			if (this.bodyWidth !== rect.width) {
				this.bodyWidth = rect.width
				this.document.dispatchEvent(new CustomEvent('resize-x'))
			}

			if (this.bodyHeight !== rect.height) {
				this.bodyHeight = rect.height
				this.document.dispatchEvent(new CustomEvent('resize-y'))
			}
		})
	}

	@HostListener('window:resize', ['$event'])
	public onResize() {
		this.resize$.next()
	}

	// Public Methods
	// ----------------------------------------

	public isNavActive() {
		const forbiddenRoutes = ['/', '/login', '/reset-password']
		return !forbiddenRoutes.includes(this.router.url)
	}

	// Private Methods
	// ----------------------------------------

	private _loadingInterceptor(e: RouterEvent) {
		switch (true) {
			case e instanceof NavigationStart: {
				this.loadingTimeout = window.setTimeout(() => {
					this.loading = true
				}, 500)
				break
			}

			case e instanceof NavigationEnd:
			case e instanceof NavigationCancel:
			case e instanceof NavigationError: {
				window.clearTimeout(this.loadingTimeout)
				this.loading = false
				break
			}

			default: {
				break
			}
		}
	}

	private _detectPlatform() {
		if (this.platform.ANDROID) {
			this.renderer.addClass(this.document.documentElement, 'android')
		}

		if (this.platform.FIREFOX) {
			this.renderer.addClass(this.document.documentElement, 'firefox')
		}

		if (this.platform.IOS) {
			this.renderer.addClass(this.document.documentElement, 'ios')
		}

		if (this.platform.SAFARI) {
			this.renderer.addClass(this.document.documentElement, 'safari')
		}

		if (this.platform.WEBKIT) {
			this.renderer.addClass(this.document.documentElement, 'chrome')
		}
	}

	private _detectScrollbarWidth() {
		this.document.documentElement.insertAdjacentHTML(
			'beforeend',
			`
				<div id="box-outer" style="position: absolute; top: -200px; left: -200px; width: 50px; height: 50px; overflow: auto;">
					<div id="box-inner" style="height: 100px;">
				</div>
			`,
		)

		const outer: HTMLElement | null = this.document.querySelector('#box-outer')
		const inner: HTMLElement | null = this.document.querySelector('#box-inner')

		if (outer && inner) {
			const scrollbarWidth = `${outer.offsetWidth - inner.offsetWidth}px`

			this.document.documentElement.style.setProperty(
				'--scrollbar-width',
				scrollbarWidth,
			)

			outer.remove()
			inner.remove()
		}
	}

	private _detectViewHeight() {
		this.viewHeight = vhCheck({
			force: true,
			updateOnTouch: true,
		})
	}
}
