import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform, inject } from '@angular/core'
import { i18nService } from '@services/i18n.service'

@Pipe({
	name: 'i18nDate',
	standalone: true,
})
export class I18nDatePipe implements PipeTransform {
	datePipe = inject(DatePipe)

	i18n = inject(i18nService)

	transform(value?: string | number | Date, format?: string) {
		if (!value) return value

		return this.datePipe.transform(
			value,
			format ?? this.i18n.dateFormat ?? 'shortDate',
		)
	}
}
