import { DropdownComponent } from '../components/dropdown/dropdown.component'
import { BaseDirective } from '@abstract/base.directive'
import { Directive, HostListener, Input } from '@angular/core'

@Directive({
	selector: '[dropdown]',
})
export class DropdownToggleDirective extends BaseDirective {
	@Input()
	public dropdown: DropdownComponent

	// Lifecycle Methods
	// ----------------------------------------

	@HostListener('click', ['$event'])
	public onClick(e: PointerEvent) {
		e.preventDefault()
		e.stopPropagation()

		this.dropdown.toggle(this.el)
	}
}
