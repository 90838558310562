import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AutoResizeDirective } from '@directives/autoresize/autoresize.directive'
import { LetDirective } from '@directives/let/let.directive'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import { ObserveResizeDirective } from '@directives/observe-resize/observe-resize.directive'
import { RouterLinkDisabledDirective } from '@directives/router-link-disabled/router-link-disabled.directive'
import { CompareDirective } from '@directives/validators/compare.directive'
import { InputDecimalDirective } from '@directives/validators/decimal.directive'
import { InputEidDirective } from '@directives/validators/eid.directive'
import { InputIntegerDirective } from '@directives/validators/integer.directive'
import { InputNumericDirective } from '@directives/validators/numeric.directive'
import { UniqueDirective } from '@directives/validators/unique.directive'

const directives = [
	AutoResizeDirective,
	CompareDirective,
	InputDecimalDirective,
	InputEidDirective,
	InputIntegerDirective,
	InputNumericDirective,
	LetDirective,
	NamedTemplateDirective,
	ObserveResizeDirective,
	RouterLinkDisabledDirective,
	UniqueDirective,
]

@NgModule({
	declarations: [...directives],
	exports: [...directives],
	imports: [CommonModule],
})
export class DirectivesModule {}
