import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	QueryList,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AccordionPanelComponent } from '@components/accordion/components/accordion-panel/accordion-panel.component'

@Component({
	selector: 'ms-accordion',
	host: {
		class: 'accordion',
		role: 'tablist',
	},
	template: `<ng-content />`,
	styleUrls: ['./accordion.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent
	extends BaseComponent
	implements AfterContentInit
{
	@ContentChildren(AccordionPanelComponent, { descendants: false })
	public panels: QueryList<AccordionPanelComponent>

	@Input()
	public openFirst = false

	@Input()
	public keepOpen = false

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		if (!this.keepOpen) {
			this.panels.toArray().forEach((panel) => {
				panel.panelOpen
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe(() => {
						this._closeOtherPanels(panel)
					})
			})
		}

		this._openFirstPanel()

		this.panels.changes
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._openFirstPanel()
			})
	}

	// Private Methods
	// ----------------------------------------

	private _openFirstPanel() {
		if (!this.openFirst) return

		const activePanel = this.panels.find((p) => p.active)

		if (activePanel) return

		const panelToOpen = this.panels.find((p) => !p.disabled)

		if (!panelToOpen) return

		panelToOpen.open()
	}

	private _closeOtherPanels(panel: AccordionPanelComponent) {
		this.panels.toArray().forEach((p) => {
			if (p.panelId !== panel.panelId && p.active) {
				p.close()
			}
		})
	}
}
