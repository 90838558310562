<button
	btn-color="none"
	btn-link
	class="table-filter"
	type="button"
	[active]="isActive"
	[dropdown]="menu"
	[tooltip]="$t('components.table.filter')"
>
	<ms-icon-filter [active]="isActive" />
</button>

<ms-dropdown #menu class="table-filter-menu" strategy="absolute">
	<div class="table-filter-form">
		@for (filter of filters; track filter; let i = $index) {
			@if (matchModes.length > 1) {
				<ms-control [validate]="false">
					<select name="match-mode" [(ngModel)]="filter.matchMode">
						@for (option of matchModes; track option) {
							<option [value]="option.code">
								{{ option.name }}
							</option>
						}
					</select>
				</ms-control>
			}
			@if (
				filter.matchMode !== 'isEmpty' && filter.matchMode !== 'isNotEmpty'
			) {
				@switch (filterType) {
					<!--
					<ng-container *ngSwitchCase="'select'">
						<ms-control aria-label="Select"  [loading]="!(options$ | async)" [validate]="false">
							<select name="select" [(ngModel)]="filter.value">
								<option value=""></option>
								<ng-container *ngFor="let option of options$ | async">
									<option [value]="option | get : optionValueField">{{ option | get : optionLabelField }}</option>
								</ng-container>
							</select>
						</ms-control>
					</ng-container>
					-->

					@case ('date') {
						<ms-date-picker [validate]="false">
							<input name="date" type="text" [(ngModel)]="filter.value" />
						</ms-date-picker>
					}
					@case ('number') {
						<ms-control [validate]="false">
							<input
								name="input"
								numeric
								type="text"
								[(ngModel)]="filter.value"
							/>
						</ms-control>
					}
					@default {
						<ms-control [validate]="false">
							<input name="input" type="text" [(ngModel)]="filter.value" />
						</ms-control>
					}
				}
			}
			@if (filters.length === 1) {
				<button
					class="table-filter-btn table-filter-btn--success"
					type="button"
					(click)="addRule($event)"
				>
					{{ 'common.add_rule' | translate }}
				</button>
			}
			@if (filters.length > 1) {
				<button
					class="table-filter-btn table-filter-btn--danger"
					type="button"
					(click)="removeRule($event, i)"
				>
					{{ 'common.remove_rule' | translate }}
				</button>
			}
		}
	</div>

	<ng-template name="footer">
		<ms-btn-group>
			<button btn-color="brand" btn-solid type="button" (click)="apply()">
				{{ 'common.apply' | translate }}
			</button>

			<button btn-color="brand" btn-solid type="button" (click)="reset()">
				{{ 'common.reset' | translate }}
			</button>
		</ms-btn-group>
	</ng-template>
</ms-dropdown>
