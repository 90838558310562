import { Pipe, PipeTransform } from '@angular/core'
import { isNumeric } from '@utils/is-numeric'
import { groupBy, isArray } from 'lodash-es'

type Iteratee<T> = string | ((value: T) => unknown)

export interface Group<T> {
	key: string | number
	value: T[]
}

@Pipe({
	name: 'groupBy',
	standalone: true,
})
export class GroupByPipe implements PipeTransform {
	public transform<T>(
		collection: T[] | null,
		iteratee: Iteratee<T>,
	): Group<T>[] | null {
		if (!collection) return collection

		if (!isArray(collection)) {
			throw new Error(`GroupByPipe: Collection is not iterable.`)
		}

		const groups = groupBy<T>(collection, iteratee)

		return Object.entries(groups).map(([key, value]) => {
			return {
				key: isNumeric(key) ? parseFloat(key) : key,
				value,
			}
		})
	}
}
