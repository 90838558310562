import { MenuService } from '../../services/menu.service'
import { NavDropdownComponent } from '../nav-dropdown/nav-dropdown.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-nav-toggle',
	host: {
		class: 'nav-toggle',
	},
	template: `
		<button
			btn-color="none"
			btn-link-icon
			class="nav-toggle"
			(click)="navDropdown.toggle()"
		>
			<ms-icon name="caret" />
		</button>
	`,
	styleUrls: ['./nav-toggle.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavToggleComponent extends BaseComponent implements OnInit {
	@Input()
	public navDropdown: NavDropdownComponent

	constructor(public menuService: MenuService) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this.navDropdown.active$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((active) =>
				active
					? this.renderer.addClass(this.el, 'nav-toggle--active')
					: this.renderer.removeClass(this.el, 'nav-toggle--active'),
			)

		this.menuService.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) =>
				collapsed
					? this.renderer.addClass(this.el, 'nav-toggle--collapsed')
					: this.renderer.removeClass(this.el, 'nav-toggle--collapsed'),
			)
	}
}
