import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ContentChildren,
	EventEmitter,
	Input,
	Output,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import { isEmpty } from '@utils/is-empty'
import { Table } from 'primeng/table'

type FilterValue = string | number | undefined | null

@Component({
	selector: 'ms-table',
	host: {
		class: 'table',
	},
	template: `
		@if (tempHeaderContent || tempHeaderControls) {
			<header class="table__header">
				<ms-overscroll [enableScrollX]="true" [enableScrollY]="false">
					<ms-row alignX="between" alignY="center" gap="15px" [wrap]="false">
						<ms-col class="flex-grow-1" size="auto">
							@if (tempHeaderContent) {
								<ng-container [ngTemplateOutlet]="tempHeaderContent" />
							}
						</ms-col>

						<ms-col size="auto">
							<ms-btn-toolbar position="end">
								@if (tempHeaderControls) {
									<ng-container [ngTemplateOutlet]="tempHeaderControls" />
								}
							</ms-btn-toolbar>
						</ms-col>
					</ms-row>
				</ms-overscroll>
			</header>
		}

		<ng-content select="p-table"></ng-content>

		@if (tempFooterContent || tempFooterControls) {
			<footer class="table__footer">
				<ms-overscroll [enableScrollX]="true" [enableScrollY]="false">
					<ms-row alignX="between" alignY="center" gap="15px" [wrap]="false">
						<ms-col class="flex-grow-1" size="auto">
							@if (tempFooterContent) {
								<ng-container [ngTemplateOutlet]="tempFooterContent" />
							}
						</ms-col>

						<ms-col size="auto">
							<ms-btn-toolbar position="end">
								@if (tempFooterControls) {
									<ng-container [ngTemplateOutlet]="tempFooterControls" />
								}
							</ms-btn-toolbar>
						</ms-col>
					</ms-row>
				</ms-overscroll>
			</footer>
		}
	`,
	styleUrls: ['./table.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TableService],
})
export class TableComponent extends BaseComponent implements AfterContentInit {
	private _tableService = inject(TableService)

	@Output()
	public tableSelectionChange = new EventEmitter<any>()

	public tempHeaderContent?: TemplateRef<any>

	public tempHeaderControls?: TemplateRef<any>

	public tempFooterContent?: TemplateRef<any>

	public tempFooterControls?: TemplateRef<any>

	private _defaults = {
		virtualScrollItemSize: 32,
	}

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	// Computed Properties
	// ----------------------------------------

	@Input()
	public get enableSelect() {
		return this._tableService?.isRowSelectEnabled
	}

	public set enableSelect(value) {
		this._tableService.isRowSelectEnabled = value
	}

	public get pTable() {
		return this._tableService?.pTable
	}

	@ContentChild(Table, { descendants: false })
	public set pTable(value) {
		this._tableService.pTable = value
	}

	@Input()
	public get uid() {
		return this._tableService?.uid
	}

	public set uid(value) {
		this._tableService.uid = value
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		this._setTemplates()
		this._setTableDefaults()

		this.cdRef.markForCheck()

		this.pTable?.tableService.selectionSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((event) => {
				this.tableSelectionChange.emit(event)
			})

		this.pTable?.filterService?.register(
			'isEmpty',
			(value: FilterValue, filter: FilterValue) => isEmpty(value),
		)

		this.pTable?.filterService?.register(
			'isNotEmpty',
			(value: FilterValue, filter: FilterValue) => !isEmpty(value),
		)
	}

	// Private Methods
	// ----------------------------------------

	private _setTemplates() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'header-content':
					this.tempHeaderContent = item.template
					break

				case 'header-controls':
					this.tempHeaderControls = item.template
					break

				case 'footer-content':
					this.tempFooterContent = item.template
					break

				case 'footer-controls':
					this.tempFooterControls = item.template
					break
			}
		})
	}

	private _setTableDefaults() {
		if (!this.pTable) return

		if (!this.pTable.virtualScrollItemSize) {
			this.pTable.virtualScrollItemSize = this._defaults.virtualScrollItemSize
		}
	}
}
