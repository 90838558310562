import {
	TableBodyComponent,
	TableColComponent,
	TableComponent,
	TableEmptyComponent,
	TableExpandComponent,
	TableExpansionComponent,
	TableExportComponent,
	TableFilterComponent,
	TableFooterComponent,
	TableHeaderComponent,
	TableRowComponent,
	TableSelectAllComponent,
	TableSelectComponent,
	TableSortComponent,
	TableStatComponent,
	TableStatPickerComponent,
	TableSummaryComponent,
	TableToggleColComponent,
} from '.'
import { colValuePipe } from './pipes/col-value.pipe'
import {
	CommonModule,
	CurrencyPipe,
	DatePipe,
	DecimalPipe,
} from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { A11yModule } from '@components/a11y/a11y.module'
import { ButtonModule } from '@components/button/button.module'
import { CardModule } from '@components/card/card.module'
import { DropdownModule } from '@components/dropdown/dropdown.module'
import { FormModule } from '@components/form/form.module'
import { GridModule } from '@components/grid/grid.module'
import { IconModule } from '@components/icon/icon.module'
import { OverlayModule } from '@components/overlay/overlay.module'
import { OverscrollModule } from '@components/overscroll/overscroll.module'
import { ProgressSpinnerModule } from '@components/progress-spinner/progress-spinner.module'
import { StatusIndicatorModule } from '@components/status-indicator/status-indicator.module'
import { TooltipModule } from '@components/tooltip/tooltip.module'
import { DirectivesModule } from '@modules/directives.module'
import { PipesModule } from '@modules/pipes.module'
import { TranslateModule } from '@ngx-translate/core'
import { OrderListModule as PrimeOrderListModule } from 'primeng/orderlist'
import { TableModule as PrimeTableModule } from 'primeng/table'

const components = [
	TableBodyComponent,
	TableColComponent,
	TableComponent,
	TableEmptyComponent,
	TableExpandComponent,
	TableExpansionComponent,
	TableExportComponent,
	TableFilterComponent,
	TableFooterComponent,
	TableHeaderComponent,
	TableRowComponent,
	TableSelectAllComponent,
	TableSelectComponent,
	TableSortComponent,
	TableStatComponent,
	TableSummaryComponent,
	TableStatPickerComponent,
	TableToggleColComponent,
]

const pipes = [colValuePipe]

@NgModule({
	declarations: [...components, ...pipes],
	exports: [PrimeTableModule, ...components, ...pipes],
	imports: [
		CommonModule,
		FormsModule,
		PrimeOrderListModule,
		PrimeTableModule,
		A11yModule,
		ButtonModule,
		CardModule,
		DropdownModule,
		FormModule,
		GridModule,
		IconModule,
		OverlayModule,
		OverscrollModule,
		ProgressSpinnerModule,
		StatusIndicatorModule,
		TooltipModule,
		DirectivesModule,
		PipesModule,
		TranslateModule,
	],
	providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class TableModule {}
