import { OverlayComponent } from '../overlay/overlay.component'
import {
	AnimationEvent,
	animateChild,
	group,
	query,
	transition,
	trigger,
	useAnimation,
} from '@angular/animations'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	OnInit,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { fadeIn, fadeOut } from '@animations/fade.animation'
import { zoomIn, zoomOut } from '@animations/zoom.animation'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

const backdropAnimation = trigger('backdropAnimation', [
	transition(
		':enter',
		useAnimation(fadeIn, {
			params: { timings: '300ms ease-in-out' },
		}),
	),
	transition(
		':leave',
		useAnimation(fadeOut, {
			params: { timings: '300ms ease-in-out' },
		}),
	),
])

const closeAnimation = trigger('closeAnimation', [
	transition(
		':enter',
		useAnimation(zoomIn, {
			params: { timings: '300ms 100ms ease-in-out', scale: '0.6' },
		}),
	),
	transition(
		':leave',
		useAnimation(zoomOut, {
			params: { timings: '300ms ease-in-out', scale: '0.6' },
		}),
	),
])

const modalAnimation = trigger('modalAnimation', [
	transition(
		':leave',
		group([
			useAnimation(zoomOut, {
				params: { timings: '300ms ease-in-out', scale: 0.9 },
			}),
			query('.modal__close', animateChild()),
		]),
	),
	transition(
		':enter',
		group([
			useAnimation(zoomIn, {
				params: { timings: '300ms ease-in-out', scale: 0.9 },
			}),
			query('.modal__close', animateChild()),
		]),
	),
])

@Component({
	selector: 'ms-modal',
	host: {
		class: 'modal',
	},
	templateUrl: './modal.component.html',
	styleUrls: ['../overlay/overlay.component.scss', './modal.component.scss'],
	animations: [backdropAnimation, closeAnimation, modalAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent
	extends OverlayComponent
	implements OnInit, AfterContentInit
{
	@Input()
	public size: 'small' | 'default' | 'medium' | 'large' = 'default'

	public tempHeader?: TemplateRef<any>

	public tempContent?: TemplateRef<any>

	public tempFooter?: TemplateRef<any>

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		super.ngOnInit()

		this._setClasses()
	}

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'header':
					this.tempHeader = item.template
					break

				case 'content':
					this.tempContent = item.template
					break

				case 'footer':
					this.tempFooter = item.template
					break
			}
		})
	}

	// Public Methods
	// ----------------------------------------

	public onAnimationStart(e: AnimationEvent) {
		this.state = e.fromState === 'void' ? 'opening' : 'closing'

		if (this.state === 'opening') {
			this.overlayWillOpen.emit()
			this.renderer.addClass(this.el, 'modal--active')
			this.renderer.setStyle(this.el, '--modal-z-index', this.zIndex, 2)
		} else {
			this.overlayWillClose.emit()
		}

		window.dispatchEvent(new CustomEvent('resize')) // HACK: Fix table component virtual scroll height
	}

	public onAnimationDone(e: AnimationEvent) {
		this.state = e.fromState === 'void' ? 'open' : 'closed'

		if (this.state === 'closed') {
			this.overlayDidClose.emit()
			this.renderer.removeClass(this.el, 'modal--active')
			this.renderer.removeStyle(this.el, '--modal-z-index', 2)
		} else {
			this.overlayDidOpen.emit()
		}

		window.dispatchEvent(new CustomEvent('resize')) // HACK: Fix table component virtual scroll height
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		if (this.size) {
			this.renderer.addClass(this.el, `modal--${this.size}`)
		}
	}
}
