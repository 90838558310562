import { NgModule } from '@angular/core'
import { ComponentsModule } from '@modules/components.module'
import { DirectivesModule } from '@modules/directives.module'
import { LayoutsModule } from '@modules/layouts.module'
import { PipesModule } from '@modules/pipes.module'
import { TranslateModule } from '@ngx-translate/core'

const modules = [
	ComponentsModule,
	DirectivesModule,
	LayoutsModule,
	PipesModule,
	TranslateModule,
]

@NgModule({
	exports: [...modules],
	imports: [...modules],
})
export class SharedModule {}
