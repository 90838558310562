import { BaseDirective } from '@abstract/base.directive'
import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	OnInit,
	Optional,
	Output,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgControl, NgModel } from '@angular/forms'

@Directive({
	selector: 'textarea[autoresize]',
})
export class AutoResizeDirective
	extends BaseDirective<HTMLTextAreaElement>
	implements OnInit, AfterViewInit
{
	@Output() textareaResize: EventEmitter<any> = new EventEmitter()

	public filled = false

	public minHeight: number

	constructor(
		@Optional() public ngModel: NgModel,
		@Optional() public control: NgControl,
	) {
		super()
	}

	public ngOnInit() {
		if (this.ngModel) {
			this.ngModel.valueChanges
				?.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(() => {
					this.resize()
				})
		}

		if (this.control) {
			this.control.valueChanges
				?.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(() => {
					this.resize()
				})
		}
	}

	public ngAfterViewInit(): void {
		this.el.rows = 1
		this.minHeight = this.el.offsetHeight

		this.resize()
		this.cdRef.detectChanges()
	}

	@HostListener('input', ['$event'])
	public onInput(e: Event) {
		this.resize(e)
	}

	@HostListener('focus', ['$event'])
	public onFocus(e: Event) {
		this.resize(e)
	}

	@HostListener('blur', ['$event'])
	public onBlur(e: Event) {
		this.resize(e)
	}

	private resize(event?: Event) {
		this.filled =
			(this.el.value && this.el.value.length) ||
			(this.ngModel && this.ngModel.model)
		this.el.style.height = 'auto'

		if (this.el.scrollHeight > 0) {
			this.el.style.height = this.el.scrollHeight + 'px'
		}

		if (
			parseFloat(this.el.style.height) >= parseFloat(this.el.style.maxHeight)
		) {
			this.el.style.overflowY = 'scroll'
			this.el.style.height = this.el.style.maxHeight
		} else {
			this.el.style.overflow = 'hidden'
		}

		this.textareaResize.emit(event || {})
	}
}
