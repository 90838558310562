import { VisuallyHiddenComponent } from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [VisuallyHiddenComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class A11yModule {}
