import { Injectable } from '@angular/core'
import { Notification } from '@models/misc/Notification'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	public notifications$ = new BehaviorSubject<Notification[]>([])

	// Computed Properties
	// ----------------------------------------

	public get notifications() {
		return this.notifications$.value
	}

	public set notifications(value: Notification[]) {
		this.notifications$.next(value)
	}

	// Public Methods
	// ----------------------------------------

	public create(notification: Notification) {
		if (!notification.timer) {
			notification.timer = 5000
		}

		this.notifications = [...this.notifications, notification]
	}

	public destroy(index: number) {
		const notifications = this.notifications
		notifications.splice(index, 1)
		this.notifications = [...notifications]
	}

	public destroyAll() {
		this.notifications = []
	}
}
