import { MenuService } from '../../services/menu.service'
import { NavItem } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'

@Component({
	selector: 'ms-nav-item',
	host: {
		class: 'nav-item',
	},
	template: `
		@if (navItem.dropdownItems) {
			<ms-nav-link
				[icon]="navItem.icon"
				[label]="navItem.label"
				[navDropdown]="navDropdown"
				[path]="navItem.path"
			></ms-nav-link>

			<ms-nav-toggle [navDropdown]="navDropdown"></ms-nav-toggle>

			<ms-nav-dropdown
				#navDropdown
				[navItems]="navItem.dropdownItems"
			></ms-nav-dropdown>
		} @else {
			<ms-nav-link
				[icon]="navItem.icon"
				[label]="navItem.label"
				[path]="navItem.path"
			></ms-nav-link>
		}
	`,
	styleUrls: ['./nav-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent extends BaseComponent implements OnInit {
	private _paths: string[] = []

	@Input()
	public navItem: NavItem

	constructor(
		private _router: Router,
		public menuService: MenuService,
	) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (this.navItem.path) {
			this._paths.push(this.navItem.path)
		}

		this.navItem.dropdownItems?.forEach((i) => {
			if (i.path) this._paths.push(i.path)
		})

		if (this._paths.length) {
			this._setClasses()

			this._router.events
				.pipe(
					takeUntilDestroyed(this.destroyRef),
					filter((e: any) => e instanceof NavigationEnd),
				)
				.subscribe(() => this._setClasses())
		}
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		const url = this._router.url

		if (this._paths.includes(url)) {
			this.renderer.addClass(this.el, 'nav-item--active')
		} else {
			this.renderer.removeClass(this.el, 'nav-item--active')
		}
	}
}
