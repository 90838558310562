import { CounterComponent } from './counter.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [CounterComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class CounterModule {}
