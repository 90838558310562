import { ControlComponent } from '../control/control.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import flatpickr from 'flatpickr'
import { BaseOptions } from 'flatpickr/dist/types/options'

@Component({
	selector: 'ms-date-picker',
	host: {
		class: 'date-picker-group',
	},
	template: `
		<label class="form-group__wrapper">
			<ng-content select="ms-visually-hidden, [visually-hidden]"></ng-content>

			@if (label) {
				<div class="form-group__label">
					<ms-label [required]="required">{{ label }}</ms-label>
				</div>
			}

			<div class="form-group__control">
				<div class="control">
					<ng-content select="input[type='text']"></ng-content>
				</div>

				<div class="form-group__btn">
					@switch (!!inputEl?.value) {
						@case (true) {
							<button
								btn-color="brand"
								btn-link-icon
								tooltip="Clear date"
								type="button"
								[disabled]="disabled"
								(click)="clear()"
							>
								<ms-icon name="close" />
							</button>
						}
						@default {
							<button
								btn-color="brand"
								btn-link-icon
								tooltip="Open date picker"
								type="button"
								[disabled]="disabled"
								(click)="open()"
							>
								<ms-icon name="calendar" />
							</button>
						}
					}
				</div>
			</div>

			@if ((messages$ | async)?.length) {
				<div class="form-group__messages">
					<ms-form-messages [messages$]="messages$"></ms-form-messages>
				</div>
			}
		</label>
	`,
	styleUrls: [
		'../form-group/form-group.component.scss',
		'../control/control.component.scss',
		'date-picker.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent
	extends ControlComponent
	implements AfterContentInit, OnChanges, OnDestroy
{
	@Input()
	public altFormat = 'J F Y'

	@Input()
	public minDate?: string

	@Input()
	public maxDate?: string

	@Input()
	public enableDates?: string[]

	@Input()
	public disableDates?: string[]

	@Input()
	public isStatic = false

	public instance?: flatpickr.Instance

	// Computed Properties
	// ----------------------------------------

	public get instanceInput() {
		return this.instance?.input
	}

	public get instanceAltInput() {
		return this.instance?.altInput
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		super.ngAfterContentInit()

		const options: any = {
			static: this.isStatic,
			altInput: true,
			altFormat: this.altFormat,
			altInputClass: 'control',
		}

		if (this.minDate) options.minDate = this.minDate

		if (this.maxDate) options.maxDate = this.maxDate

		if (this.enableDates) options.enable = this.enableDates

		if (this.disableDates) options.enable = this.disableDates

		if (this.inputEl) {
			this.instance = flatpickr(this.inputEl, options)
		}

		this.ngModel?.valueChanges
			?.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.instance?.setDate(value)
			})

		this.ngModel?.statusChanges
			?.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((status) => {
				if (this.disabled) {
					this.disable()
				} else {
					this.enable()
				}
			})
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.minDate && changes.minDate.currentValue) {
			this.setOption('minDate', changes.minDate.currentValue)
		}

		if (changes.maxDate && changes.maxDate.currentValue) {
			this.setOption('minDate', changes.maxDate.currentValue)
		}
	}

	public ngOnDestroy() {
		this.instance?.destroy()
	}

	// Public Methods
	// ----------------------------------------

	public setOption(option: keyof BaseOptions, value: any) {
		this.instance?.set(option, value)
	}

	public disable() {
		if (!this.instance) return

		this.renderer.setAttribute(this.instanceInput, 'disabled', 'disabled')
		this.renderer.setAttribute(this.instanceAltInput, 'disabled', 'disabled')
	}

	public enable() {
		if (!this.instance) return

		this.renderer.removeAttribute(this.instanceInput, 'disabled')
		this.renderer.removeAttribute(this.instanceAltInput, 'disabled')
	}

	public toggle() {
		if (!this.instance) return

		if (this.instance.isOpen) {
			this.close()
		} else {
			this.open()
		}
	}

	public open() {
		setTimeout(() => this.instance?.open(), 0)
	}

	public close() {
		setTimeout(() => this.instance?.close(), 0)
	}

	public clear() {
		setTimeout(() => {
			this.instance?.clear()

			this.ngModel?.reset(null)

			if (this.inputEl) {
				this.inputEl.value = ''
			}
		}, 0)
	}
}
