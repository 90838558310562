import { FormGroupComponent } from '../form-group/form-group.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-checkbox',
	host: {
		class: 'checkbox',
	},
	template: `
		<label class="checkbox__wrapper">
			<ng-content select="input[type='checkbox']"></ng-content>

			<ng-content select="ms-visually-hidden, [visually-hidden]"></ng-content>

			<span class="checkbox__btn"></span>

			<span class="checkbox__indicator"></span>

			<span class="checkbox__label">
				@if (label) {
					<ms-label [required]="required">{{ label }}</ms-label>
				}
				<ms-form-messages [messages$]="messages$"></ms-form-messages>
			</span>
		</label>
	`,
	styleUrls: [
		'../form-group/form-group.component.scss',
		'./checkbox.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FormGroupComponent {}

@Component({
	selector: 'ms-checkbox-list',
	host: {
		class: 'checkbox-list',
	},
	template: `
		@if (label) {
			<ms-label>{{ label }}</ms-label>
		}

		<div class="checkbox-list">
			<ng-content select="ms-checkbox"></ng-content>
		</div>
	`,
	styleUrls: [
		'../form-group/form-group.component.scss',
		'./checkbox.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListComponent extends BaseComponent {
	@Input()
	public label?: string
}
