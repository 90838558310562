import { Pipe, PipeTransform } from '@angular/core'
import { RecursiveKeyOf } from '@models/util/RecursiveKeyOf'
import { get, isObject } from 'lodash-es'

@Pipe({
	name: 'get',
	standalone: true,
})
export class GetPipe implements PipeTransform {
	public transform<T extends object>(
		item?: string | number | T,
		path?: RecursiveKeyOf<T>,
	) {
		if (!item || !isObject(item)) return item

		if (!path || typeof path !== 'string') return item

		return get(item, path)
	}
}
