import { Injectable } from '@angular/core'
import { StorageService } from '@services/storage.service'
import { Table as PrimeTable } from 'primeng/table'

@Injectable()
export class TableService {
	public pTable?: PrimeTable

	public uid?: string

	public isRowSelectEnabled = false

	// Computed Properties
	// ----------------------------------------

	public get columns() {
		return (this.pTable?.columns || []) as MS.Table.Column[]
	}

	public set columns(columns) {
		if (!this.pTable) return
		this.pTable.columns = [...columns]
	}

	public get rows() {
		return this.pTable?.filteredValue || this.pTable?.value || []
	}

	public get data() {
		if (!this.uid) return null

		const data = this.storage.getItem<MS.Table.Data>(this.uid)
		return data?.value || null
	}

	public set data(value) {
		if (!this.uid) return

		if (!value) {
			this.storage.removeItem(this.uid)
			return
		}

		this.storage.setItem<MS.Table.Data>(this.uid, value)
	}

	public get isRowExpandEnabled() {
		return !!this.pTable?.expandedRowTemplate
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(public storage: StorageService) {}
}
