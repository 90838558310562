import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-icon-sort',
	host: {
		'class': 'icon-sort',
		'[class.icon-sort--active]': 'active',
		'[class.icon-sort--asc]': 'sortOrder === "ascending"',
		'[class.icon-sort--desc]': 'sortOrder === "descending"',
	},
	template: `
		<span></span>
		<span></span>
		<span></span>
		<span></span>
	`,
	styleUrls: ['./icon-sort.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSortComponent {
	@Input()
	public active = true

	@Input()
	public sortOrder: 'ascending' | 'descending' | 'none' = 'none'
}
