import { Pipe, PipeTransform } from '@angular/core'
import { getTypeOf } from '@utils/typeof'

@Pipe({
	name: 'typeof',
	standalone: true,
})
export class TypeofPipe implements PipeTransform {
	public transform<T>(collection: any) {
		return getTypeOf(collection)
	}
}
