import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { SessionService } from '@services/session.service'

@Injectable({
	providedIn: 'root',
})
export class IsNotAuthenticatedGuard {
	router = inject(Router)

	sessionService = inject(SessionService)

	public canActivate() {
		if (!this.sessionService.isLoggedIn()) return true

		this.router.navigate(['/animals/mobs'])

		return false
	}
}
