import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

@Component({
	selector: 'ms-overlay-wrapper',
	host: {
		class: 'overlay-wrapper',
	},
	template: `<ng-content />`,
	styleUrls: ['./overlay-wrapper.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayWrapperComponent
	extends BaseComponent
	implements AfterContentInit, AfterViewInit
{
	@ViewChild('overlay')
	public overlay: OverlayComponent

	@Output()
	public overlayDidClose: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayDidOpen: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayWillClose: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayWillOpen: EventEmitter<any> = new EventEmitter<any>()

	public tempActions?: TemplateRef<any>

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	// Computed Properties
	// ----------------------------------------

	public get active() {
		return this.overlay.active$.value
	}

	public set active(value) {
		this.overlay.active$.next(value)
	}

	public get disabled() {
		return this.overlay.disabled$.value
	}

	public set disabled(value) {
		this.overlay.disabled$.next(value)
	}

	public get state() {
		return this.overlay.state$.value
	}

	public set state(value) {
		this.overlay.state$.next(value)
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'actions':
					this.tempActions = item.template
					break
			}
		})
	}

	public ngAfterViewInit() {
		if (!this.overlay) {
			throw new Error(
				'OverlayWrapperComponent: does not wrap a component of type OverlayComponent',
			)
		}
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		this.overlay.toggle()
		return this
	}

	public open() {
		this.overlay.open()
		return this
	}

	public close() {
		this.overlay.close()
		return this
	}

	public enable() {
		this.overlay.enable()
		return this
	}

	public disable() {
		this.overlay.disable()
		return this
	}
}
