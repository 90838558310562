import { ContentComponent } from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ProgressSpinnerModule } from '@components/progress-spinner/progress-spinner.module'

const components = [ContentComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, ProgressSpinnerModule],
})
export class ContentModule {}
