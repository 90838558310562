import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-embed-item, [embed-item]',
	host: {
		class: 'embed-item',
	},
	template: `<ng-content />`,
	styleUrls: ['./embed-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedItemComponent extends BaseComponent {}
