import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: '[dropdown-menu-item]',
	host: {
		'class': 'dropdown-menu-item',
		'[class.dropdown-menu-item--active]': 'active',
		'[class.dropdown-menu-item--disabled]': 'disabled',
	},
	template: `<ng-content />`,
	styleUrls: ['./dropdown-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent extends BaseComponent {
	@Input()
	public active = false

	@Input()
	public disabled = false
}
