import { BaseDirective } from '@abstract/base.directive'
import { Directive, Input } from '@angular/core'
import {
	NG_VALIDATORS,
	UntypedFormControl,
	ValidationErrors,
	Validator,
} from '@angular/forms'
import { toString } from 'lodash-es'

@Directive({
	selector: '[unique]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: UniqueDirective,
			multi: true,
		},
	],
})
export class UniqueDirective extends BaseDirective implements Validator {
	@Input()
	public unique: string[] | undefined

	public validate(control: UntypedFormControl): ValidationErrors | null {
		if (!control.root || !control.value || !this.unique) return null

		const val = toString(control.value).toLowerCase().trim()
		const uniqVal = this.unique.map((item) => item.toLowerCase().trim())

		if (!uniqVal.includes(val)) return null

		const err = {
			unique: {
				message: this.$t('validators.unique'),
			},
		}

		control.root.setErrors(err)

		return err
	}
}
