@if (active$ | async) {
	<ms-backdrop
		[@backdropAnimation]
		class="drawer__backdrop"
		(backdropClick)="close()"
	/>

	<article
		[@drawerAnimation]
		class="drawer__dialog"
		(@drawerAnimation.done)="onAnimationDone($event)"
		(@drawerAnimation.start)="onAnimationStart($event)"
	>
		<div class="drawer__content">
			@if (tempHeader) {
				<header class="drawer__header">
					<ng-container [ngTemplateOutlet]="tempHeader" />
				</header>
			}

			<main class="drawer__scroll-body">
				<div class="drawer__body">
					<ng-content />
					@if (tempContent) {
						<ng-container [ngTemplateOutlet]="tempContent" />
					}
				</div>
			</main>

			@if (tempFooter) {
				<footer class="drawer__footer">
					<ng-container [ngTemplateOutlet]="tempFooter" />
				</footer>
			}
		</div>

		<div [@closeAnimation] class="drawer__close">
			<button
				btn-color="brand"
				btn-solid-icon
				type="button"
				[attr.aria-label]="$t('common.close')"
				(click)="close()"
			>
				<ms-icon name="close" />
			</button>
		</div>
	</article>
}
