import { BaseComponent } from '../../abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-select, ms-select-outline',
	host: {
		class: 'select',
	},
	template: `<ng-content />`,
	styleUrls: ['./select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends BaseComponent implements OnInit {
	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		this.el.hasAttribute('ms-select')
			? this.renderer.addClass(this.el, 'select--solid')
			: this.renderer.removeClass(this.el, 'select--solid')
		this.el.hasAttribute('ms-select-outline')
			? this.renderer.addClass(this.el, 'select--outline')
			: this.renderer.removeClass(this.el, 'select--outline')
	}
}
