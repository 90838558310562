import { BREAKPOINTS } from '@constants/breakpoints'

export const isSM = () =>
	window.matchMedia(`(min-width: ${BREAKPOINTS.sm}px)`).matches

export const isMD = () =>
	window.matchMedia(`(min-width: ${BREAKPOINTS.md}px)`).matches

export const isLG = () =>
	window.matchMedia(`(min-width: ${BREAKPOINTS.lg}px)`).matches

export const isXL = () =>
	window.matchMedia(`(min-width: ${BREAKPOINTS.xl}px)`).matches

export const isXXL = () =>
	window.matchMedia(`(min-width: ${BREAKPOINTS.xxl}px)`).matches
