import { animate, animation, style } from '@angular/animations'

export const fadeIn = animation([
	style({ opacity: 0 }),
	animate('{{ timings }}', style({ opacity: 1 })),
])

export const fadeOut = animation([
	style({ opacity: 1 }),
	animate('{{ timings }}', style({ opacity: 0 })),
])
