import { Pipe, PipeTransform } from '@angular/core'
import { isNumeric } from '@utils/is-numeric'
import { isObject, sortBy } from 'lodash-es'

@Pipe({
	name: 'sortBy',
	standalone: true,
})
export class SortByPipe implements PipeTransform {
	public transform<T>(
		collection: T[] | null,
		prop: string | number,
		order: 'asc' | 'desc' = 'asc',
	): T[] | null {
		if (!collection || !collection.length) return collection

		if (!Array.isArray(collection)) {
			throw new Error(`SortByPipe: Collection is not iterable.`)
		}

		if (!isObject(collection[0])) {
			throw new Error(`SortByPipe: Collection items are not an object.`)
		}

		if (!Object.prototype.hasOwnProperty.call(collection[0], prop)) {
			return collection
		}

		const sortedCollection = sortBy(collection, (o: any) => {
			const val = o[prop]

			if (typeof val === 'string' && isNumeric(val)) {
				return parseFloat(val)
			}

			if (typeof val === 'string') {
				return val.toLowerCase()
			}

			return val
		})

		if (order === 'asc') {
			return sortedCollection
		} else {
			return sortedCollection.reverse()
		}
	}
}
