import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
	selector: 'ng-template[name]',
})
export class NamedTemplateDirective {
	@Input()
	public name: string

	constructor(public template: TemplateRef<any>) {}
}
