import { OverlayService } from '../../services/overlay.service'
import { BaseComponent } from '@abstract/base.component'
import { DOCUMENT } from '@angular/common'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NavigationStart, Router, RouterEvent } from '@angular/router'
import { uniqueId } from 'lodash-es'
import { BehaviorSubject, filter } from 'rxjs'

type OverlayState = 'opening' | 'open' | 'closing' | 'closed'

@Component({
	host: {
		'role': 'dialog',
		'[attr.aria-hidden]': '!active',
		'[attr.tabindex]': '!active || disabled ? -1 : (tabIndex || 0)',
	},
	template: ``,
	styleUrls: ['./overlay.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent
	extends BaseComponent
	implements OnInit, OnDestroy
{
	@Input()
	public active$ = new BehaviorSubject(false)

	@Input()
	public disabled$ = new BehaviorSubject(false)

	@Input()
	public id?: string

	@Output()
	public overlayWillOpen: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayDidOpen: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayWillClose: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public overlayDidClose: EventEmitter<any> = new EventEmitter<any>()

	public state$ = new BehaviorSubject<OverlayState>('closed')

	public zIndex = -1

	// Computed Properties
	// ----------------------------------------

	public get active() {
		return this.active$.value
	}

	public set active(value) {
		this.active$.next(value)
	}

	public get disabled() {
		return this.disabled$.value
	}

	public set disabled(value) {
		this.disabled$.next(value)
	}

	public get state() {
		return this.state$.value
	}

	public set state(value) {
		this.state$.next(value)
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		@Inject(DOCUMENT) public document: Document,
		public overlayService: OverlayService,
		public router: Router,
	) {
		super()
	}

	public ngOnInit() {
		if (!this.id) {
			this.id = uniqueId('overlay-')
			this.renderer.setAttribute(this.el, 'id', this.id)
		}

		this.renderer.appendChild(this.document.body, this.el)

		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((e: any) => e instanceof NavigationStart),
			)
			.subscribe((e: RouterEvent) => {
				this.overlayService.close(this)
			})
	}

	public ngOnDestroy() {
		this.renderer.removeChild(this.document.body, this.el)
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		this.overlayService.toggle(this)
		return this
	}

	public open() {
		this.overlayService.open(this)
		return this
	}

	public close() {
		this.overlayService.close(this)
		return this
	}

	public enable() {
		this.disabled = false
		return this
	}

	public disable() {
		this.disabled = true
		return this
	}
}
