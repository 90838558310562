import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'td[tableCol], th[tableCol]',
	host: {
		'class': 'table-col',
		'[class.table-col--control]': 'isControl',
	},
	template: `
		<div class="table-col__inner">
			<ng-content />
		</div>
	`,
	styleUrls: ['./table-col.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColComponent extends BaseComponent implements OnInit {
	@Input()
	public isControl: boolean = false

	@Input()
	public align: 'left' | 'center' | 'right' = 'left'

	public ngOnInit() {
		this.renderer.addClass(this.el, 'table-col--align-' + this.align)
	}
}
