import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-navbar, [navbar]',
	host: {
		'class': 'navbar',
		'[class.navbar--tabs]': 'tabs',
	},
	template: `<ng-content />`,
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
	@Input()
	public tabs: boolean = false
}
