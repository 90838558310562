import { BaseComponent } from '../../abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-status-indicator',
	host: {
		class: 'status',
	},
	template: `@if (status) {
		<span class="status__wrapper" [ngClass]="status | lowercase">{{
			status
		}}</span>
	}`,
	styleUrls: ['./status-indicator.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent extends BaseComponent implements OnInit {
	@Input()
	public status?: 'Alive' | 'Dead' | 'Culled' | 'Sold' | 'Reference' | 'Unknown'

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		if (this.status) {
			this.renderer.addClass(this.el, `status--${this.status.toLowerCase()}`)
		}
	}
}
