<button
	btn-color="brand"
	btn-link-icon
	type="button"
	[overlay-toggle]="overlay"
	[tooltip]="$t('components.table.columns')"
>
	<ms-icon name="tbl-columns" />
</button>

<ms-drawer #overlay class="toggle-col-drawer">
	<ng-template name="header">
		<ms-row alignX="between" alignY="center" gap="10px">
			<ms-col>
				<p overlay-title>
					{{ 'components.table.columns' | translate }}
				</p>
			</ms-col>

			<ms-col size="auto">
				<button btn-color="success" btn-solid type="button" (click)="apply()">
					{{ 'common.apply' | translate }}
				</button>
			</ms-col>

			<ms-col size="auto">
				<button btn-solid type="button" (click)="reset()">
					{{ 'common.reset' | translate }}
				</button>
			</ms-col>
		</ms-row>
	</ng-template>

	<ng-template name="content">
		<div class="toggle-col-item">
			<ms-checkbox label="All">
				<input
					type="checkbox"
					[checked]="isToggleAllChecked()"
					[indeterminate]="isToggleAllIndeterminate()"
					(change)="toggleAllCols()"
				/>
			</ms-checkbox>
		</div>

		<p-orderList
			[dragdrop]="true"
			[value]="orderedCols"
			(onReorder)="onReorder()"
		>
			<ng-template let-col pTemplate="item">
				<div class="toggle-col-item">
					<ms-checkbox [label]="col.header">
						<input
							type="checkbox"
							[checked]="isColSelected(col)"
							[disabled]="isColDisabled(col)"
							(change)="toggleCol(col)"
						/>
					</ms-checkbox>

					<ms-icon name="drag" />
				</div>
			</ng-template>
		</p-orderList>
	</ng-template>
</ms-drawer>
