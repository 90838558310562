import {
	AccordionComponent,
	AccordionPanelComponent,
	AccordionTableComponent,
} from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'

const components = [
	AccordionComponent,
	AccordionPanelComponent,
	AccordionTableComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, ButtonModule, IconModule],
})
export class AccordionModule {}
