import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-table-sort',
	template: `
		<button
			btn-color="none"
			btn-link
			class="table-sort"
			type="button"
			[active]="active"
			[pSortableColumn]="field"
			[tooltip]="sortLabel"
		>
			<ms-icon-sort [active]="active" [sortOrder]="sortOrder"></ms-icon-sort>
		</button>
	`,
	styleUrls: ['./table-sort.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortComponent extends BaseComponent implements OnInit {
	public active = false

	@Input()
	public field: MS.Table.Column['field']

	@Input()
	public type: MS.Table.Column['type']

	@Input()
	public valueFn: MS.Table.Column['valueFn']

	@Input()
	public sortLabel = this.$t('components.table.sort')

	public sortOrder: 'ascending' | 'descending' | 'none' = 'none'

	// Computed Properties
	// ----------------------------------------

	public get pTable() {
		return this.tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(public tableService: TableService) {
		super()
	}

	public ngOnInit() {
		if (!this.pTable) {
			throw new Error('TableSortComponent: table cannot be undefined.')
		}

		if (!this.field) {
			throw new Error('TableSortComponent: field cannot be undefined.')
		}

		this._updateSortState()

		this.pTable.tableService.sortSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((e) => {
				this._updateSortState()
			})
	}

	// Private Methods
	// ----------------------------------------

	private _updateSortState() {
		if (!this.pTable) return

		this.active = this.pTable.isSorted(this.field)
		this.sortOrder = this.active
			? this.pTable.sortOrder === 1
				? 'ascending'
				: 'descending'
			: 'none'

		if (!this.active) {
			this.sortLabel = this.$t('components.table.sort')
			this.cdRef.markForCheck()
			return
		}

		switch (this.type) {
			case 'date':
				this.sortLabel =
					this.sortOrder === 'ascending'
						? this.$t('components.table.sort_date.asc')
						: this.$t('components.table.sort_date.desc')
				break

			case 'currency':
			case 'number':
			case 'percent':
				this.sortLabel =
					this.sortOrder === 'ascending'
						? this.$t('components.table.sort_number.asc')
						: this.$t('components.table.sort_number.desc')
				break

			default:
				this.sortLabel =
					this.sortOrder === 'ascending'
						? this.$t('components.table.sort_string.asc')
						: this.$t('components.table.sort_string.desc')
				break
		}

		this.cdRef.markForCheck()
	}
}
