import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-unauth-layout',
	host: {
		class: 'unauth-layout',
	},
	template: `
		<div class="unauth-layout__container">
			<router-outlet></router-outlet>
		</div>
	`,
	styleUrls: ['./unauth.layout.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthLayout extends BaseComponent {}
