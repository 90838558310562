import { A11yModule } from '../a11y/a11y.module'
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module'
import {
	CheckboxComponent,
	CheckboxListComponent,
} from './components/checkbox/checkbox.component'
import { ControlComponent } from './components/control/control.component'
import { DatePickerComponent } from './components/date-picker/date-picker.component'
import { FieldsetComponent } from './components/fieldset/fieldset.component'
import { FormButtonsComponent } from './components/form-btns/form-btns.component'
import { FormGroupComponent } from './components/form-group/form-group.component'
import { FormMessagesComponent } from './components/form-messages/form-messages.component'
import { FormRequiredComponent } from './components/form-required/form-required.component'
import { LabelComponent } from './components/label/label.component'
import {
	RadioButtonComponent,
	RadioButtonsComponent,
} from './components/radio-buttons/radio-buttons.component'
import {
	RadioComponent,
	RadioListComponent,
} from './components/radio/radio.component'
import { RangeComponent } from './components/range/range.component'
import { SwitchComponent } from './components/switch/switch.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { OverlayModule } from '@components/overlay/overlay.module'
import { TranslateModule } from '@ngx-translate/core'
import { SliderModule as PNGSliderModule } from 'primeng/slider'

const components = [
	CheckboxComponent,
	CheckboxListComponent,
	ControlComponent,
	DatePickerComponent,
	FieldsetComponent,
	FormButtonsComponent,
	FormGroupComponent,
	FormMessagesComponent,
	FormRequiredComponent,
	LabelComponent,
	RangeComponent,
	RadioComponent,
	RadioListComponent,
	RadioButtonComponent,
	RadioButtonsComponent,
	SwitchComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		A11yModule,
		CommonModule,
		ButtonModule,
		FormsModule,
		IconModule,
		OverlayModule,
		PNGSliderModule,
		ProgressSpinnerModule,
		TranslateModule,
	],
})
export class FormModule {}
