import { OverlayWrapperComponent } from '../../../overlay/components/overlay-wrapper/overlay-wrapper.component'
import { OverlayComponent } from '../../../overlay/components/overlay/overlay.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-fieldset',
	host: {
		class: 'fieldset',
	},
	template: `
		<fieldset class="fieldset__wrapper">
			@if (legend) {
				<legend class="fieldset__legend">{{ legend }}:</legend>
			}
			@if (overlayToggle) {
				<div class="fieldset__btn">
					<button
						aria-label="Add new"
						btn-color="success"
						btn-outline-icon
						tabindex="1"
						type="button"
						[overlay-toggle]="overlayToggle"
					>
						<ms-icon name="plus" />
					</button>
				</div>
			}

			<div class="fieldset__content">
				<ng-content />
			</div>
		</fieldset>
	`,
	styleUrls: ['./fieldset.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsetComponent extends BaseComponent {
	@Input()
	public legend?: string

	@Input()
	public overlayToggle?: OverlayComponent | OverlayWrapperComponent
}
