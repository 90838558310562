import { BaseDirective } from '@abstract/base.directive'
import { Directive } from '@angular/core'
import {
	NG_VALIDATORS,
	UntypedFormControl,
	ValidationErrors,
	Validator,
} from '@angular/forms'

@Directive({
	selector: 'input[eid]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: InputEidDirective,
			multi: true,
		},
	],
})
export class InputEidDirective extends BaseDirective implements Validator {
	public readonly pattern = /^\d{3} \d{12}$/

	public validate(control: UntypedFormControl): ValidationErrors | null {
		if (!control.root) return null

		const val = control.value

		if (!val || this.pattern.test(val)) return null

		const err = {
			eid: {
				message: this.$t('validators.eid'),
			},
		}

		control.root.setErrors(err)

		return err
	}
}
