import { BaseComponent } from '@abstract/base.component'
import { TemplatePortal } from '@angular/cdk/portal'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import { uniqueId } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-tab-panel',
	host: {
		class: 'tab-panel',
		role: 'tabpanel',
	},
	template: `
		<ng-container [cdkPortalHost]="content" />

		<ng-template #implicitContent>
			<ng-content />
		</ng-template>
	`,
	styleUrls: ['./tab-panel.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPanelComponent
	extends BaseComponent
	implements OnInit, AfterContentInit
{
	@Input()
	public id?: string

	@Input()
	public disabled$ = new BehaviorSubject(false)

	@Input()
	public name?: string | number

	@Input()
	public selected$ = new BehaviorSubject(false)

	@Output()
	public tabClose = new EventEmitter()

	@Output()
	public tabOpen = new EventEmitter()

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates?: QueryList<NamedTemplateDirective>

	@ViewChild('implicitContent', { static: true })
	private _implicitContent: TemplateRef<any>

	private _explicitContent?: TemplateRef<any>

	private _contentPortal: TemplatePortal | null = null

	// Computed Properties
	// ----------------------------------------

	public get content(): TemplatePortal | null {
		return this.selected ? this._contentPortal : null
	}

	public get disabled() {
		return this.disabled$.value
	}

	@Input()
	public set disabled(value) {
		this.disabled$.next(value)
	}

	public get selected() {
		return this.selected$.value
	}

	@Input()
	public set selected(value) {
		this.selected$.next(value)
		this.cdRef.markForCheck()
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(private _vcRef: ViewContainerRef) {
		super()
	}

	public ngOnInit() {
		if (!this.id) {
			this.id = uniqueId('tab-panel-')
			this.renderer.setAttribute(this.el, 'id', this.id)
		}

		this.renderer.setAttribute(this.el, 'aria-labelledby', `${this.id}-btn`)
	}

	public ngAfterContentInit() {
		this._templates?.forEach((item) => {
			switch (item.name) {
				case 'content':
					this._explicitContent = item.template
					break
			}
		})

		this._contentPortal = new TemplatePortal(
			this._explicitContent || this._implicitContent,
			this._vcRef,
		)
	}

	// Public Methods
	// ----------------------------------------

	public open() {
		if (this.disabled) return

		this.tabOpen.emit()
		this.renderer.addClass(this.el, 'tab-panel--selected')
		this.selected = true

		window.dispatchEvent(new CustomEvent('resize')) // HACK: Fix table component virtual scroll height
	}

	public close() {
		if (this.disabled) return

		this.tabClose.emit()
		this.renderer.removeClass(this.el, 'tab-panel--selected')
		this.selected = false
	}
}
