import { SelectComponent } from './select.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [SelectComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class SelectModule {}
