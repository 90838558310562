import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	OnChanges,
	OnInit,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

@Component({
	selector: 'ms-card',
	host: {
		class: 'card',
	},
	template: `
		<div class="card__inner">
			@if (tempHeader) {
				<header class="card__header">
					<ng-container [ngTemplateOutlet]="tempHeader" />
				</header>
			}

			<div class="card__content">
				<ng-content />
				@if (tempContent) {
					<ng-container [ngTemplateOutlet]="tempContent" />
				}
			</div>

			@if (tempFooter) {
				<footer class="card__footer">
					<ng-container [ngTemplateOutlet]="tempFooter" />
				</footer>
			}
		</div>

		@if (tempControls) {
			<ms-btn-toolbar class="card__controls" position="middle">
				<ng-container [ngTemplateOutlet]="tempControls" />
			</ms-btn-toolbar>
		}
	`,
	styleUrls: ['./card.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent
	extends BaseComponent
	implements OnInit, AfterContentInit, OnChanges
{
	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	@Input()
	public foldCorner = false

	@Input()
	public inline = false

	@Input()
	public hover = false

	public tempHeader?: TemplateRef<any>

	public tempContent?: TemplateRef<any>

	public tempFooter?: TemplateRef<any>

	public tempControls?: TemplateRef<any>

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'header':
					this.tempHeader = item.template
					break

				case 'content':
					this.tempContent = item.template
					break

				case 'controls':
					this.tempControls = item.template
					break

				case 'footer':
					this.tempFooter = item.template
					break
			}
		})
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.inline) {
			this._setClasses()
		}
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		this.foldCorner
			? this.renderer.addClass(this.el, 'card--folded-corner')
			: this.renderer.removeClass(this.el, 'card--folded-corner')

		this.hover
			? this.renderer.addClass(this.el, 'card--hoverable')
			: this.renderer.removeClass(this.el, 'card--hoverable')

		if (this.inline) {
			this.renderer.addClass(this.el, 'card--row')
			this.renderer.removeClass(this.el, 'card--column')
		} else {
			this.renderer.removeClass(this.el, 'card--row')
			this.renderer.addClass(this.el, 'card--column')
		}
	}
}
