import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { IsAuthenticatedGuard } from '@guards/is-authenticated.guard'
import { IsNotAuthenticatedGuard } from '@guards/is-not-authenticated.guard'
import { MainLayout } from '@layouts/main/main.layout'
import { UnauthLayout } from '@layouts/unauth/unauth.layout'

const routes: Routes = [
	{
		path: '',
		component: MainLayout,
		canActivate: [IsAuthenticatedGuard],
		children: [
			{
				path: '',
				redirectTo: 'animals/mobs',
				pathMatch: 'full',
			},
			{
				path: 'alerts',
				loadChildren: () =>
					import(`./alerts/alerts.module`).then((m) => m.AlertsModule),
			},
			{
				path: 'animals',
				loadChildren: () =>
					import(`./animals/animals.module`).then((m) => m.AnimalsModule),
			},
			{
				path: 'draft-sets',
				loadChildren: () =>
					import(`./draft-sets/draft-sets.module`).then(
						(m) => m.DraftSetsModule,
					),
			},
			{
				path: 'error',
				loadChildren: () =>
					import(`./error/error.module`).then((m) => m.ErrorModule),
			},
			{
				path: 'import',
				loadChildren: () =>
					import(`./import/import.module`).then((m) => m.ImportModule),
			},
			{
				path: 'profile',
				loadChildren: () =>
					import(`./profile/profile.module`).then((m) => m.ProfileModule),
			},
			{
				path: 'search',
				loadChildren: () =>
					import(`./search/search.module`).then((m) => m.SearchModule),
			},
			{
				path: 'sessions',
				loadChildren: () =>
					import(`./events/events.module`).then((m) => m.EventsModule),
			},
			{
				path: 'settings',
				loadChildren: () =>
					import(`./settings/settings.module`).then((m) => m.SettingsModule),
			},
			{
				path: 'support',
				loadChildren: () =>
					import(`./support/support.module`).then((m) => m.SupportModule),
			},
			{
				path: 'contacts',
				loadChildren: () =>
					import(`./contacts/contacts.module`).then((m) => m.ContactsModule),
			},
		],
	},
	{
		path: '',
		component: UnauthLayout,
		canActivate: [IsNotAuthenticatedGuard],
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: 'login',
				loadChildren: () =>
					import(`./login/login.module`).then((m) => m.LoginModule),
			},
		],
	},
	{
		path: '**',
		redirectTo: 'login',
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutesModule {}
