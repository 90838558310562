import { NgModule } from '@angular/core'
import { A11yModule } from '@components/a11y/a11y.module'
import { AccordionModule } from '@components/accordion/accordion.module'
import { AlertModule } from '@components/alert/alert.module'
import { ButtonModule } from '@components/button/button.module'
import { CardModule } from '@components/card/card.module'
import { ChartModule } from '@components/chart/chart.module'
import { ContentModule } from '@components/content/content.module'
import { CounterModule } from '@components/counter/counter.module'
import { DividerModule } from '@components/divider/divider.module'
import { DropdownModule } from '@components/dropdown/dropdown.module'
import { EmbedModule } from '@components/embed/embed.module'
// import {Form2Module} from './form2/form.module';
import { FormModule } from '@components/form/form.module'
import { GridModule } from '@components/grid/grid.module'
import { HeaderModule } from '@components/header/header.module'
import { IconModule } from '@components/icon/icon.module'
import { LayoutSwitcherModule } from '@components/layout-switcher/layout-switcher.module'
import { LogoModule } from '@components/logo/logo.module'
import { MenuModule } from '@components/menu/menu.module'
import { NavbarModule } from '@components/navbar/navbar.module'
import { NotificationsModule } from '@components/notifications/notifications.module'
import { OverlayModule } from '@components/overlay/overlay.module'
import { OverscrollModule } from '@components/overscroll/overscroll.module'
import { PortalModule } from '@components/portal/portal.module'
import { ProgressBarModule } from '@components/progress-bar/progress-bar.module'
import { ProgressSpinnerModule } from '@components/progress-spinner/progress-spinner.module'
import { RippleModule } from '@components/ripple/ripple.module'
import { SearchModule } from '@components/search/search.module'
import { SelectModule } from '@components/select/select.module'
import { SortModule } from '@components/sort/sort.module'
import { StatsModule } from '@components/stats/stats.module'
import { StatusIndicatorModule } from '@components/status-indicator/status-indicator.module'
import { StepperModule } from '@components/stepper/stepper.module'
import { TableModule } from '@components/table/table.module'
import { TabsModule } from '@components/tabs/tabs.module'
import { TitlebarModule } from '@components/titlebar/titlebar.module'
import { TooltipModule } from '@components/tooltip/tooltip.module'

const modules = [
	A11yModule,
	AccordionModule,
	AlertModule,
	ButtonModule,
	CardModule,
	ChartModule,
	ContentModule,
	CounterModule,
	DividerModule,
	DropdownModule,
	EmbedModule,
	FormModule,
	// Form2Module,
	GridModule,
	HeaderModule,
	IconModule,
	LayoutSwitcherModule,
	LogoModule,
	MenuModule,
	NavbarModule,
	NotificationsModule,
	OverlayModule,
	OverscrollModule,
	PortalModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	RippleModule,
	SearchModule,
	SelectModule,
	SortModule,
	StatsModule,
	StatusIndicatorModule,
	StepperModule,
	TableModule,
	TabsModule,
	TitlebarModule,
	TooltipModule,
]

@NgModule({
	exports: [...modules],
	imports: [...modules],
})
export class ComponentsModule {}
