import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-table-expand',
	template: `
		<button
			aria-label="Expand row"
			btn-color="brand"
			btn-link-icon
			class="table-expand"
			[class.table-expand--active]="isExpanded"
			[pRowToggler]="row"
		>
			<ms-icon class="table-expand__icon" name="caret" />
		</button>
	`,
	styleUrls: ['./table-expand.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpandComponent extends BaseComponent implements OnInit {
	@Input()
	public row: any

	// Computed Properties
	// ----------------------------------------

	public get isExpanded() {
		return this.pTable?.isRowExpanded(this.row) || false
	}

	public get pTable() {
		return this.tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(public tableService: TableService) {
		super()
	}

	public ngOnInit() {
		this.tableService.pTable?.onRowExpand
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}
}
