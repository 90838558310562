import { DrawerNavEvent } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-drawer-nav',
	host: {
		class: 'drawer-nav',
	},
	template: `
		<button
			btn-color="brand"
			btn-solid
			class="drawer-nav__button"
			[disabled]="disabled || !collection || isFirstItem()"
			(click)="prev()"
		>
			@switch (loading && lastAction === 'prev') {
				@case (true) {
					<ms-progress-spinner></ms-progress-spinner>
				}
				@default {
					<ms-icon class="drawer-nav__icon" name="arrow" />

					<span class="drawer-nav__label">
						{{ 'common.previous' | translate }}
					</span>
				}
			}
		</button>

		<button
			btn-color="brand"
			btn-solid
			class="drawer-nav__button"
			[disabled]="disabled || !collection || isLastItem()"
			(click)="next()"
		>
			@switch (loading && lastAction === 'next') {
				@case (true) {
					<ms-progress-spinner></ms-progress-spinner>
				}
				@default {
					<span class="drawer-nav__label">{{ 'common.next' | translate }}</span>

					<ms-icon class="drawer-nav__icon" name="arrow" [rotate]="180" />
				}
			}
		</button>
	`,
	styleUrls: ['./drawer-nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerNavComponent extends BaseComponent {
	@Input()
	public item$?: BehaviorSubject<any>

	@Input()
	public collection$?: BehaviorSubject<any[]>

	@Input()
	public disabled = false

	@Input()
	public loading = false

	@Output()
	public drawerNav = new EventEmitter<DrawerNavEvent<any>>()

	public lastAction: 'next' | 'prev' | 'none' = 'none'

	// Computed Properties
	// ----------------------------------------

	public get collection() {
		return this.collection$?.value || []
	}

	public set collection(value) {
		this.collection$?.next(value)
	}

	// Public Methods
	// ----------------------------------------

	public isFirstItem() {
		if (!this.collection$) return true

		const index = this._getItemIndex()
		return index <= 0
	}

	public isLastItem() {
		if (!this.collection$) return true

		const index = this._getItemIndex()
		return index >= this.collection.length - 1
	}

	public prev() {
		if (this.loading) return
		if (!this.collection$) return

		const index = this._getItemIndex()
		const prevIndex = index - 1

		if (prevIndex >= 0) {
			this.lastAction = 'prev'

			this.drawerNav.emit({
				type: 'prev',
				item: this.collection[prevIndex],
			})
		}
	}

	public next() {
		if (this.loading) return
		if (!this.collection) return

		const index = this._getItemIndex()
		const nextIndex = index + 1

		if (this.collection.length > nextIndex) {
			this.lastAction = 'next'

			this.drawerNav.emit({
				type: 'next',
				item: this.collection[nextIndex],
			})
		}
	}

	// Private Methods
	// ----------------------------------------

	private _getItemIndex() {
		if (!this.collection$ || !this.item$) {
			return -1
		} else {
			return this.collection.findIndex((i) => i === this.item$!.value)
		}
	}
}
