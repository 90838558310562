import { SearchService } from './search.service'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { NgForm } from '@angular/forms'

@Component({
	selector: 'ms-search',
	host: {
		class: 'search',
	},
	template: `
		<form #form="ngForm" autocomplete="off" class="search__form" novalidate>
			<input
				#input
				class="search__input"
				name="query"
				type="text"
				[placeholder]="$t('common.search')"
				[(ngModel)]="query"
			/>

			<div class="search__btn">
				<button btn-link-icon type="submit" (click)="onClick($event)">
					<ms-icon name="search" />
				</button>
			</div>
		</form>
	`,
	styleUrls: ['./search.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent extends BaseComponent implements AfterViewInit {
	private _active = false

	@ViewChild(NgForm)
	public form?: NgForm

	@ViewChild('input')
	public input?: ElementRef<HTMLInputElement>

	@Input()
	public expanded = false

	constructor(private _searchService: SearchService) {
		super()
	}

	// Computed Properties
	// ----------------------------------------

	public get query() {
		return this._searchService.query
	}

	public set query(value) {
		this._searchService.query = value
	}

	public get inputEl() {
		return this.input?.nativeElement
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterViewInit() {
		if (this.expanded) {
			this.renderer.addClass(this.el, 'search--expanded')
			this._active = true
		} else {
			if (this.query) {
				this._grow()
			}

			this.renderer.listen(this.el, 'focusout', (e: FocusEvent) => {
				const target = e.relatedTarget

				if (!this._active) return
				if (this.inputEl?.value) return
				if (target && this.el.contains(target as Node)) return

				this._shrink()
			})
		}
	}

	public onClick(e: Event) {
		e.preventDefault()

		if (!this._active) {
			this._focus()
			this._grow()
			return
		}

		if (this.inputEl?.value) {
			this._submit()
		} else {
			this._shrink()
		}
	}

	// Private Methods
	// ----------------------------------------

	private _grow() {
		if (this.expanded) return

		this.renderer.addClass(this.el, 'search--active')
		this._active = true
	}

	private _shrink() {
		if (this.expanded) return

		this.renderer.removeClass(this.el, 'search--active')
		this._active = false
	}

	private _focus() {
		this.inputEl?.focus({ preventScroll: true })
	}

	private _submit() {
		if (!this.form || this.form?.invalid) return

		this._searchService.search()
	}
}
