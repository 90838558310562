import { ButtonBaseComponent } from '../../shared/button.component'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { IconComponent } from '@components/icon/components/icon/icon.component'

@Component({
	selector: '[btn-link], [btn-solid], [btn-outline]',
	templateUrl: './button.component.html',
	styleUrls: ['../../shared/button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent
	extends ButtonBaseComponent
	implements OnInit, AfterViewInit
{
	@ContentChild(IconComponent)
	public icon?: IconComponent

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (this.el.hasAttribute('btn-link')) {
			this.renderer.addClass(this.el, `btn--link`)
		}

		if (this.el.hasAttribute('btn-solid')) {
			this.renderer.addClass(this.el, `btn--solid`)
		}

		if (this.el.hasAttribute('btn-outline')) {
			this.renderer.addClass(this.el, `btn--outline`)
		}

		if (this.color && this.color !== 'none') {
			this.renderer.addClass(this.el, `btn--theme-${this.color}`)
		}
	}

	public ngAfterViewInit() {
		if (this.icon) {
			this.renderer.addClass(this.el, `btn--icon`)
		}
	}
}
