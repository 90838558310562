import { Pipe, PipeTransform } from '@angular/core'
import { RecursiveKeyOf } from '@models/util/RecursiveKeyOf'
import { filter, get, isObject } from 'lodash-es'

@Pipe({
	name: 'filterBy',
	standalone: true,
})
export class FilterByPipe implements PipeTransform {
	public transform<T extends object>(
		collection: T[] | null,
		path: RecursiveKeyOf<T>,
		val: string | number | boolean | undefined,
	) {
		if (!collection || !val) return collection

		if (!Array.isArray(collection)) {
			throw new Error(`FilterByPipe: Collection is not iterable.`)
		}

		if (!collection.length) return collection

		if (!isObject(collection[0])) {
			throw new Error(`FilterByPipe: Collection does not contain an object.`)
		}

		const results = filter(collection, (item) => get(item, path) === val)

		return results
	}
}
