import { PortalOutletComponent } from './components/portal-outlet/portal-outlet.component'
import { PortalComponent } from './components/portal/portal.component'
import { PortalModule as CDKPortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [PortalComponent, PortalOutletComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, CDKPortalModule],
})
export class PortalModule {}
