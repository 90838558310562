<dialog #dialog class="dropdown">
	<div class="dropdown__content">
		@if (tempHeader) {
			<header class="dropdown__header">
				<ng-container [ngTemplateOutlet]="tempHeader" />
			</header>
		}

		<div class="dropdown__body">
			<ng-content />

			@if (tempBody) {
				<ng-container [ngTemplateOutlet]="tempBody" />
			}
		</div>

		@if (tempFooter) {
			<footer class="dropdown__footer">
				<ng-container [ngTemplateOutlet]="tempFooter" />
			</footer>
		}
	</div>
</dialog>
