import {
	FormStyle,
	TranslationWidth,
	getLocaleMonthNames,
} from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { isNumeric } from '@utils/is-numeric'

@Pipe({
	name: 'getMonthByIndex',
	standalone: true,
})
export class GetMonthByIndexPipe implements PipeTransform {
	private _months = getLocaleMonthNames(
		this._locale,
		FormStyle.Standalone,
		TranslationWidth.Wide,
	)

	constructor(@Inject(LOCALE_ID) private _locale: string) {}

	public transform(index: number | string | null): string | null {
		if (!index && index !== 0) return null

		if (typeof index === 'string') {
			index = parseInt(index)
		}

		if (!isNumeric(index)) {
			throw new Error(`GetMonthByIndexPipe: Index is not a number.`)
		}

		if (index < 0 || index > 11) {
			throw new Error(`GetMonthByIndexPipe: Index is out of range.`)
		}

		return this._months[index]
	}
}
