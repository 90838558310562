import {
	ColAlign,
	ColJustify,
	ColJustifyResponsive,
	ColSize,
	ColSizeResponsive,
	ColeAlignResponsive,
} from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { getClassInfix } from '@utils/css'

@Component({
	selector: 'ms-col',
	host: {
		class: 'col',
	},
	template: ` <ng-content /> `,
	styleUrls: ['./col.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColComponent extends BaseComponent implements OnInit {
	@Input()
	public alignX?: ColJustify | ColJustifyResponsive

	@Input()
	public alignY?: ColAlign | ColeAlignResponsive

	@Input()
	public size?: ColSize | ColSizeResponsive

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setAlignX()
		this._setAlignY()
		this._setSizes()
	}

	// Private Methods
	// ----------------------------------------

	private _setAlignX() {
		if (!this.alignX) return

		if (typeof this.alignX === 'string') {
			this.renderer.addClass(this.el, `justify-self-${this.alignX}`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.alignX)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `justify-self-${value}${infix}`)
		}
	}

	private _setAlignY() {
		if (!this.alignY) return

		if (typeof this.alignY === 'string') {
			this.renderer.addClass(this.el, `align-self-${this.alignY}`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.alignY)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `align-self-${value}${infix}`)
		}
	}

	private _setSizes() {
		if (!this.size) return

		if (['string', 'number'].includes(typeof this.size)) {
			this.renderer.addClass(this.el, `col--${this.size}`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.size)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `col--${value}${infix}`)
		}
	}
}
