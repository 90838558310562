import { BaseDirective } from '@abstract/base.directive'
import { Component } from '@angular/core'
import { TemplateFns } from '@utils/template-fns'

@Component({
	host: {
		class: 'component',
	},
	template: ``,
})
export abstract class BaseComponent<
	T extends HTMLElement = HTMLElement,
> extends BaseDirective<T> {
	public tempFns = TemplateFns
}
