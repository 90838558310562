import { RippleComponent } from './components/ripple.component'
import { RippleDirective } from './directives/ripple.directive'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [RippleComponent, RippleDirective]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class RippleModule {}
