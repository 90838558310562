import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-navbar-item, [navbar-item]',
	host: {
		class: 'navbar-item',
	},
	template: `<ng-content />`,
	styleUrls: ['./navbar-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarItemComponent {}
