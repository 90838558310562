import { TabContentComponent } from '../tab-content/tab-content.component'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-tab-nav',
	host: {
		class: 'tab-nav',
	},
	templateUrl: './tab-nav.component.html',
	styleUrls: ['./tab-nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavComponent extends BaseComponent implements AfterContentInit {
	@Input()
	public content?: TabContentComponent

	@Input()
	public id?: string

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		this.content?.tabs.changes
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((e: any) => {
				this.cdRef.detectChanges()
			})
	}
}
