import { PortalService } from '../../services/portal.service'
import { Portal } from '@angular/cdk/portal'
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-portal-outlet',
	template: `<ng-template [cdkPortalHost]="outlet$ | async"></ng-template>`,
	styleUrls: ['./portal-outlet.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalOutletComponent implements OnInit, OnDestroy {
	@Input()
	public name: string

	public outlet$: BehaviorSubject<Portal<any> | null>

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		private _cdRef: ChangeDetectorRef,
		public portalService: PortalService,
	) {}

	public ngOnInit() {
		if (!this.name) {
			throw new Error('PortalOutletComponent: name is required')
		}

		this.outlet$ = this.portalService.useOutlet(this.name)
		this._cdRef.markForCheck()
	}

	public ngOnDestroy() {
		this.outlet$.next(null)
	}
}
