import { Portal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { el } from 'date-fns/locale'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class PortalService {
	public outlets = new Map<string, BehaviorSubject<Portal<any> | null>>()

	// Public Methods
	// ----------------------------------------

	public useOutlet(name: string) {
		if (this.outlets.has(name)) {
			return this.outlets.get(name) as BehaviorSubject<Portal<any> | null>
		} else {
			this.outlets.set(name, new BehaviorSubject<Portal<any> | null>(null))
			return this.outlets.get(name) as BehaviorSubject<Portal<any> | null>
		}
	}
}
