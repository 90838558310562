import { TabPanelComponent } from '../tab-panel/tab-panel.component'
import { BaseComponent } from '@abstract/base.component'
import { Location } from '@angular/common'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	QueryList,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'

@Component({
	selector: 'ms-tab-content',
	host: {
		class: 'tab-content',
	},
	template: `<ng-content />`,
	styleUrls: ['./tab-content.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentComponent
	extends BaseComponent
	implements AfterContentInit
{
	@ContentChildren(TabPanelComponent, { descendants: false })
	public tabs: QueryList<TabPanelComponent>

	@Input()
	public id?: string

	@Input()
	public saveState = false

	// Computed Methods
	// ----------------------------------------

	public get activeTab() {
		return this.tabs.filter((t) => t.selected)[0]
	}

	public get inactiveTabs() {
		return this.tabs.filter((t) => !t.selected)
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(
		private _location: Location,
		private _route: ActivatedRoute,
		private _router: Router,
	) {
		super()
	}

	public async ngAfterContentInit() {
		this.tabs.changes
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._openFirstTab()
			})

		if (!this.saveState || !this.id) {
			this._openFirstTab()
			return
		}

		const params = await firstValueFrom(this._route.queryParams)
		const tabId: string | undefined = params[this.id]

		if (tabId) {
			this.selectTabById(tabId)
		} else {
			this._openFirstTab()
		}
	}

	// Public Methods
	// ----------------------------------------

	public selectTabById(id: string) {
		const tab = this.tabs.find((t) => t.id === id)

		if (tab) {
			this.selectTab(tab)
		}
	}

	public selectTab(tab: TabPanelComponent) {
		if (tab.selected) return

		this.tabs.forEach((t) => {
			t.id !== tab.id ? t.close() : t.open()
		})

		this._updateParams()
	}

	// Private Methods
	// ----------------------------------------

	private _openFirstTab() {
		const isTabSelected = this.tabs.some((t) => t.selected)

		if (!isTabSelected) {
			this.tabs.find((t) => !t.disabled)?.open()
		}

		this._updateParams()
	}

	private async _getParams() {
		return await firstValueFrom(this._route.queryParams)
	}

	private async _updateParams() {
		if (!this.saveState || !this.id) return

		const currentParams = await this._getParams()
		const tabId = this.activeTab.id

		if (currentParams[this.id] === tabId) return

		const updatedParams: Params = []
		updatedParams[this.id] = this.activeTab.id

		const url = this._router
			.createUrlTree([], {
				relativeTo: this._route,
				queryParams: updatedParams,
			})
			.toString()
		this._location.go(url)
	}
}
