import { BaseComponent } from '../../../../abstract/base.component'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnDestroy,
	ViewEncapsulation,
	computed,
	effect,
	input,
	viewChild,
} from '@angular/core'
import { EmbedRatio } from '@components/embed/types'
import { Chart, ChartData, ChartOptions, ChartType, Plugin } from 'chart.js'
import { cloneDeep, merge } from 'lodash-es'

@Component({
	selector: 'ms-chart',
	host: {
		class: 'chart',
	},
	template: `
		<ms-embed [loading]="!chart" [ratio]="ratio()">
			<div embed-item>
				@if (!hasData()) {
					<p class="chart__no-data">No Data</p>
				}

				<canvas #canvas [hidden]="!hasData()"></canvas>
			</div>
		</ms-embed>
	`,
	styles: `
		@import 'global/index';

		.chart {
			display: block;

			.embed,
			canvas {
				width: 100% !important;
				height: 100% !important;
			}

			&__no-data {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
			}
		}
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent
	extends BaseComponent
	implements AfterViewInit, OnDestroy
{
	canvas = viewChild<ElementRef<HTMLCanvasElement>>('canvas')

	chart: Chart | null = null

	data = input<ChartData>({
		labels: [],
		datasets: [],
	})

	options = input<ChartOptions>({})

	ratio = input<EmbedRatio>()

	type = input<ChartType>('line')

	// Computed Properties
	// ----------------------------------------

	hasData = computed(() => {
		return this.data()?.datasets.some(({ data }) => data.length)
	})

	// Lifecycle Methods
	// ----------------------------------------

	constructor() {
		super()

		effect(() => {
			const data = this.data()
			const options = this.options()

			if (!this.chart) return

			this.chart.data = data
			this.chart.options = merge(cloneDeep(CHART_DEFAULT_OPTIONS), options)
			this.chart.update()
		})
	}

	async ngAfterViewInit() {
		const [{ Chart, registerables }, ChartAnnotation, ChartDataLabels] =
			await Promise.all([
				import('chart.js'),
				import('chartjs-plugin-annotation'),
				import('chartjs-plugin-datalabels'),
				import('chartjs-adapter-date-fns'),
			])

		Chart.register(...registerables)
		Chart.register(ChartAnnotation.default)
		Chart.register(ChartDataLabels.default)
		Chart.register(CHART_BG_PLUGIN)

		const el = this.canvas()?.nativeElement

		if (el) {
			this.chart = new Chart(el, {
				type: this.type(),
				data: this.data(),
				options: merge(cloneDeep(CHART_DEFAULT_OPTIONS), this.options()),
			})
		}
	}

	ngOnDestroy() {
		this.chart?.destroy()
		this.chart = null
	}

	// Methods
	// ----------------------------------------

	saveAsImage() {
		if (!this.chart) return

		const link = document.createElement('a')
		link.download = 'chart.png'
		link.href = this.chart.toBase64Image()
		link.click()
	}

	public resize() {
		this.chart?.resize(this.el.offsetWidth, this.el.offsetHeight)
	}
}

const CHART_DEFAULT_OPTIONS: ChartOptions = {
	animation: {
		duration: 0,
	},
	datasets: {
		line: {
			pointRadius: 4,
			pointHoverRadius: 4,
		},
	},
	interaction: {
		axis: 'x',
		intersect: false,
		mode: 'nearest',
	},
	maintainAspectRatio: false,
	plugins: {
		backgroundColor: {
			color: '#ffffff',
		},
		datalabels: {
			align: 'top',
			clamp: true,
			font: {
				size: 10,
			},
		},
	} as any,
	responsive: true,
	scales: {
		x: {
			grid: {
				display: true,
			},
			ticks: {
				font: {
					size: 10,
				},
				maxRotation: 30,
				minRotation: 30,
				padding: 5,
			},
		},
		y: {
			grid: {
				display: true,
			},
			ticks: {
				font: {
					size: 10,
				},
				padding: 12,
			},
		},
	},
}

const CHART_BG_PLUGIN: Plugin = {
	id: 'backgroundColor',
	beforeDraw: (chart: Chart, args: any, options: any) => {
		const { ctx } = chart
		ctx.save()
		ctx.globalCompositeOperation = 'destination-over'
		ctx.fillStyle = options.color || 'transparent'
		ctx.fillRect(0, 0, chart.width, chart.height)
		ctx.restore()
	},
}
