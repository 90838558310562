import { LayoutSwitcherComponent } from './layout-switcher.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { A11yModule } from '@components/a11y/a11y.module'
import { IconModule } from '@components/icon/icon.module'

const components = [LayoutSwitcherComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, FormsModule, A11yModule, IconModule],
})
export class LayoutSwitcherModule {}
