import { TitlebarBackComponent } from './components/titlebar-back/titlebar-back.component'
import { TitlebarHeadingComponent } from './components/titlebar-heading/titlebar-heading.component'
import { TitlebarSubheadingComponent } from './components/titlebar-subheading/titlebar-subheading.component'
import { TitlebarComponent } from './components/titlebar/titlebar.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
	declarations: [
		TitlebarComponent,
		TitlebarHeadingComponent,
		TitlebarSubheadingComponent,
		TitlebarBackComponent,
	],
	exports: [
		TitlebarComponent,
		TitlebarHeadingComponent,
		TitlebarSubheadingComponent,
		TitlebarBackComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		ButtonModule,
		IconModule,
		TranslateModule,
	],
})
export class TitlebarModule {}
