import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	QueryList,
	Renderer2,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

@Component({
	selector: 'ms-accordion-table',
	host: {
		class: 'accordion-table',
	},
	template: `
		<table>
			<thead>
				@if (tempHead) {
					<ng-container [ngTemplateOutlet]="tempHead" />
				}
			</thead>
			<tbody>
				@if (tempRows) {
					<ng-container [ngTemplateOutlet]="tempRows" />
				}
			</tbody>
		</table>
	`,
	styleUrls: ['./accordion-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionTableComponent
	extends BaseComponent
	implements AfterContentInit
{
	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	public tempHead?: TemplateRef<any>

	public tempRows?: TemplateRef<any>

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'head':
					this.tempHead = item.template
					break

				case 'rows':
					this.tempRows = item.template
					break
			}
		})

		this.cdRef.detectChanges()
	}
}
