import { SortComponent } from './sort.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from '@components/button/button.module'
import { DropdownModule } from '@components/dropdown/dropdown.module'
import { IconModule } from '@components/icon/icon.module'
import { TranslateModule } from '@ngx-translate/core'

const components = [SortComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		ButtonModule,
		DropdownModule,
		IconModule,
		TranslateModule,
	],
})
export class SortModule {}
