<label class="form-group__wrapper">
	<ng-content select="ms-visually-hidden, [visually-hidden]"></ng-content>

	@if (label) {
		<div class="form-group__label">
			<ms-label [required]="required">{{ label }}</ms-label>
		</div>
	}

	<div class="form-group__control">
		<div class="control">
			<ng-content
				select="input[type='email'],
				input[type='number'],
				input[type='password'],
				input[type='tel'],
				input[type='text'],
				select,
				textarea"
			>
			</ng-content>

			@if (loading) {
				<div class="control__loader">
					<ms-progress-spinner />
				</div>
			}
			@if (overlayToggle) {
				<div class="control__btn">
					<button
						aria-label="Add new"
						btn-color="success"
						btn-outline-icon
						tabindex="1"
						type="button"
						[overlay-toggle]="overlayToggle"
						(onOverlayToggle)="onOverlayToggle.emit()"
					>
						<ms-icon name="plus" />
					</button>
				</div>
			}
		</div>

		@if (enableClearBtn && value) {
			<div class="form-group__btn">
				<button
					btn-color="brand"
					btn-link-icon
					type="button"
					[disabled]="disabled"
					(click)="clear()"
				>
					<ms-icon name="close" />
				</button>
			</div>
		}
	</div>

	@if ((messages$ | async)?.length) {
		<div class="form-group__messages">
			<ms-form-messages [messages$]="messages$" />
		</div>
	}
</label>
