import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DropdownComponent } from '@components/dropdown/components/dropdown/dropdown.component'
import {
	DATE_MATCH_MODES,
	NUMBER_MATCH_MODES,
	SELECT_MATCH_MODES,
	TEXT_MATCH_MODES,
} from '@constants/match-modes'
import { clone, cloneDeep } from 'lodash-es'
import { FilterMetadata, FilterOperator } from 'primeng/api'

@Component({
	selector: 'ms-table-filter',
	templateUrl: './table-filter.component.html',
	styleUrls: ['./table-filter.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFilterComponent extends BaseComponent implements OnInit {
	@Input({ required: true })
	field: MS.Table.Column['field']

	filters: FilterMetadata[] = []

	@ViewChild('menu')
	menu?: DropdownComponent

	tableService = inject(TableService)

	@Input({ required: true })
	type: MS.Table.Column['type']

	// Computed Properties
	// ----------------------------------------

	public get filterType() {
		switch (this.type) {
			case 'currency':
			case 'number':
			case 'percent':
				return 'number'

			case 'date':
				return 'date'

			case 'select':
				return 'select'

			default:
				return 'string'
		}
	}

	public get isActive() {
		let data = this.pTable?.filters?.[this.field] ?? []

		if (!Array.isArray(data)) {
			data = [data]
		}

		return data.filter((f) => f.value).length > 0
	}

	public get matchModes() {
		switch (this.filterType) {
			case 'date':
				return [...DATE_MATCH_MODES]

			case 'number':
				return [...NUMBER_MATCH_MODES]

			case 'select':
				return [...SELECT_MATCH_MODES]

			default:
				return [...TEXT_MATCH_MODES]
		}
	}

	public get pTable() {
		return this.tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (!this.field) {
			throw new Error('TableFilterComponent: field cannot be undefined.')
		}

		this._update()

		this.pTable?.onFilter
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this._update())
	}

	// Public Methods
	// ----------------------------------------

	public apply() {
		if (!this.pTable) return

		let filters = cloneDeep(this.filters)

		// Convert date string to date Obj
		filters = filters.map((f) => {
			if (f.value && f.matchMode?.startsWith('date')) {
				f.value = new Date(f.value)
			}
			return f
		})

		this.pTable.filters[this.field] = filters
		this.pTable._filter()
		this.menu?.close()
	}

	public reset() {
		this.filters = [
			{
				matchMode: this.matchModes[0].code,
				operator: FilterOperator.AND,
				value: null,
			},
		]

		this.apply()
	}

	public addRule(e: Event) {
		e.stopPropagation() // HACK: Prevents dropdown from closing

		this.filters.push({
			matchMode: this.matchModes[0].code,
			operator: FilterOperator.AND,
			value: null,
		})
	}

	public removeRule(e: Event, index: number) {
		e.stopPropagation() // HACK: Prevents dropdown from closing

		this.filters.splice(index, 1)
	}

	// Private Properties
	// ----------------------------------------

	private _update() {
		if (!this.pTable) {
			this.reset()
			return
		}

		const keys = Object.keys(this.pTable.filters)

		if (!keys.includes(this.field)) {
			this.reset()
			return
		}

		const values = this.pTable.filters[this.field]

		if (!values) {
			this.reset()
			return
		}

		this.filters = Array.isArray(values) ? cloneDeep(values) : [clone(values)]
	}
}
