<div class="accordion-panel__header">
	@if ({ active: active$ | async, disabled: disabled$ | async }; as state) {
		<button
			class="accordion-panel__toggle"
			role="tab"
			type="button"
			[attr.aria-controls]="panelId"
			[attr.aria-expanded]="state.active"
			[attr.tabindex]="state.disabled ? -1 : 0"
			[disabled]="state.disabled"
			[id]="labelId"
			(click)="toggle()"
		>
			<ms-icon class="accordion-panel__indicator" name="caret" />

			@if (tempTitle) {
				<div class="accordion-panel__title">
					<ng-container [ngTemplateOutlet]="tempTitle" />
				</div>
			}
		</button>
	}
	@if (tempActions) {
		<ms-btn-toolbar>
			<ng-container [ngTemplateOutlet]="tempActions" />
		</ms-btn-toolbar>
	}
</div>

<div role="tabpanel" [attr.aria-labelledby]="labelId" [id]="panelId">
	@if (active$ | async) {
		<div
			[@panelAnimation]="active ? 'open' : 'closed'"
			class="accordion-panel__body"
		>
			<div class="accordion-panel__content">
				<ng-content />
				@if (tempContent) {
					<ng-container [ngTemplateOutlet]="tempContent" />
				}
			</div>
		</div>
	}
</div>
