import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { FormatService } from '@services/format.service'
import { get } from 'lodash-es'

@Component({
	selector: 'ms-table-export',
	template: `
		<button
			btn-color="brand"
			btn-link-icon
			class="table-export"
			type="button"
			[dropdown]="menuExport"
			[tooltip]="$t('components.table.export')"
		>
			<ms-icon name="export" />
		</button>

		<ms-dropdown
			#menuExport
			class="table-export-dropdown"
			placement="bottom-end"
		>
			@if (formats.csv) {
				<button dropdown-menu-item type="button" (click)="exportCSV()">
					{{ 'components.table.export_csv' | translate }}
				</button>
			}
			@if (formats.pdf) {
				<button dropdown-menu-item type="button" (click)="exportPdf()">
					{{ 'components.table.export_pdf' | translate }}
				</button>
			}
		</ms-dropdown>
	`,
	styleUrls: ['./table-export.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExportComponent extends BaseComponent implements OnInit {
	@Input('columns')
	public columnsInput: MS.Table.Column[]

	@Input()
	public filename = 'export'

	@Input()
	public formats: MS.Table.ExportFormats = {}

	private _formats: MS.Table.ExportFormats = {
		csv: true,
		pdf: true,
	}

	// Computed Properties
	// ----------------------------------------

	public get columns() {
		return this.columnsInput ?? this.tableService.columns
	}

	public get pTable() {
		return this.tableService.pTable
	}

	public get rows() {
		const selectedRows = this.tableService.pTable?.selection
		const rows = this.tableService.rows

		return selectedRows?.length ? selectedRows : rows
	}

	// Lifecycle Methods
	// ----------------------------------------

	public constructor(
		private _formatService: FormatService,
		public tableService: TableService,
	) {
		super()
	}

	public ngOnInit() {
		if (!this.pTable) {
			throw new Error('TableExportComponent: No table provided')
		}

		this.formats = {
			...this._formats,
			...this.formats,
		}
	}

	// Public Methods
	// ----------------------------------------

	public exportCSV() {
		if (!this.pTable || !this.columns || !this.rows) return

		const columns = this.columns
		const headers: string[] = columns
			.map((col) => get(col, 'header', ''))
			.filter(Boolean)
		const csv = [headers.join(',')]
		let rows

		// Map row data
		if (this.pTable?.filteredValue?.length) {
			rows = [...this.pTable.filteredValue]
		} else {
			rows = [...this.rows]
		}

		rows.forEach((row) => {
			const values: string[] = []

			columns.forEach((col) => {
				const value = this._getFormattedValue(row, col)
				value ? values.push(`"${value}"`) : values.push('""')
			})

			csv.push(values.join(','))
		})

		// Download CSV file
		const a = document.createElement('a')
		a.setAttribute('id', 'download-link')
		a.setAttribute('download', `${this.filename}.csv`)
		a.setAttribute(
			'href',
			URL.createObjectURL(
				new Blob([csv.join('\r\n')], {
					type: 'text/csv;encoding:utf-8',
				}),
			),
		)
		document.body.appendChild(a)
		a.click()
		document.querySelector('#download-link')?.remove()
	}

	public async exportPdf() {
		if (!this.pTable || !this.columns || !this.rows) return

		const [pdf, autoTable] = await Promise.all([
			import('jspdf'),
			import('jspdf-autotable'),
		])

		const doc = new pdf.jsPDF({
			orientation: 'landscape',
		})

		let rows

		if (this.pTable?.filteredValue?.length) {
			rows = [...this.pTable.filteredValue]
		} else {
			rows = [...this.rows]
		}

		const body: any[][] = rows.map((row) => {
			const data: any[] = []

			this.columns.forEach((col) => {
				const value = this._getFormattedValue(row, col)
				value ? data.push(value) : data.push('')
			})

			return data
		})

		// Configure and save the pdf
		autoTable.default(doc, {
			columns: this.columns.map((col) => ({
				title: col.header,
				dataKey: col.field,
			})),
			body,
			headStyles: {
				overflow: 'visible',
				fillColor: '#0d7cba',
			},
		})

		doc.save(`${this.filename}.pdf`)
	}

	// Private Methods
	// ----------------------------------------

	private _getFormattedValue(row: any, { field, type }: MS.Table.Column) {
		const value = get(row, field)

		if (value === null || value === undefined) return null

		return this._formatService.format(value, type)
	}
}
