import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { DropdownToggleDirective } from './directives/dropdown-toggle.directive'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [
	DropdownItemComponent,
	DropdownComponent,
	DropdownToggleDirective,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class DropdownModule {}
