import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { HeaderModule } from '@components/header/header.module'
import { MenuModule } from '@components/menu/menu.module'
import { MainLayout } from '@layouts/main/main.layout'
import { UnauthLayout } from '@layouts/unauth/unauth.layout'

const layouts = [MainLayout, UnauthLayout]

@NgModule({
	declarations: [...layouts],
	exports: [...layouts],
	imports: [CommonModule, HeaderModule, MenuModule, RouterModule],
})
export class LayoutsModule {}
