import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
	input,
} from '@angular/core'

@Component({
	selector: 'ms-stats-bar',
	template: `<ng-content />`,
	styles: `
		@import 'global/index';

		ms-stats-bar {
			display: block;
			color: $color-white;
			background-color: $color-brand-dark;
			border-radius: 5px;
		}
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsBarComponent extends BaseComponent {
	public stats = input<Stat[]>([])

	public trackBy(index: number, stat: Stat) {
		return index
	}
}
