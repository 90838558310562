import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module'
import { BackdropComponent } from './components/backdrop/backdrop.component'
import { DrawerNavComponent } from './components/drawer-nav/drawer-nav.component'
import { DrawerComponent } from './components/drawer/drawer.component'
import { ModalComponent } from './components/modal/modal.component'
import { OverlayTitleComponent } from './components/overlay-title/overlay-title.component'
import { OverlayWrapperComponent } from './components/overlay-wrapper/overlay-wrapper.component'
import { OverlayComponent } from './components/overlay/overlay.component'
import { OverlayToggleDirective } from './directives/overlay-toggle.directive'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { DirectivesModule } from '@modules/directives.module'
import { TranslateModule } from '@ngx-translate/core'

const components = [
	BackdropComponent,
	DrawerNavComponent,
	DrawerComponent,
	ModalComponent,
	OverlayTitleComponent,
	OverlayWrapperComponent,
	OverlayComponent,
	OverlayToggleDirective,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		DirectivesModule,
		ButtonModule,
		IconModule,
		ProgressSpinnerModule,
		TranslateModule,
	],
})
export class OverlayModule {}
