import { MenuService } from '../../services/menu.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-menu-title',
	host: {
		class: 'menu-title',
	},
	template: `{{ title }}`,
	styleUrls: ['./menu-title.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTitleComponent extends BaseComponent implements OnInit {
	public title = ''

	constructor(private _menu: MenuService) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		// prettier-ignore
		this._menu.title$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((title) => {
				this.title = title;
				this.cdRef.detectChanges();
			});
	}
}
