import { BaseComponent } from '@abstract/base.component'
import { DOCUMENT } from '@angular/common'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import {
	Placement,
	Strategy,
	autoUpdate,
	computePosition,
	flip,
	offset,
	shift,
} from '@floating-ui/dom'
import { uniqueId } from 'lodash-es'

@Component({
	selector: 'ms-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent
	extends BaseComponent
	implements OnInit, AfterContentInit, OnDestroy
{
	@ViewChild('dialog', { static: true })
	private _dialogRef: ElementRef<HTMLDialogElement>

	private _removeAutoUpdate?: () => void

	private _removeListener?: () => void

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	@Input()
	closeOnClickOutside = true

	@Input()
	closeOnClickInside = false

	@Input()
	offset = [0, 6]

	@Input()
	placement: Placement = 'bottom'

	@Input()
	strategy: Strategy = 'fixed'

	@Output()
	dropdownWillOpen: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	dropdownDidOpen: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	dropdownWillClose: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	dropdownDidClose: EventEmitter<any> = new EventEmitter<any>()

	tempHeader?: TemplateRef<any>

	tempBody?: TemplateRef<any>

	tempFooter?: TemplateRef<any>

	// Computed Properties
	// ----------------------------------------

	get dialog() {
		return this._dialogRef.nativeElement
	}

	get isActive() {
		return this.dialog.open
	}

	get id() {
		return this.dialog.id
	}

	// Lifecycle Hooks
	// ----------------------------------------

	constructor(@Inject(DOCUMENT) public document: Document) {
		super()
	}

	ngOnInit() {
		this.renderer.setAttribute(this.dialog, 'id', uniqueId('dropdown-'))

		this.renderer.listen(this.dialog, 'cancel', (e) => {
			e.preventDefault()
			this.close()
		})

		this.renderer.listen(this.dialog, 'click', ({ target }) => {
			if (!target || !(target instanceof Element)) return
			if (!this.dialog.contains(target)) return
			if (!this.closeOnClickInside) return

			this.close()
		})
	}

	ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'header':
					this.tempHeader = item.template
					break

				case 'body':
					this.tempBody = item.template
					break

				case 'footer':
					this.tempFooter = item.template
					break
			}
		})
	}

	ngOnDestroy() {
		this._removeAutoUpdate?.()
		this._removeListener?.()
	}

	onClickOutside({ target }: PointerEvent) {
		if (!target || !(target instanceof Element)) return

		// When fixed, the target will be the dialog element if the user clicks the ::backdrop.
		// When absolute, the target will be the element the user clicked, so we need to check if the dialog contains the target.
		const didClickOutside =
			this.strategy === 'fixed'
				? this.dialog === target
				: !this.dialog.contains(target)

		if (didClickOutside) {
			this.close()
		}
	}

	// Methods
	// ----------------------------------------

	toggle(el: HTMLElement) {
		if (this.isActive) {
			this.close()
		} else {
			this.open(el)
		}
	}

	open(el: HTMLElement) {
		if (this.isActive) return

		this.dropdownWillOpen.emit()

		const [crossAxis, mainAxis] = this.offset

		this._removeAutoUpdate = autoUpdate(el, this.dialog, async () => {
			const { x, y } = await computePosition(el, this.dialog, {
				placement: this.placement,
				strategy: this.strategy,
				middleware: [
					flip(),
					offset({
						crossAxis,
						mainAxis,
					}),
					shift(),
				],
			})

			this.renderer.setStyle(this.dialog, 'position', this.strategy)
			this.renderer.setStyle(this.dialog, 'top', `${y}px`)
			this.renderer.setStyle(this.dialog, 'left', `${x}px`)
		})

		if (this.strategy === 'fixed') {
			this.dialog.showModal()
		} else {
			this.dialog.show()
		}

		if (this.closeOnClickOutside) {
			// Add a body click listener to close the dropdown when the user clicks away.
			this._removeListener = this.renderer.listen(
				this.document.body,
				'click',
				(e) => this.onClickOutside(e),
			)
		}

		this.dropdownDidOpen.emit()
	}

	close() {
		if (!this.isActive) return

		this.dropdownWillClose.emit()

		this.dialog.close()
		this._removeAutoUpdate?.()
		this._removeListener?.()

		this.dropdownDidClose.emit()
	}
}
