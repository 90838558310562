import { BaseDirective } from '../../abstract/base.directive'
import {
	Directive,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'

@Directive({
	selector: '[observe-resize]',
})
export class ObserveResizeDirective
	extends BaseDirective
	implements OnInit, OnDestroy
{
	@Output()
	public onResize = new EventEmitter()

	private _observer = new ResizeObserver(() => this.onResize.emit())

	public ngOnInit() {
		this._observer.observe(this.el)
	}

	public ngOnDestroy() {
		this._observer.unobserve(this.el)
		this._observer.disconnect()
	}
}
