<svg class="logo__svg" height="100" viewBox="0 0 280 100" width="280">
	<path class="logo__square" d="m0 0v99.75h99.75v-81.99l-17.77-17.76z" />

	<path
		class="logo__brand"
		d="m86.44 81.01c0 .67.06 1.35-.61 1.77v3.2h6.84v-3.2c-.67-.42-.61-1.1-.61-1.77v-8.11c0-.67-.06-1.35.61-1.77v-3.2h-6.84v3.2c.67.42.61 1.09.61 1.77zm-9.58-9.35h.57c1.22 0 1.6.63 1.6 1.79 0 1.26-.38 1.9-1.6 1.9h-.57v-3.68zm0 7.29h.23c1.54.04 1.68.76 1.68 2.19-.06 3.01.08 3.85.32 4.84h6.04v-3.2c-.69-.27-.69-1.16-.69-1.77v-1.03c0-1.83-.59-2.59-2.36-3.01v-.04c2.11-.53 2.7-2.25 2.7-4.23 0-1.64-.51-3.18-1.98-4.04-1.22-.74-2.97-.72-4.36-.72h-7.81v3.2c.67.42.61 1.09.61 1.77v8.11c0 .67.06 1.35-.61 1.77v3.2h6.84v-3.2c-.67-.42-.61-1.1-.61-1.77v-2.06zm-15.33 3.83c-.48-.13-.74-.48-.74-.99 0-.17.04-.36.08-.53h2.72c.04.17.08.34.08.53 0 .48-.21.82-.65.99v3.2h7.1v-3.2c-.63-.19-.84-1.2-.95-1.77l-2.63-13.08h-8l-2.38 12.8c-.17.69-.23 1.62-.84 2.04v3.2h6.21v-3.2zm-.3-5.03.88-5.35h.04l.93 5.35zm-12.91-6.04h.53c1.66 0 1.6.95 1.6 2.65s.06 2.63-1.6 2.63h-.53v-5.29zm-5.62 9.31c0 .67.06 1.35-.61 1.77v3.2h6.84v-3.2c-.67-.42-.61-1.1-.61-1.98h2c4.61 0 5.75-2.13 5.75-5.56v-1.43c0-.46.3-3.35-1.77-4.93-1.26-.97-3.33-.95-4.86-.95h-7.35v3.2c.67.42.61 1.09.61 1.77zm-11.31-1.56v.59c-.02 1.47 0 2.11-1.68 2.11h-.34v-3.64h3.56v-3.52h-3.56v-3.22h.34c1.52 0 1.68.5 1.68 1.73v.55h5.18v-6.11h-13.52v3.2c.67.42.61 1.09.61 1.77v8.11c0 .67.06 1.35-.61 1.77v3.2h13.52v-6.53h-5.18zm-24.41-3.37h3.98v-3.03c0-.82.19-1.26.99-1.28v9.24c0 .67.06 1.35-.61 1.77v3.2h6.84v-3.2c-.67-.42-.61-1.1-.61-1.77v-9.24c.8.02.99.46.99 1.28v3.03h3.98v-8.15h-15.55v8.15z"
	/>

	<g class="logo__title">
		<path
			d="m117.99 60.58 7.38 18.42 7.38-18.42h3.86v22.58h-2.98v-8.79l.28-9.49-7.41 18.28h-2.28l-7.4-18.23.29 9.44v8.79h-2.98v-22.58h3.85z"
		/>
		<path
			d="m151.77 83.15c-.17-.33-.3-.92-.4-1.77-1.33 1.39-2.93 2.08-4.78 2.08-1.65 0-3.01-.47-4.07-1.4s-1.59-2.12-1.59-3.56c0-1.75.66-3.1 1.99-4.07s3.2-1.45 5.61-1.45h2.79v-1.32c0-1-.3-1.8-.9-2.4-.6-.59-1.48-.89-2.65-.89-1.02 0-1.88.26-2.57.78s-1.04 1.14-1.04 1.88h-2.88c0-.84.3-1.65.89-2.43s1.4-1.4 2.42-1.85c1.02-.46 2.14-.68 3.36-.68 1.93 0 3.45.48 4.54 1.45 1.1.97 1.66 2.3 1.71 3.99v7.72c0 1.54.2 2.77.59 3.67v.25h-3.01zm-4.76-2.19c.9 0 1.75-.23 2.56-.7s1.39-1.07 1.75-1.81v-3.44h-2.25c-3.51 0-5.27 1.03-5.27 3.09 0 .9.3 1.6.9 2.11s1.37.76 2.31.76z"
		/>
		<path
			d="m165.42 81.12c1.02 0 1.92-.31 2.68-.93s1.19-1.4 1.27-2.33h2.71c-.05.96-.38 1.88-.99 2.74-.61.87-1.42 1.56-2.44 2.08s-2.1.78-3.23.78c-2.28 0-4.1-.76-5.45-2.29s-2.02-3.61-2.02-6.26v-.48c0-1.63.3-3.09.9-4.36s1.46-2.26 2.58-2.96 2.45-1.05 3.98-1.05c1.88 0 3.44.56 4.69 1.69s1.91 2.59 1.99 4.39h-2.71c-.08-1.09-.49-1.98-1.23-2.67-.74-.7-1.65-1.05-2.74-1.05-1.46 0-2.59.52-3.39 1.57s-1.2 2.57-1.2 4.55v.54c0 1.93.4 3.42 1.19 4.47.8 1.04 1.93 1.57 3.41 1.57z"
		/>
		<path
			d="m183.42 68.95c-.43-.07-.9-.11-1.41-.11-1.88 0-3.16.8-3.83 2.4v11.91h-2.87v-16.78h2.79l.05 1.94c.94-1.5 2.27-2.25 4-2.25.56 0 .98.07 1.27.22z"
		/>
		<path
			d="m185.01 74.61c0-1.64.32-3.12.97-4.43s1.55-2.33 2.7-3.04 2.47-1.07 3.95-1.07c2.28 0 4.13.79 5.54 2.37s2.12 3.69 2.12 6.31v.2c0 1.63-.31 3.1-.94 4.4s-1.52 2.31-2.68 3.03-2.5 1.09-4.01 1.09c-2.27 0-4.12-.79-5.53-2.37s-2.12-3.67-2.12-6.28v-.2zm2.88.34c0 1.86.43 3.35 1.29 4.48s2.02 1.69 3.47 1.69 2.62-.57 3.47-1.71c.86-1.14 1.29-2.74 1.29-4.8 0-1.84-.44-3.33-1.31-4.47s-2.03-1.71-3.48-1.71-2.56.56-3.43 1.69-1.3 2.74-1.3 4.84z"
		/>
		<path
			d="m213.65 78.7c0-.78-.29-1.38-.88-1.81-.58-.43-1.6-.8-3.05-1.11s-2.61-.68-3.46-1.12c-.85-.43-1.48-.95-1.89-1.55s-.61-1.31-.61-2.14c0-1.38.58-2.54 1.74-3.49s2.65-1.43 4.46-1.43c1.9 0 3.44.49 4.63 1.47 1.18.98 1.78 2.24 1.78 3.77h-2.88c0-.79-.33-1.46-1-2.03s-1.51-.85-2.52-.85-1.86.23-2.45.68-.88 1.05-.88 1.78c0 .69.27 1.21.82 1.57s1.54.69 2.97 1.01 2.59.7 3.48 1.15 1.55.98 1.98 1.61c.43.62.64 1.39.64 2.29 0 1.5-.6 2.7-1.8 3.6-1.2.91-2.75 1.36-4.67 1.36-1.34 0-2.53-.24-3.57-.71-1.03-.48-1.84-1.14-2.43-1.99-.58-.85-.88-1.78-.88-2.77h2.87c.05.96.44 1.72 1.16 2.29.72.56 1.67.84 2.84.84 1.09 0 1.96-.22 2.61-.66.66-.44.98-1.03.98-1.76z"
		/>
		<path
			d="m224.15 62.31v4.06h3.13v2.22h-3.13v10.42c0 .67.14 1.18.42 1.51.28.34.75.5 1.43.5.33 0 .79-.06 1.36-.19v2.31c-.75.21-1.49.31-2.2.31-1.28 0-2.25-.39-2.9-1.16s-.98-1.88-.98-3.3v-10.4h-3.05v-2.22h3.05v-4.06z"
		/>
		<path
			d="m229.58 74.61c0-1.64.32-3.12.97-4.43s1.55-2.33 2.7-3.04 2.47-1.07 3.95-1.07c2.28 0 4.13.79 5.54 2.37s2.12 3.69 2.12 6.31v.2c0 1.63-.31 3.1-.94 4.4s-1.52 2.31-2.68 3.03-2.5 1.09-4.01 1.09c-2.27 0-4.12-.79-5.53-2.37s-2.12-3.67-2.12-6.28v-.2zm2.88.34c0 1.86.43 3.35 1.29 4.48s2.02 1.69 3.47 1.69 2.62-.57 3.47-1.71c.86-1.14 1.29-2.74 1.29-4.8 0-1.84-.44-3.33-1.31-4.47s-2.03-1.71-3.48-1.71-2.56.56-3.43 1.69-1.3 2.74-1.3 4.84z"
		/>
		<path
			d="m255.18 81.12c1.02 0 1.92-.31 2.68-.93s1.19-1.4 1.27-2.33h2.71c-.05.96-.38 1.88-.99 2.74-.61.87-1.42 1.56-2.44 2.08s-2.1.78-3.23.78c-2.29 0-4.1-.76-5.45-2.29s-2.02-3.61-2.02-6.26v-.48c0-1.63.3-3.09.9-4.36s1.46-2.26 2.58-2.96 2.45-1.05 3.98-1.05c1.88 0 3.44.56 4.69 1.69s1.91 2.59 1.99 4.39h-2.71c-.08-1.09-.49-1.98-1.23-2.67-.74-.7-1.65-1.05-2.74-1.05-1.46 0-2.59.52-3.39 1.57s-1.2 2.57-1.2 4.55v.54c0 1.93.4 3.42 1.19 4.47.8 1.04 1.93 1.57 3.41 1.57z"
		/>
		<path
			d="m269.76 75.38-1.8 1.88v5.89h-2.87v-23.82h2.87v14.41l1.54-1.84 5.23-5.52h3.49l-6.53 7.01 7.29 9.77h-3.37l-5.85-7.77z"
		/>
		<path
			d="m118.63 97.57c0-.29-.1-.51-.31-.67s-.56-.33-1.08-.5-.93-.34-1.26-.5c-1.06-.52-1.59-1.24-1.59-2.15 0-.45.13-.85.4-1.2.26-.35.64-.62 1.12-.81s1.03-.29 1.63-.29 1.12.1 1.58.31c.47.21.83.51 1.09.9s.39.83.39 1.33h-1.97c0-.33-.1-.59-.31-.78-.2-.18-.48-.28-.83-.28s-.63.08-.84.23c-.2.16-.31.35-.31.59 0 .21.11.4.34.57s.62.35 1.19.53 1.03.38 1.4.59c.89.51 1.33 1.21 1.33 2.11 0 .72-.27 1.28-.81 1.69s-1.28.61-2.22.61c-.66 0-1.27-.12-1.8-.36-.54-.24-.94-.56-1.22-.98-.27-.41-.41-.89-.41-1.43h1.98c0 .44.11.76.34.97s.6.31 1.11.31c.33 0 .58-.07.77-.21s.28-.34.28-.59z"
		/>
		<path d="m130.53 93.08h-2.46v6.67h-1.98v-6.67h-2.41v-1.53h6.85z" />
		<path
			d="m140.27 91.56v5.36c0 .61-.13 1.13-.39 1.58-.26.44-.63.78-1.11 1.02-.48.23-1.06.35-1.72.35-1 0-1.78-.26-2.36-.78s-.87-1.23-.88-2.13v-5.4h1.99v5.44c.02.9.44 1.34 1.25 1.34.41 0 .72-.11.93-.34.21-.22.31-.59.31-1.1v-5.35h1.98z"
		/>
		<path
			d="m143.98 99.75v-8.2h2.64c.72 0 1.38.16 1.95.49.58.33 1.03.79 1.35 1.39s.49 1.27.49 2.01v.38c0 .75-.16 1.42-.48 2.02-.32.59-.76 1.06-1.34 1.4s-1.22.51-1.93.51h-2.7zm1.98-6.67v5.15h.69c.57 0 1-.2 1.31-.61.3-.4.46-1 .46-1.8v-.35c0-.79-.15-1.39-.46-1.79-.3-.4-.75-.6-1.33-.6h-.66z"
		/>
		<path d="m156.02 99.75h-1.97v-8.2h1.97z" />
		<path
			d="m166.81 95.82c0 .8-.15 1.51-.44 2.12s-.72 1.09-1.26 1.42-1.17.5-1.87.5-1.32-.16-1.86-.48-.96-.78-1.26-1.38-.46-1.28-.48-2.05v-.46c0-.8.15-1.51.44-2.12s.72-1.09 1.27-1.42 1.18-.5 1.88-.5 1.31.17 1.86.5c.54.33.97.8 1.27 1.41s.46 1.31.46 2.1v.38zm-2.01-.34c0-.81-.14-1.43-.41-1.85s-.66-.63-1.17-.63c-.99 0-1.51.74-1.57 2.23v.6c0 .8.13 1.42.39 1.85s.66.65 1.19.65.88-.21 1.15-.64c.27-.42.41-1.03.41-1.83v-.38z"
		/>
	</g>
</svg>
