import { NavbarModule } from '../navbar/navbar.module'
import { TabContentComponent } from './components/tab-content/tab-content.component'
import { TabNavComponent } from './components/tab-nav/tab-nav.component'
import { TabPanelComponent } from './components/tab-panel/tab-panel.component'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { OverscrollModule } from '@components/overscroll/overscroll.module'
import { SelectModule } from '@components/select/select.module'

const components = [TabContentComponent, TabNavComponent, TabPanelComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		NavbarModule,
		PortalModule,
		SelectModule,
		OverscrollModule,
	],
})
export class TabsModule {}
