import { BaseDirective } from '@abstract/base.directive'
import { Attribute, Directive } from '@angular/core'
import {
	NG_VALIDATORS,
	UntypedFormControl,
	ValidationErrors,
	Validator,
} from '@angular/forms'
import { startCase } from 'lodash-es'

@Directive({
	selector: '[compare]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CompareDirective,
			multi: true,
		},
	],
})
export class CompareDirective extends BaseDirective implements Validator {
	constructor(@Attribute('compare') public comparer?: string) {
		super()
	}

	public validate(control: UntypedFormControl): ValidationErrors | null {
		if (!control.root || !this.comparer) return null

		const compare = control.root.get(this.comparer)

		if (!compare) return null

		if (compare.value !== control.value) {
			compare.setErrors({ compare: true })

			return {
				compare: {
					message: this.$t('validators.compare', {
						name: startCase(this.comparer).toLowerCase(),
					}),
				},
			}
		}

		if (compare.value === control.value) {
			if (!compare.errors) return null

			delete compare.errors['compare']

			if (!Object.keys(compare.errors).length) {
				compare.setErrors(null)
			}
		}

		return null
	}
}
