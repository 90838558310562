import {
	HttpErrorResponse,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SessionService } from '@services/session.service'
import { log } from '@utils/log'
import { tap } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private _sessionService: SessionService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler) {
		const isCloudAPI = new RegExp(
			/^(https?:\/\/)?cloudapi(?:-dev)?\.tepari\.com(?:\/.*)?$/,
		).test(request.url)

		// Skip non-api requests
		if (!isCloudAPI) {
			return next.handle(request)
		}

		request = this._updateHeaders(request)

		return next.handle(request).pipe(
			tap({
				error: (error: any) => {
					if (!(error instanceof HttpErrorResponse)) return

					if (error.status === 401 || error.status === 403) {
						this._sessionService.logout(true)
					}
				},
			}),
		)
	}

	private _updateHeaders(request: HttpRequest<any>) {
		const token = this._sessionService.token

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		})
	}
}
