<!-- prettier-ignore -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<!-- Alert -->
		<symbol id="svg-alert" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<path d="m12.62 19v.8a2.39 2.39 0 0 0 4.77 0v-.8" fill="currentColor"/>
				<path d="m13.41 9.44a1.59 1.59 0 1 1 3.18 0 5.56 5.56 0 0 1 3.18 4.76v2.39a3.16 3.16 0 0 0 1.59 2.41h-12.72a3.16 3.16 0 0 0 1.59-2.38v-2.42a5.58 5.58 0 0 1 3.18-4.77" fill="none"/>
			</g>
		</symbol>

		<!-- Arrow -->
		<symbol id="svg-arrow" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<path d="m22.1 15h-14.2"/>
				<path d="m12 19.1-4.1-4.1"/>
				<path d="m12 10.9-4.1 4.1"/>
			</g>
		</symbol>

		<!-- Calendar -->
		<symbol id="svg-calendar" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<g fill="none">
					<path d="m15.5 15.5v4"/>
					<path d="m13.5 17.5h4"/>
					<rect height="13" rx="1" width="14" x="8.5" y="9.5"/>
					<path d="m12.5 7.5v2"/>
					<path d="m18.5 7.5v2"/>
				</g>
				<path d="m9.5 9.5h12a1 1 0 0 1 1 1v2h-14v-2a1 1 0 0 1 1-1z" fill="currentColor"/>
			</g>
		</symbol>

		<!-- Card -->
		<symbol id="svg-card" viewBox="0 0 30 30">
			<g fill="currentColor">
				<path d="m10 10h4v4h-4z"/>
				<path d="m10 16h4v4h-4z"/>
				<path d="m16 10h4v4h-4z"/>
				<path d="m16 16h4v4h-4z"/>
			</g>
		</symbol>

		<!-- Caret -->
		<symbol id="svg-caret" viewBox="0 0 30 30">
			<path d="m15.5 18.44-3.5-5.44h7z" fill="currentColor"/>
		</symbol>

		<!-- Chevron -->
		<symbol id="svg-chevron" viewBox="0 0 30 30">
			<path d="m11.8 7.5 6.5 7.5-6.5 7.5" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="3"/>
		</symbol>

		<!-- Classing -->
		<symbol id="svg-classing" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<rect height="4.5" rx="1" width="4.5" x="9" y="9"/>
				<rect height="4.5" rx="1" width="4.5" x="16.5" y="9"/>
				<rect height="4.5" rx="1" width="4.5" x="9" y="16.5"/>
				<rect height="4.5" rx="1" width="4.5" x="16.5" y="16.5"/>
			</g>
		</symbol>

		<!-- Close -->
		<symbol id="svg-close" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2">
				<path d="m18.54 11.46-7.08 7.08"/>
				<path d="m18.54 18.54-7.08-7.08"/>
			</g>
		</symbol>

		<!-- Criteria -->
		<symbol id="svg-criteria" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<g fill="none">
					<path d="m8.5 9.2h2"/>
					<path d="m8.5 21.6h2"/>
					<path d="m16.5 9.2h5"/>
					<path d="m16.5 21.6h5"/>
					<circle cx="12.5" cy="9.2" r="2"/>
					<circle cx="12.5" cy="21.6" r="2"/>
					<path d="m21.5 15.4h-2"/>
					<path d="m13.5 15.4h-5"/>
				</g>
				<circle cx="17.5" cy="15.4" fill="currentColor" r="2"/>
			</g>
		</symbol>

		<!-- Delete -->
		<symbol id="svg-delete" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<path d="m9.5 12.5v8a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-8" fill="none" stroke="currentColor"/>
				<path d="m8.5 10.5h13" fill="none" stroke="currentColor"/>
				<path d="m12.5 10.5v-3h5v3" fill="currentColor" stroke="none"/>
				<g fill="none" stroke="currentColor">
					<path d="m12.5 19.5 5-5"/>
					<path d="m17.5 19.5-5-5"/>
				</g>
			</g>
		</symbol>

		<!-- Download -->
		<symbol id="svg-download" viewBox="0 0 30 30">
			<path d="m10 14 4.5 5 4.5-5h-3v-6h-3v6z" fill="currentColor" stroke="none"/>
			<path d="m22 18v3.5c0 .83-.67 1.5-1.5 1.5h-12c-.83 0-1.5-.67-1.5-1.5v-3.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
		</symbol>

		<!-- Drag -->
		<symbol id="svg-drag" viewBox="0 0 30 30">
			<g fill="currentColor">
				<circle cx="9.83" cy="15.33" r="2"/>
				<circle cx="20.83" cy="15.33" r="2"/>
				<circle cx="15.33" cy="20.83" r="2"/>
				<circle cx="15.33" cy="9.83" r="2"/>
			</g>
		</symbol>

		<!-- Edit -->
		<symbol id="svg-edit" viewBox="0 0 30 30">
			<path d="m8.5 22.5h14" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="m18 7.5 3 3-8.5 8.5-4 1 1-4z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="m16.83 7.83h3v5h-3z" fill="currentColor" transform="matrix(.70710678 -.70710678 .70710678 .70710678 -1.94 15.99)"/>
		</symbol>

		<!-- Export -->
		<symbol id="svg-export" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<path d="m20.86 18.33v1.72a1.72 1.72 0 0 1 -1.72 1.72h-9.14a1.72 1.72 0 0 1 -1.72-1.72v-9.19a1.72 1.72 0 0 1 1.72-1.72h1.72" fill="none"/>
				<path d="m20.86 13.84v-4.7h-4.7z" fill="currentColor"/>
				<path d="m20.86 9.14-6.89 6.89" fill="none"/>
			</g>
		</symbol>

		<!-- Filter -->
		<symbol id="svg-filter" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor">
				<path d="m11 10v5.5"/>
				<path d="m11 18.5v1.5"/>
				<circle cx="11" cy="17" r="1.5"/>
				<path d="m15 10v1.5"/>
				<path d="m15 14.5v5.5"/>
				<circle cx="15" cy="13" r="1.5"/>
				<path d="m19 17.5v2.5"/>
				<path d="m19 10v4.5"/>
				<circle cx="19" cy="16" r="1.5"/>
			</g>
		</symbol>

		<!-- Graph -->
		<symbol id="svg-graph" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<path d="m8.23 17.24h2.72v4.54h-2.72z" fill="currentColor"/>
				<g fill="none">
					<path d="m13.18 13.61h2.72v8.17h-2.72z"/>
					<path d="m18.14 9.14h2.72v12.63h-2.72z"/>
				</g>
			</g>
		</symbol>

		<!-- Import -->
		<symbol id="svg-import" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m20.74 25.34h-11.48a2.3 2.3 0 0 1 -2.26-2.34v-16a2.3 2.3 0 0 1 2.3-2.3h8l5.7 5.71v12.59a2.3 2.3 0 0 1 -2.26 2.34z"/>
				<path d="m17.3 4.66v4.6a1.15 1.15 0 0 0 1.15 1.15h4.55"/>
				<path d="m15 13.85v6.89"/>
				<path d="m11.55 17.3h6.9"/>
			</g>
		</symbol>

		<!-- Info -->
		<symbol id="svg-info" viewBox="0 0 30 30">
			<circle cx="15" cy="15" fill="none" r="8.5" fill="none" stroke="currentcolor"  stroke-miterlimit="10"/>
			<g fill="currentcolor" stroke="none">
				<path d="m13.81 13.59h2.38v7h-2.38z"/>
				<circle cx="15" cy="10.91" r="1.5"/>
			</g>
		</symbol>

		<!-- Layout -->
		<symbol id="svg-layout" viewBox="0 0 30 30">
			<rect fill="none" height="13" rx=".5" stroke="currentColor" width="13" x="8.5" y="8.5"/>
			<rect fill="currentColor" height="14" rx="1" width="4" x="8" y="8"/>
		</symbol>

		<!-- List -->
		<symbol id="svg-list" viewBox="0 0 30 30">
			<g fill="currentColor">
				<path d="m10 10h10v2h-10z"/>
				<path d="m10 14h10v2h-10z"/>
				<path d="m10 18h10v2h-10z"/>
			</g>
		</symbol>



		<!-- Menu account -->
		<symbol id="svg-menu-account" viewBox="0 0 30 30">
			<circle cx="15" cy="9.397" r="3.897" stroke-linecap="round" style="stroke-width: 2px;" fill="none" stroke="currentColor" stroke-linejoin="round"/>
			<path d="M 7.692 24.5 L 7.692 22.064 C 7.692 19.374 9.874 17.192 12.564 17.192 L 17.436 17.192 C 20.126 17.192 22.308 19.374 22.308 22.064 L 22.308 24.5" style="stroke-width: 2px;" fill="none" stroke="currentColor" stroke-linejoin="round"/>
		</symbol>

		<!-- Menu alerts -->
		<symbol id="svg-menu-alerts" viewBox="0 0 30 30">
			<g fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m11.8 20.33v1.07a3.2 3.2 0 0 0 6.4 0v-1.07"/>
				<path d="m12.87 7.54a2.13 2.13 0 1 1 4.26 0 7.46 7.46 0 0 1 4.27 6.4v3.2a4.29 4.29 0 0 0 2.13 3.2h-17.06a4.27 4.27 0 0 0 2.13-3.2v-3.2a7.46 7.46 0 0 1 4.27-6.4"/>
			</g>
		</symbol>

		<!-- Menu animals -->
		<symbol id="svg-menu-animals" viewBox="0 0 30 30">
			<path d="m25.32 8.52c-2.91-2.75-3-2.95-6.89-2.93l1 1.86c-.62 1.22-1.68 3.1-3.13 3.1h-9.79c-2.94 0-2.87 3.81-.1 3.81v2.07l-2.09 1.77v6.21h2.41l1.38-4.13 2.89-2.43h4l2.77 6.56h2.68l-1.24-6.64c3.23 0 2.9-3.63 2.87-6.08a3 3 0 0 0 3.59-2.29 1 1 0 0 0 -.35-.88z" fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
		</symbol>

		<!-- Menu classing -->
		<symbol id="svg-menu-classing" viewBox="0 0 30 30">
			<g fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<rect height="7.5" rx="1.83" width="7.5" x="5" y="5"/>
				<rect height="7.5" rx="1.83" width="7.5" x="17.5" y="5"/>
				<rect height="7.5" rx="1.83" width="7.5" x="5" y="17.5"/>
				<rect height="7.5" rx="1.83" width="7.5" x="17.5" y="17.5"/>
			</g>
		</symbol>

		<!-- Menu collapse -->
		<symbol id="svg-menu-collapse" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m14 7.5-6.5 7.5 6.5 7.5"/>
				<path d="m21 7.5-6.5 7.5 6.5 7.5"/>
			</g>
		</symbol>

		<!-- Menu Events -->
		<symbol id="svg-events" viewBox="0 0 30 30">
			<path d="m5 12h20v11c0 1.1-.9 2-2 2h-16c-1.1 0-2-.9-2-2z" fill="#fff" stroke="none"/>
			<g fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2">
				<rect height="18" rx="2" width="20" x="5" y="7"/>
				<path d="m5 12h20"/>
				<path d="m10 5v3"/>
				<path d="m21 5v3"/>
			</g>
		</symbol>

		<!-- Menu help & support -->
		<symbol id="svg-menu-help-support" viewBox="0 0 30 30">
			<path d="m16.25 3.75v8.75h7.5l-10 13.75v-8.75h-7.5z" fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
		</symbol>

		<!-- Menu import -->
		<symbol id="svg-menu-import" viewBox="0 0 30 30">
			<g fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m20.74 25.34h-11.48a2.3 2.3 0 0 1 -2.26-2.34v-16a2.3 2.3 0 0 1 2.3-2.3h8l5.7 5.71v12.59a2.3 2.3 0 0 1 -2.26 2.34z"/>
				<path d="m17.3 4.66v4.6a1.15 1.15 0 0 0 1.15 1.15h4.55"/>
				<path d="m15 13.85v6.89"/>
				<path d="m11.55 17.3h6.9"/>
			</g>
		</symbol>

		<!-- Menu Logout -->
		<symbol id="svg-menu-logout" viewBox="0 0 30 30">
			<path d="M 17.55 15.001 L 6.5 15.001 M 6.5 15.001 L 9.475 12.451 M 6.5 15.001 L 9.475 17.551" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" style="fill: none; stroke-width: 2px;"/>
			<path d="M 12.451 10.751 C 12.462 8.902 12.544 7.901 13.197 7.246 C 13.943 6.501 15.146 6.501 17.55 6.501 L 18.4 6.501 C 20.804 6.501 22.007 6.501 22.753 7.246 C 23.5 7.994 23.5 9.196 23.5 11.601 L 23.5 18.4 C 23.5 20.804 23.5 22.006 22.753 22.753 C 22.1 23.406 21.098 23.488 19.249 23.498 M 12.451 19.25 C 12.462 21.099 12.544 22.1 13.197 22.753 C 13.742 23.298 14.53 23.445 15.85 23.485" stroke="currentColor" stroke-linecap="round" style="fill: none; stroke-width: 2px;"/>
		</symbol>

		<!-- Menu measurements -->
		<symbol id="svg-menu-measurements" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<g fill="none">
					<path d="m15 3.75v22.5"/>
					<path d="m11.25 26.25h7.5"/>
					<path d="m3.75 7.5 3.75 1.25 7.5-2.5 7.5 2.5 3.75-1.25"/>
				</g>
				<path d="m7.5 8.75-3.75 11.25a6.24 6.24 0 0 0 7.5 0z" fill="#ffffff"/>
				<path d="m22.5 8.75-3.75 11.25a6.24 6.24 0 0 0 7.5 0z" fill="#ffffff"/>
			</g>
		</symbol>

		<!-- Menu reporting -->
		<symbol id="svg-menu-reporting" viewBox="0 0 30 30">
			<g fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m20.74 25.34h-11.48a2.3 2.3 0 0 1 -2.26-2.34v-16a2.3 2.3 0 0 1 2.3-2.3h8l5.7 5.71v12.59a2.3 2.3 0 0 1 -2.26 2.34z"/>
				<path d="m17.3 4.66v4.6a1.15 1.15 0 0 0 1.15 1.15h4.55"/>
				<path d="m11.55 15h6.9"/>
				<path d="m11.55 19.59h6.9"/>
			</g>
		</symbol>

		<!-- Menu sales & purchases -->
		<symbol id="svg-menu-sales-purchases" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<circle fill="#ffffff" cx="7.5" cy="6.25" r="2.5"/>
				<circle fill="#ffffff" cx="22.5" cy="23.75" r="2.5"/>
				<g fill="none">
					<path d="m20 23.75h-6.25a6.25 6.25 0 0 1 -6.25-6.25v-3.75l3.75 3.75m-7.5 0 3.75-3.75"/>
					<path d="m10 6.25h6.25a6.25 6.25 0 0 1 6.25 6.25v3.75l3.75-3.75m-7.5 0 3.75 3.75"/>
				</g>
			</g>
		</symbol>

		<!-- Menu settings -->
		<symbol id="svg-menu-settings" viewBox="0 0 30 30">
			<circle cx="15" cy="15" fill="currentColor" r="2.78"/>
			<path d="m22.59 15a6.77 6.77 0 0 0 -.24-1.79l2.65-1.48-2.07-3.46-2.65 1.47a7.67 7.67 0 0 0 -3.21-1.74v-3h-4.14v3a7.71 7.71 0 0 0 -3.22 1.74l-2.64-1.47-2.07 3.46 2.64 1.48a7 7 0 0 0 0 3.58l-2.64 1.48 2.07 3.46 2.64-1.47a7.71 7.71 0 0 0 3.22 1.79v2.95h4.14v-2.95a7.71 7.71 0 0 0 3.22-1.79l2.64 1.47 2.07-3.46-2.65-1.48a6.72 6.72 0 0 0 .24-1.79z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
		</symbol>

		<!-- Menu treatments -->
		<symbol id="svg-menu-treatments" viewBox="0 0 30 30">
			<g fill="#ffffff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m20 5 3.75 3.75-15.43 15.44a2.65 2.65 0 0 1 -3.75-3.75z" fill="none"/>
				<path d="m23.75 18.75 1.88 2a2.5 2.5 0 1 1 -3.75 0z"/>
				<path d="m12.53 12.48-8 8a2.65 2.65 0 0 0 3.75 3.75l11.72-11.75z"/>
			</g>
		</symbol>

		<!-- Merge -->
		<symbol id="svg-merge" viewBox="0 0 30 30">
			<g fill="none">
				<path d="m8 8h10v10h-10z"/>
				<path d="m8.5 8.5h9v9h-9z" stroke="currentColor"/>
				<path d="m13 13h10v10h-10z"/>
				<path d="m13.5 13.5h9v9h-9z" stroke="currentColor"/>
			</g>
			<path d="m13 13h5v5h-5z" fill="currentColor"/>
		</symbol>

		<!-- Move -->
		<symbol id="svg-move" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
				<g fill="none">
					<path d="m8.57 15.39h12.5"/>
					<path d="m8.57 9.19h12.9"/>
					<path d="m8.57 21.58h12.9"/>
				</g>
				<path d="m18.14 18.71 3.33-3.33-3.33-3.32z" fill="currentColor"/>
			</g>
		</symbol>

		<!-- Organise -->
		<symbol id="svg-organise" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor">
				<path d="m10 11h10"/>
				<path d="m10 14h7"/>
				<path d="m10 17h4"/>
				<path d="m10 20h2"/>
			</g>
		</symbol>

		<!-- Plus circle -->
		<symbol id="svg-plus-cirlce" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor">
				<path d="m15 11v8"/>
				<path d="m19 15h-8"/>
				<circle cx="15" cy="15" r="8.5"/>
			</g>
		</symbol>

		<!-- Plus -->
		<symbol id="svg-plus" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-width="2">
				<path d="m15 10v10"/>
				<path d="m20 15h-10"/>
			</g>
		</symbol>

		<!-- Preview -->
		<symbol id="svg-preview" viewBox="0 0 30 30">
			<circle cx="15.02" cy="15.24" fill="currentColor" r="3.9"/>
			<path d="m6 15.24s3.87-6.24 9-6.24 9 6.24 9 6.24-3.86 6.25-9 6.25-9-6.25-9-6.25z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
		</symbol>

		<!-- Print -->
		<symbol id="svg-print" viewBox="0 0 30 30">
			<path d="m10.76 20h-2.76v-6.5a1.45 1.45 0 0 1 1.38-1.5h11a1.45 1.45 0 0 1 1.38 1.5v6.5h-2.76" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"/>
			<path d="m11 15.77h8v7h-8z" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"/>
			<path d="m10.5 7.5h9v3h-9z" fill="currentColor"/>
		</symbol>

		<!-- Run -->
		<symbol id="svg-run" viewBox="0 0 30 30">
			<g stroke="currentColor" stroke-miterlimit="10">
				<path d="m11 21.21a8.18 8.18 0 0 0 4.38 1.4 7.45 7.45 0 1 0 -6.65-10.82" fill="none"/>
				<g stroke-linecap="square">
					<path d="m8.13 7.4.6 4.39 4.47-.6" fill="none"/>
					<circle cx="9.41" cy="19.62" fill="currentColor" r="2.23"/>
				</g>
			</g>
		</symbol>

		<!-- Save -->
		<symbol id="svg-save" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1">
				<path d="m20.8 22.5h-11.6c-.9 0-1.7-.8-1.7-1.7v-11.6c0-.9.8-1.7 1.7-1.7h9.2l4.2 4.2v9.2c-.1.9-.8 1.6-1.8 1.6z"/>
				<path d="m19.2 22.5v-6.7h-8.3v6.7"/>
				<path d="m10.8 7.5v4.2h6.7"/>
			</g>
		</symbol>

		<!-- Search -->
		<symbol id="svg-search" viewBox="0 0 30 30">
			<g fill="none" stroke="currentColor" stroke-width="2">
				<circle cx="13.23" cy="12.83" r="5.97"/>
				<path d="m17.28 17.22 5.46 5.92"/>
			</g>
		</symbol>

		<!-- Settings -->
		<symbol id="svg-settings" viewBox="0 0 30 30">
			<path d="m13.83 7a.16.16 0 0 0 -.16.15l-.5 1.85a6.47 6.47 0 0 0 -1.12.47l-1.65-.96a.19.19 0 0 0 -.23 0l-1.66 1.66a.18.18 0 0 0 0 .22l.94 1.61a5.74 5.74 0 0 0 -.45 1.16l-1.84.51a.16.16 0 0 0 -.15.16v2.34a.16.16 0 0 0 .15.16l1.83.5a6.47 6.47 0 0 0 .46 1.17l-.94 1.6a.19.19 0 0 0 0 .23l1.66 1.65a.18.18 0 0 0 .23 0l1.65-.94a6.62 6.62 0 0 0 1.11.45l.51 1.84a.16.16 0 0 0 .16.16h2.34a.16.16 0 0 0 .16-.16l.51-1.83a6.62 6.62 0 0 0 1.16-.45l1.65.94a.18.18 0 0 0 .23 0l1.66-1.65a.17.17 0 0 0 0-.23l-.94-1.66a6.06 6.06 0 0 0 .4-1.12l1.83-.5a.16.16 0 0 0 .15-.16v-2.34a.16.16 0 0 0 -.16-.16l-1.82-.51a5.74 5.74 0 0 0 -.45-1.16l.94-1.65a.16.16 0 0 0 0-.22l-1.66-1.62a.2.2 0 0 0 -.23 0l-1.6.94a6.47 6.47 0 0 0 -1.17-.45l-.5-1.82a.16.16 0 0 0 -.16-.18zm1.17 4.76a3.24 3.24 0 1 1 -3.24 3.24 3.24 3.24 0 0 1 3.24-3.24z" fill="currentColor"/>
		</symbol>

		<!-- Sort -->
		<symbol id="svg-sort" viewBox="0 0 30 30">
			<g fill="currentColor">
				<path d="m8 9h15v3h-15z"/>
				<path d="m8 19h4v3h-4z"/>
				<path d="m8 14h9v3h-9z"/>
			</g>
		</symbol>

		<!-- Table Columns -->
		<symbol id="svg-tbl-columns" viewBox="0 0 30 30">
			<path fill="currentColor" stroke="none" d="M 20.402 7.5 L 9.597 7.5 C 8.439 7.504 7.504 8.439 7.5 9.597 L 7.5 20.402 C 7.504 21.56 8.439 22.495 9.597 22.5 L 20.402 22.5 C 21.559 22.493 22.493 21.559 22.5 20.402 L 22.5 9.597 C 22.493 8.44 21.559 7.506 20.402 7.5 Z M 12.24 21.661 L 9.597 21.661 C 8.902 21.661 8.339 21.097 8.339 20.402 L 8.339 17.794 L 12.24 17.794 L 12.24 21.661 Z M 12.24 16.955 L 8.339 16.955 L 8.339 13.045 L 12.24 13.045 L 12.24 16.955 Z M 12.24 12.205 L 8.339 12.205 L 8.339 9.597 C 8.339 8.902 8.902 8.339 9.597 8.339 L 12.24 8.339 L 12.24 12.205 Z M 21.661 20.402 C 21.655 21.096 21.096 21.655 20.402 21.661 L 13.078 21.661 L 13.078 17.794 L 21.661 17.794 L 21.661 20.402 Z M 21.661 16.955 L 13.078 16.955 L 13.078 13.045 L 21.661 13.045 L 21.661 16.955 Z M 21.661 12.205 L 13.078 12.205 L 13.078 8.339 L 20.402 8.339 C 21.096 8.345 21.655 8.903 21.661 9.597 L 21.661 12.205 Z" style=""/>
		</symbol>
	</defs>
</svg>
