import { TableService } from '../../services/table.service'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-table-footer',
	template: `
		<tr tableRow>
			<th tableCol [isControl]="true">
				<ms-table-stat-picker #statPicker></ms-table-stat-picker>
			</th>

			@if (enableExpand && enableSelect) {
				<td tableCol>&nbsp;</td>
			}
			@for (col of columns; track col) {
				<td tableCol>
					<ms-table-stat
						[calc]="statPicker.calc"
						[field]="col.field"
						[type]="col.type"
						[valueFn]="col.valueFn"
					></ms-table-stat>
				</td>
			}
			@if (!columns.length) {
				<td tableCol>&nbsp;</td>
			}
		</tr>
	`,
	styles: [
		`
			ms-table-footer {
				display: contents;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFooterComponent {
	@Input()
	public columns: any[]

	// Computed Properties
	// ----------------------------------------

	public get enableExpand() {
		return this._tableService.isRowExpandEnabled
	}

	public get enableSelect() {
		return this._tableService.isRowSelectEnabled
	}

	public get pTable() {
		return this._tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(private _tableService: TableService) {}
}
