import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-icon-definitions',
	host: {
		class: 'page-icons',
	},
	templateUrl: './icon-definitions.component.html',
	styleUrls: ['./icon-definitions.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDefinitionsComponent {}
