import { MatchMode } from '@models/vendor/prime-ng'

export const TEXT_MATCH_MODES: MatchMode[] = [
	{ name: 'Starts with', code: 'startsWith' },
	{ name: 'Contains', code: 'contains' },
	{ name: 'Not contains', code: 'notContains' },
	{ name: 'Ends with', code: 'endsWith' },
	{ name: 'Equals', code: 'equals' },
	{ name: 'Not equals', code: 'notEquals' },
	{ name: 'Is empty', code: 'isEmpty' },
	{ name: 'Is not empty', code: 'isNotEmpty' },
]

export const NUMBER_MATCH_MODES: MatchMode[] = [
	{ name: 'Equals', code: 'equals' },
	{ name: 'Not equals', code: 'notEquals' },
	{ name: 'Less than', code: 'lt' },
	{ name: 'Less than or equal to', code: 'lte' },
	{ name: 'Greater than', code: 'gt' },
	{ name: 'Greater than or equal to', code: 'gte' },
	{ name: 'Is empty', code: 'isEmpty' },
	{ name: 'Is not empty', code: 'isNotEmpty' },
]

export const DATE_MATCH_MODES: MatchMode[] = [
	{ name: 'Date is', code: 'dateIs' },
	{ name: 'Date is not', code: 'dateIsNot' },
	{ name: 'Date is before', code: 'dateBefore' },
	{ name: 'Date is after', code: 'dateAfter' },
]

export const SELECT_MATCH_MODES: MatchMode[] = [
	{ name: 'Equals', code: 'equals' },
]
