import {
	ChangeDetectorRef,
	DestroyRef,
	Directive,
	ElementRef,
	Renderer2,
	inject,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Directive()
export abstract class BaseDirective<T extends HTMLElement = HTMLElement> {
	public cdRef = inject(ChangeDetectorRef)

	public destroyRef = inject(DestroyRef)

	public elRef: ElementRef<T> = inject(ElementRef)

	public renderer = inject(Renderer2)

	public translate = inject(TranslateService)

	// Computed Properties
	// ----------------------------------------

	public get el() {
		return this.elRef.nativeElement
	}

	// Public Methods
	// ----------------------------------------

	/**
	 * Gets the translated value of a key.
	 *
	 * @param key translation key
	 * @param params translation params
	 * @returns translated string
	 */
	public $t(key: string, params?: Record<string, unknown>) {
		return this.translate.instant(key, params)
	}
}
