import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'

if (environment.enableProdMode) {
	enableProdMode()

	Sentry.init({
		dsn: 'https://5a11251f73fe313e72c5a84412f9a9e1@o4506181388468224.ingest.sentry.io/4506181391024128',
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [
					/^https:\/\/app\.macrostock\.com/,
					/^https:\/\/cloudapi\.tepari\.com/,
				],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	})
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err: any) => console.error(err))
