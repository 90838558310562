@for (
	notification of notifications$ | async;
	track notification;
	let i = $index
) {
	<div [@notificationAnimation] class="notifications__alert">
		<ms-alert
			[dismissable]="true"
			[icon]="notification.icon"
			[theme]="notification.type"
			[timer]="notification.timer"
			(alertDismissed)="dismissByIndex(i)"
		>
			@if (notification.title) {
				<p>
					<strong>{{ notification.title }}</strong>
				</p>
			}
			<p>{{ notification.message }}</p>
		</ms-alert>
	</div>
}
