@if (message) {
	<ms-alert class="mb-6" theme="brand">
		<p>{{ message }}</p>
	</ms-alert>
}

<form #form="ngForm" novalidate (ngSubmit)="submit()">
	<input name="username" type="hidden" [value]="formData.username" />

	<ms-control [label]="$t('common.password')">
		<input
			autocomplete="current-password"
			name="password"
			required
			type="password"
			[(ngModel)]="formData.password"
		/>
	</ms-control>

	<input class="d-none" type="submit" />
</form>
