import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-form-messages',
	host: {
		class: 'form-messages',
	},
	template: `
		@if (messages$ | async; as messages) {
			@for (message of messages; track message) {
				<p>{{ message }}</p>
			}
		}
	`,
	styleUrls: ['./form-messages.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMessagesComponent extends BaseComponent {
	@Input()
	public messages$ = new BehaviorSubject<string[]>([])
}
