import { BaseComponent } from '@abstract/base.component'
import { transition, trigger, useAnimation } from '@angular/animations'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { collapse, expand } from '@animations/collapse-expand.animation'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import { uniqueId } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

const panelAnimation = trigger('panelAnimation', [
	transition(
		':leave',
		useAnimation(collapse, { params: { timings: '200ms ease-in-out' } }),
	),
	transition(
		':enter',
		useAnimation(expand, { params: { timings: '200ms ease-in-out' } }),
	),
])

@Component({
	selector: 'ms-accordion-panel',
	host: {
		class: 'accordion-panel',
	},
	templateUrl: './accordion-panel.component.html',
	styleUrls: ['./accordion-panel.component.scss'],
	animations: [panelAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionPanelComponent
	extends BaseComponent
	implements OnInit, AfterContentInit
{
	@Input()
	public active$ = new BehaviorSubject(false)

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	public labelId = uniqueId('accordion-panel-label-')

	public panelId = uniqueId('accordion-panel-content-')

	@Input()
	public disabled$ = new BehaviorSubject(false)

	@Input()
	public showGuide = false

	@Output()
	public panelOpen: EventEmitter<undefined> = new EventEmitter<undefined>()

	@Output()
	public panelClose: EventEmitter<undefined> = new EventEmitter<undefined>()

	public tempActions?: TemplateRef<any>

	public tempContent?: TemplateRef<any>

	public tempTitle?: TemplateRef<any>

	// Computed Properties
	// ----------------------------------------

	public get active() {
		return this.active$.value
	}

	@Input()
	public set active(value) {
		this.active$.next(value)
	}

	public get disabled() {
		return this.disabled$.value
	}

	@Input()
	public set disabled(value) {
		this.disabled$.next(value)
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'actions':
					this.tempActions = item.template
					break

				case 'content':
					this.tempContent = item.template
					break

				case 'title':
					this.tempTitle = item.template
					break
			}
		})

		this.cdRef.detectChanges()
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		if (this.active) {
			this.close()
		} else {
			this.open()
		}
	}

	public open() {
		if (this.disabled) return

		this.panelOpen.emit()
		this.active = true
		this._setClasses()
		this.cdRef.detectChanges()
	}

	public close() {
		if (this.disabled) return

		this.panelClose.emit()
		this.active = false
		this._setClasses()
		this.cdRef.detectChanges()
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		this.active
			? this.renderer.addClass(this.el, 'accordion-panel--active')
			: this.renderer.removeClass(this.el, 'accordion-panel--active')
		this.disabled
			? this.renderer.addClass(this.el, 'accordion-panel--disabled')
			: this.renderer.removeClass(this.el, 'accordion-panel--disabled')
		this.showGuide
			? this.renderer.addClass(this.el, 'accordion-panel--guide')
			: this.renderer.removeClass(this.el, 'accordion-panel--guide')
	}
}
