import { FormGroupComponent } from '../form-group/form-group.component'
import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'ms-switch',
	host: {
		class: 'switch-group',
	},
	template: `
		@if (label) {
			<ms-label [required]="required">{{ label }}</ms-label>
		}

		<label class="switch">
			@if (label) {
				<span class="visually-hidden">{{ label }}</span>
			}
			@if (labelOff) {
				<span class="switch__label">{{ labelOff }}</span>
			}

			<div class="switch__inner">
				<ng-content select="input[type='checkbox']"></ng-content>
				<span class="switch__indicator"></span>
			</div>

			@if (labelOn) {
				<span class="switch__label">{{ labelOn }}</span>
			}
		</label>

		<ms-form-messages [messages$]="messages$"></ms-form-messages>
	`,
	styleUrls: [
		'../form-group/form-group.component.scss',
		'./switch.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class SwitchComponent extends FormGroupComponent {
	@Input()
	public labelOn?: string

	@Input()
	public labelOff?: string
}
