import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-table-summary',
	host: {
		class: 'table-summary',
	},
	template: `{{ summaryText }}`,
	styleUrls: ['./table-summary.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSummaryComponent extends BaseComponent implements OnInit {
	public tableService = inject(TableService)

	// Computed Properties
	// ----------------------------------------

	public get pTable() {
		return this.tableService.pTable
	}

	public get total() {
		return this.pTable?.totalRecords ?? 0
	}

	public get filtered() {
		return this.pTable?.filteredValue?.length ?? 0
	}

	public get selected() {
		return this.pTable?.selection?.length ?? 0
	}

	public get summaryText() {
		if (this.selected && this.filtered) {
			return this.$t('components.table.summary.selected_filtered', {
				filtered: this.filtered,
				selected: this.selected,
				total: this.total,
			})
		}

		if (this.selected && !this.filtered) {
			return this.$t('components.table.summary.selected', {
				selected: this.selected,
				total: this.total,
			})
		}

		if (!this.selected && this.filtered) {
			return this.$t('components.table.summary.filtered', {
				filtered: this.filtered,
				total: this.total,
			})
		}

		return this.$t('components.table.summary.default', {
			total: this.total,
		})
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this.pTable?.onFilter
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})

		this.pTable?.selectionChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})

		this.pTable?.tableService.valueSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}
}
