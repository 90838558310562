import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Paginated } from '@models/misc/Paginated'
import { Response } from '@models/misc/Response'
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private readonly baseUrl = environment.api

	constructor(private _httpClient: HttpClient) {}

	// Public Methods
	// ----------------------------------------

	public get<T>(url: string, params?: string[]) {
		const endpoint = this._buildURL(url, params)

		return this._httpClient.get<Response<T>>(endpoint).pipe(
			map((res) => {
				return res.data
			}),
		)
	}

	public getPaginated<T>(url: string, params: string[] = [], pageSize = 250) {
		let currentPage = 1

		let totalPages = 1

		const response: Paginated<T[]> = {
			data$: new BehaviorSubject<T[]>([]),
			loading$: new BehaviorSubject<boolean>(true),
			progress$: new BehaviorSubject<number>(0),
			unsubscribe$: new Subject<boolean>(),
		}

		const getData = (page: number) => {
			const endpoint = this._buildURL(url, [
				...params,
				`page=${page}`,
				`page_size=${pageSize}`,
			])

			this._httpClient
				.get<Response<T[]>>(endpoint)
				.pipe(takeUntil(response.unsubscribe$))
				.subscribe(({ data, pagination }) => {
					currentPage = pagination ? pagination.currentPage : 1
					totalPages = pagination ? pagination.totalPages : 1

					response.data$.next([...response.data$.value, ...data])
					response.progress$?.next((currentPage / totalPages) * 100)

					// log(
					// 	`${url} - page: ${currentPage}/${totalPages}, Progress: ${Math.round(
					// 		(currentPage / totalPages) * 100,
					// 	)}%`,
					// );

					if (currentPage !== totalPages) {
						getData(currentPage + 1)
					} else {
						response.loading$.next(false)
					}
				})
		}

		getData(currentPage)

		return response
	}

	public post<T>(url: string, body: any) {
		const endpoint = this._buildURL(url)

		return this._httpClient.post<Response<T>>(endpoint, body).pipe(
			map((res) => {
				return res.data
			}),
		)
	}

	public put<T>(url: string, body: any) {
		const endpoint = this._buildURL(url)

		return this._httpClient.put<Response<T>>(endpoint, body).pipe(
			map((res) => {
				return res.data
			}),
		)
	}

	public delete(url: string, body?: any) {
		const endpoint = this._buildURL(url)

		if (body) {
			return this._httpClient
				.request<Response<null>>('delete', endpoint, { body })
				.pipe(
					map((res) => {
						return res.status
					}),
				)
		} else {
			return this._httpClient.delete<Response<null>>(endpoint).pipe(
				map((res) => {
					return res.status
				}),
			)
		}
	}

	// Private Methods
	// ----------------------------------------

	private _buildURL(url: string, params?: string[]) {
		url = `${this.baseUrl}${url}`

		params?.forEach((param) => {
			url.includes('?') ? (url += '&') : (url += '?')
			url += param
		})

		return url
	}
}
