import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-backdrop',
	host: {
		class: 'backdrop',
	},
	template: ``,
	styleUrls: ['./backdrop.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackdropComponent extends BaseComponent {
	@Output()
	public backdropClick: EventEmitter<any> = new EventEmitter<any>()

	// Public Methods
	// ----------------------------------------

	@HostListener('click', ['$event'])
	public onClick() {
		this.backdropClick.emit()
	}
}
