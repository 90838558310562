@if (loading) {
	<ms-progress-bar id="page-loader" />
}

<router-outlet />

<ms-icon-definitions />

<ms-notifications />

<ms-verify-user-overlay />
