import { OverscrollComponent } from './overscroll.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DirectivesModule } from '@modules/directives.module'

const components = [OverscrollComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, DirectivesModule],
})
export class OverscrollModule {}
