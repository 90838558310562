import { OverlayWrapperComponent } from '../../../overlay/components/overlay-wrapper/overlay-wrapper.component'
import { FormGroupComponent } from '../form-group/form-group.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'

@Component({
	selector: 'ms-control',
	host: {
		class: 'control-group',
	},
	templateUrl: './control.component.html',
	styleUrls: [
		'../form-group/form-group.component.scss',
		'./control.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlComponent
	extends FormGroupComponent
	implements AfterContentInit, OnChanges
{
	@Input()
	public labelStyle: 'float' | 'inline' | 'top' = 'float'

	@Input()
	public loading = false

	@Input()
	public overlayToggle?: OverlayComponent | OverlayWrapperComponent

	@Output()
	public onOverlayToggle = new EventEmitter()

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.loading) {
			this.updateClasses()
		}
	}

	// Public Methods
	// ----------------------------------------

	public updateClasses() {
		super.updateClasses()

		switch (this.labelStyle) {
			case 'float':
				this.renderer.addClass(this.el, 'form-group--float-label')
				this.renderer.removeClass(this.el, 'form-group--inline-label')
				this.renderer.removeClass(this.el, 'form-group--top-label')
				break

			case 'inline':
				this.renderer.removeClass(this.el, 'form-group--float-label')
				this.renderer.addClass(this.el, 'form-group--inline-label')
				this.renderer.removeClass(this.el, 'form-group--top-label')
				break

			case 'top':
				this.renderer.removeClass(this.el, 'form-group--float-label')
				this.renderer.removeClass(this.el, 'form-group--inline-label')
				this.renderer.addClass(this.el, 'form-group--top-label')
				break
		}

		this.loading
			? this.renderer.addClass(this.el, 'form-group--loading')
			: this.renderer.removeClass(this.el, 'form-group--loading')
	}
}
