import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { TableService } from '@components/table/services/table.service'

@Component({
	selector: 'ms-table-body',
	template: `
		<tr
			tableRow
			[isClickable]="isClickable"
			[isHoverable]="isHoverable"
			[row]="row"
			[rowIndex]="rowIndex"
			(rowClick)="onRowClick($event)"
		>
			@if (enableExpand) {
				<td tableCol [isControl]="true">
					<ms-table-expand [row]="row"></ms-table-expand>
				</td>
			}
			@if (enableSelect) {
				<td tableCol [isControl]="true">
					<ms-table-select
						[disabled]="isSelectDisabled"
						[row]="row"
						[rowIndex]="rowIndex"
					></ms-table-select>
				</td>
			}
			@for (col of columns; track trackBy($index, col)) {
				<td tableCol>{{ row | colValue: col }}</td>
			}
			@if (!columns.length) {
				<td tableCol>&nbsp;</td>
			}
		</tr>
	`,
	styles: [
		`
			ms-table-body {
				display: contents;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBodyComponent {
	@Input()
	public columns: MS.Table.Column[]

	@Input()
	public isClickable = false

	@Input()
	public isHoverable = true

	@Input()
	public isSelectDisabled = false

	@Input()
	public row: any

	@Input()
	public rowIndex: number

	@Output()
	public rowClick = new EventEmitter<any>()

	// Computed Properties
	// ----------------------------------------

	public get enableExpand() {
		return this._tableService.isRowExpandEnabled
	}

	public get enableSelect() {
		return this._tableService.isRowSelectEnabled
	}

	public get pTable() {
		return this._tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(private _tableService: TableService) {}

	public onRowClick(event: any) {
		this.rowClick.emit(event)
	}

	// Public Methods
	// ----------------------------------------

	public trackBy(i: number, col: MS.Table.Column) {
		return col.field ?? i
	}
}
