import { CardComponent } from '../card/card.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-card-btn',
	host: {
		class: 'card-btn',
	},
	template: `
		<a
			class="card__inner"
			role="button"
			[attr.aria-label]="label"
			(click)="cardClick.emit()"
		>
			@if (tempHeader) {
				<header class="card__header">
					<ng-container [ngTemplateOutlet]="tempHeader" />
				</header>
			}

			<div class="card__content">
				<ng-content />
				@if (tempContent) {
					<ng-container [ngTemplateOutlet]="tempContent" />
				}
			</div>

			@if (tempFooter) {
				<footer class="card__footer">
					<ng-container [ngTemplateOutlet]="tempFooter" />
				</footer>
			}
		</a>

		@if (tempControls) {
			<ms-btn-toolbar class="card__controls" position="middle">
				<ng-container [ngTemplateOutlet]="tempControls" />
			</ms-btn-toolbar>
		}
	`,
	styleUrls: ['../card/card.component.scss', './card-btn.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardButtonComponent extends CardComponent {
	@Input()
	public label?: string

	@Output()
	public cardClick = new EventEmitter()
}
