import {
	ButtonComponent,
	ButtonGroupComponent,
	ButtonIconComponent,
	ButtonToolbarComponent,
} from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '@components/icon/icon.module'
import { ProgressSpinnerModule } from '@components/progress-spinner/progress-spinner.module'
import { RippleModule } from '@components/ripple/ripple.module'

const components = [
	ButtonComponent,
	ButtonGroupComponent,
	ButtonIconComponent,
	ButtonToolbarComponent,
]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, IconModule, ProgressSpinnerModule, RippleModule],
})
export class ButtonModule {}
