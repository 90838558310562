import { NavbarItemComponent } from './components/navbar-item/navbar-item.component'
import { NavbarLinkComponent } from './components/navbar-link/navbar-link.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [NavbarComponent, NavbarItemComponent, NavbarLinkComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class NavbarModule {}
