import { EmbedRatio } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
	input,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

@Component({
	selector: 'ms-embed',
	template: `
		<div
			class="embed"
			[style]="{
				'--embed-ratio': ratio()?.replace(':', '/')
			}"
		>
			@if (loading()) {
				<ms-progress-spinner class="embed__spinner"></ms-progress-spinner>
			}

			<ng-content select="[embed-item]"></ng-content>

			@if (tempOverlay) {
				<div class="embed__overlay">
					<ng-container [ngTemplateOutlet]="tempOverlay" />
				</div>
			}
		</div>
	`,
	styleUrls: ['./embed.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedComponent extends BaseComponent implements AfterContentInit {
	public ratio = input<EmbedRatio>()

	public loading = input<boolean>(false)

	public tempOverlay?: TemplateRef<any>

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'overlay':
					this.tempOverlay = item.template
					break
			}
		})
	}
}
