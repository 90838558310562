<svg
	class="text-logo__svg"
	height="40.577"
	viewBox="0 0 165 40.577"
	width="165"
>
	<path
		d="M 3.863 1.251 L 11.25 19.688 L 18.637 1.251 L 22.5 1.251 L 22.5 23.851 L 19.517 23.851 L 19.517 15.054 L 19.798 5.555 L 12.381 23.851 L 10.099 23.851 L 2.692 5.605 L 2.983 15.054 L 2.983 23.851 L 0 23.851 L 0 1.251 L 3.853 1.251 L 3.863 1.251 Z"
	/>
	<path
		d="M 37.673 23.841 C 37.503 23.511 37.373 22.921 37.274 22.07 C 35.942 23.462 34.341 24.152 32.489 24.152 C 30.838 24.152 29.476 23.681 28.416 22.751 C 27.355 21.82 26.824 20.629 26.824 19.188 C 26.824 17.436 27.484 16.084 28.816 15.113 C 30.147 14.143 32.019 13.662 34.431 13.662 L 37.223 13.662 L 37.223 12.341 C 37.223 11.34 36.923 10.54 36.323 9.939 C 35.722 9.348 34.842 9.048 33.671 9.048 C 32.649 9.048 31.788 9.309 31.098 9.829 C 30.407 10.349 30.057 10.97 30.057 11.711 L 27.174 11.711 C 27.174 10.87 27.474 10.059 28.065 9.279 C 28.656 8.497 29.466 7.877 30.487 7.427 C 31.508 6.967 32.629 6.746 33.851 6.746 C 35.782 6.746 37.304 7.226 38.394 8.197 C 39.495 9.168 40.056 10.499 40.106 12.191 L 40.106 19.918 C 40.106 21.46 40.306 22.69 40.696 23.592 L 40.696 23.841 L 37.683 23.841 L 37.673 23.841 Z M 32.909 21.65 C 33.81 21.65 34.661 21.419 35.472 20.949 C 36.283 20.479 36.863 19.878 37.223 19.138 L 37.223 15.695 L 34.972 15.695 C 31.458 15.695 29.697 16.726 29.697 18.787 C 29.697 19.688 29.997 20.388 30.597 20.899 C 31.198 21.409 31.969 21.66 32.909 21.66 L 32.909 21.65 Z"
	/>
	<path
		d="M 51.337 21.81 C 52.357 21.81 53.258 21.5 54.018 20.879 C 54.78 20.258 55.21 19.478 55.29 18.547 L 58.002 18.547 C 57.953 19.508 57.622 20.429 57.011 21.29 C 56.401 22.161 55.59 22.85 54.569 23.371 C 53.548 23.892 52.468 24.152 51.337 24.152 C 49.054 24.152 47.232 23.391 45.882 21.859 C 44.53 20.329 43.86 18.246 43.86 15.594 L 43.86 15.113 C 43.86 13.482 44.16 12.021 44.76 10.75 C 45.361 9.478 46.222 8.487 47.343 7.787 C 48.464 7.087 49.795 6.736 51.327 6.736 C 53.208 6.736 54.77 7.297 56.02 8.428 C 57.272 9.559 57.933 11.02 58.012 12.821 L 55.3 12.821 C 55.22 11.731 54.81 10.84 54.069 10.149 C 53.329 9.449 52.417 9.099 51.327 9.099 C 49.865 9.099 48.734 9.619 47.933 10.669 C 47.132 11.721 46.733 13.242 46.733 15.224 L 46.733 15.764 C 46.733 17.696 47.132 19.188 47.923 20.238 C 48.723 21.279 49.855 21.81 51.337 21.81 Z"
	/>
	<path
		d="M 69.352 9.629 C 68.922 9.559 68.452 9.518 67.941 9.518 C 66.059 9.518 64.778 10.319 64.108 11.921 L 64.108 23.841 L 61.236 23.841 L 61.236 7.046 L 64.028 7.046 L 64.077 8.988 C 65.019 7.487 66.35 6.736 68.081 6.736 C 68.642 6.736 69.062 6.807 69.352 6.957 L 69.352 9.629 Z"
	/>
	<path
		d="M 70.944 15.294 C 70.944 13.652 71.265 12.171 71.915 10.86 C 72.566 9.549 73.466 8.528 74.618 7.818 C 75.769 7.107 77.09 6.746 78.571 6.746 C 80.853 6.746 82.704 7.537 84.116 9.119 C 85.527 10.7 86.238 12.811 86.238 15.434 L 86.238 15.634 C 86.238 17.265 85.928 18.737 85.297 20.038 C 84.667 21.34 83.776 22.35 82.615 23.071 C 81.454 23.792 80.112 24.162 78.601 24.162 C 76.329 24.162 74.477 23.371 73.066 21.79 C 71.654 20.208 70.944 18.116 70.944 15.504 L 70.944 15.304 L 70.944 15.294 Z M 73.826 15.634 C 73.826 17.496 74.257 18.987 75.117 20.118 C 75.978 21.249 77.139 21.81 78.591 21.81 C 80.043 21.81 81.214 21.239 82.064 20.098 C 82.925 18.957 83.356 17.356 83.356 15.294 C 83.356 13.452 82.915 11.96 82.044 10.82 C 81.174 9.679 80.012 9.109 78.561 9.109 C 77.109 9.109 75.999 9.669 75.127 10.799 C 74.257 11.931 73.826 13.543 73.826 15.644 L 73.826 15.634 Z"
	/>
	<path
		d="M 99.61 19.387 C 99.61 18.607 99.32 18.007 98.729 17.576 C 98.149 17.146 97.128 16.775 95.677 16.465 C 94.226 16.155 93.064 15.784 92.214 15.344 C 91.363 14.914 90.732 14.393 90.321 13.792 C 89.911 13.192 89.711 12.481 89.711 11.65 C 89.711 10.27 90.292 9.109 91.452 8.157 C 92.613 7.206 94.105 6.726 95.916 6.726 C 97.819 6.726 99.36 7.216 100.551 8.197 C 101.732 9.178 102.333 10.44 102.333 11.971 L 99.45 11.971 C 99.45 11.18 99.12 10.509 98.449 9.939 C 97.778 9.368 96.937 9.088 95.926 9.088 C 94.915 9.088 94.065 9.318 93.474 9.769 C 92.884 10.219 92.594 10.82 92.594 11.551 C 92.594 12.241 92.864 12.762 93.415 13.122 C 93.965 13.482 94.956 13.812 96.388 14.133 C 97.819 14.453 98.98 14.834 99.87 15.284 C 100.761 15.735 101.422 16.264 101.852 16.896 C 102.283 17.516 102.493 18.287 102.493 19.188 C 102.493 20.689 101.892 21.89 100.691 22.791 C 99.49 23.701 97.938 24.152 96.017 24.152 C 94.676 24.152 93.484 23.912 92.443 23.442 C 91.413 22.961 90.602 22.301 90.011 21.45 C 89.431 20.599 89.131 19.668 89.131 18.677 L 92.003 18.677 C 92.053 19.638 92.443 20.398 93.164 20.969 C 93.885 21.529 94.836 21.81 96.007 21.81 C 97.098 21.81 97.969 21.59 98.619 21.15 C 99.28 20.708 99.6 20.118 99.6 19.387 L 99.61 19.387 Z"
	/>
	<path
		d="M 110.119 2.983 L 110.119 7.046 L 113.252 7.046 L 113.252 9.269 L 110.119 9.269 L 110.119 19.698 C 110.119 20.368 110.259 20.879 110.54 21.209 C 110.82 21.549 111.29 21.709 111.971 21.709 C 112.302 21.709 112.762 21.65 113.333 21.519 L 113.333 23.831 C 112.581 24.042 111.841 24.142 111.13 24.142 C 109.849 24.142 108.879 23.752 108.228 22.981 C 107.578 22.21 107.247 21.099 107.247 19.678 L 107.247 9.269 L 104.194 9.269 L 104.194 7.046 L 107.247 7.046 L 107.247 2.983 L 110.119 2.983 Z"
	/>
	<path
		d="M 115.554 15.294 C 115.554 13.652 115.874 12.171 116.526 10.86 C 117.177 9.549 118.077 8.528 119.228 7.818 C 120.379 7.107 121.7 6.746 123.181 6.746 C 125.463 6.746 127.315 7.537 128.727 9.119 C 130.138 10.7 130.849 12.811 130.849 15.434 L 130.849 15.634 C 130.849 17.265 130.539 18.737 129.907 20.038 C 129.277 21.34 128.387 22.35 127.226 23.071 C 126.064 23.792 124.723 24.162 123.212 24.162 C 120.94 24.162 119.088 23.371 117.677 21.79 C 116.265 20.208 115.554 18.116 115.554 15.504 L 115.554 15.304 L 115.554 15.294 Z M 118.437 15.634 C 118.437 17.496 118.867 18.987 119.728 20.118 C 120.589 21.249 121.75 21.81 123.202 21.81 C 124.653 21.81 125.824 21.239 126.675 20.098 C 127.536 18.957 127.966 17.356 127.966 15.294 C 127.966 13.452 127.526 11.96 126.655 10.82 C 125.784 9.679 124.622 9.109 123.171 9.109 C 121.72 9.109 120.609 9.669 119.738 10.799 C 118.867 11.931 118.437 13.543 118.437 15.644 L 118.437 15.634 Z"
	/>
	<path
		d="M 141.178 21.81 C 142.199 21.81 143.099 21.5 143.86 20.879 C 144.621 20.258 145.052 19.478 145.131 18.547 L 147.844 18.547 C 147.794 19.508 147.464 20.429 146.853 21.29 C 146.242 22.161 145.431 22.85 144.411 23.371 C 143.39 23.892 142.309 24.152 141.178 24.152 C 138.886 24.152 137.074 23.391 135.723 21.859 C 134.371 20.329 133.701 18.246 133.701 15.594 L 133.701 15.113 C 133.701 13.482 134.002 12.021 134.602 10.75 C 135.202 9.478 136.063 8.487 137.184 7.787 C 138.306 7.087 139.636 6.736 141.168 6.736 C 143.05 6.736 144.611 7.297 145.862 8.428 C 147.113 9.559 147.774 11.02 147.854 12.821 L 145.141 12.821 C 145.062 11.731 144.651 10.84 143.911 10.149 C 143.17 9.449 142.259 9.099 141.168 9.099 C 139.707 9.099 138.576 9.619 137.775 10.669 C 136.974 11.721 136.574 13.242 136.574 15.224 L 136.574 15.764 C 136.574 17.696 136.974 19.188 137.765 20.238 C 138.566 21.279 139.696 21.81 141.178 21.81 Z"
	/>
	<path
		d="M 155.771 16.065 L 153.97 17.946 L 153.97 23.841 L 151.097 23.841 L 151.097 0 L 153.97 0 L 153.97 14.424 L 155.511 12.582 L 160.746 7.056 L 164.239 7.056 L 157.703 14.073 L 165 23.851 L 161.626 23.851 L 155.771 16.074 L 155.771 16.065 Z"
	/>
	<path
		d="M 4.503 38.275 C 4.503 37.985 4.404 37.764 4.193 37.604 C 3.984 37.444 3.633 37.274 3.113 37.104 C 2.592 36.934 2.182 36.763 1.852 36.603 C 0.79 36.083 0.26 35.363 0.26 34.451 C 0.26 34.001 0.39 33.6 0.66 33.25 C 0.92 32.9 1.301 32.63 1.781 32.439 C 2.262 32.249 2.812 32.149 3.413 32.149 C 4.013 32.149 4.533 32.249 4.994 32.459 C 5.465 32.67 5.824 32.97 6.085 33.361 C 6.345 33.75 6.475 34.191 6.475 34.692 L 4.503 34.692 C 4.503 34.362 4.404 34.101 4.193 33.91 C 3.994 33.73 3.713 33.631 3.362 33.631 C 3.012 33.631 2.732 33.711 2.521 33.861 C 2.322 34.021 2.211 34.212 2.211 34.451 C 2.211 34.662 2.322 34.852 2.552 35.022 C 2.782 35.192 3.173 35.373 3.743 35.552 C 4.314 35.732 4.774 35.933 5.145 36.143 C 6.035 36.654 6.475 37.354 6.475 38.255 C 6.475 38.976 6.205 39.536 5.664 39.947 C 5.125 40.356 4.383 40.557 3.443 40.557 C 2.782 40.557 2.172 40.437 1.641 40.196 C 1.101 39.957 0.7 39.636 0.42 39.215 C 0.15 38.806 0.01 38.325 0.01 37.784 L 1.992 37.784 C 1.992 38.225 2.102 38.545 2.332 38.755 C 2.562 38.966 2.932 39.065 3.443 39.065 C 3.773 39.065 4.023 38.996 4.213 38.855 C 4.404 38.715 4.494 38.515 4.494 38.265 L 4.503 38.275 Z"
	/>
	<path
		d="M 16.414 33.781 L 13.952 33.781 L 13.952 40.457 L 11.97 40.457 L 11.97 33.781 L 9.558 33.781 L 9.558 32.249 L 16.414 32.249 L 16.414 33.781 Z"
	/>
	<path
		d="M 26.163 32.259 L 26.163 37.624 C 26.163 38.235 26.033 38.755 25.773 39.205 C 25.513 39.646 25.142 39.987 24.662 40.226 C 24.181 40.457 23.601 40.577 22.941 40.577 C 21.94 40.577 21.159 40.317 20.578 39.796 C 19.998 39.276 19.707 38.565 19.697 37.665 L 19.697 32.259 L 21.689 32.259 L 21.689 37.704 C 21.709 38.605 22.13 39.045 22.941 39.045 C 23.351 39.045 23.661 38.935 23.871 38.705 C 24.082 38.485 24.181 38.115 24.181 37.604 L 24.181 32.249 L 26.163 32.249 L 26.163 32.259 Z"
	/>
	<path
		d="M 29.877 40.457 L 29.877 32.249 L 32.519 32.249 C 33.24 32.249 33.9 32.409 34.471 32.739 C 35.051 33.07 35.502 33.531 35.823 34.131 C 36.143 34.731 36.313 35.402 36.313 36.143 L 36.313 36.524 C 36.313 37.274 36.153 37.945 35.833 38.545 C 35.512 39.136 35.071 39.606 34.491 39.947 C 33.91 40.287 33.27 40.457 32.559 40.457 L 29.857 40.457 L 29.877 40.457 Z M 31.859 33.781 L 31.859 38.935 L 32.549 38.935 C 33.12 38.935 33.55 38.735 33.861 38.325 C 34.161 37.924 34.321 37.324 34.321 36.524 L 34.321 36.173 C 34.321 35.382 34.171 34.782 33.861 34.382 C 33.56 33.981 33.11 33.781 32.53 33.781 L 31.869 33.781 L 31.859 33.781 Z"
	/>
	<path
		d="M 41.928 40.457 L 39.956 40.457 L 39.956 32.249 L 41.928 32.249 L 41.928 40.457 Z"
	/>
	<path
		d="M 52.727 36.524 C 52.727 37.324 52.577 38.034 52.287 38.645 C 51.997 39.256 51.566 39.736 51.025 40.066 C 50.486 40.397 49.855 40.567 49.154 40.567 C 48.454 40.567 47.833 40.407 47.292 40.087 C 46.752 39.766 46.332 39.306 46.032 38.705 C 45.732 38.105 45.571 37.424 45.551 36.654 L 45.551 36.193 C 45.551 35.392 45.701 34.682 45.991 34.071 C 46.281 33.46 46.712 32.98 47.262 32.65 C 47.813 32.319 48.443 32.149 49.144 32.149 C 49.845 32.149 50.456 32.319 51.006 32.65 C 51.546 32.98 51.977 33.45 52.277 34.061 C 52.577 34.672 52.737 35.373 52.737 36.163 L 52.737 36.543 L 52.727 36.524 Z M 50.715 36.183 C 50.715 35.373 50.575 34.751 50.305 34.331 C 50.035 33.91 49.645 33.701 49.134 33.701 C 48.143 33.701 47.623 34.441 47.562 35.933 L 47.562 36.533 C 47.562 37.334 47.693 37.955 47.953 38.385 C 48.214 38.816 48.614 39.035 49.144 39.035 C 49.675 39.035 50.026 38.826 50.295 38.395 C 50.565 37.975 50.705 37.364 50.705 36.563 L 50.705 36.183 L 50.715 36.183 Z"
	/>
</svg>
