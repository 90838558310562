import { ChartComponent } from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EmbedModule } from '@components/embed/embed.module'

const components = [ChartComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule, EmbedModule],
})
export class ChartModule {}
