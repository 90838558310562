import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'keys',
	standalone: true,
})
export class KeysPipe implements PipeTransform {
	public transform(object?: Record<string, any>) {
		return Object.keys(object ?? {})
	}
}
