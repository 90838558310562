import { Pipe, PipeTransform, inject } from '@angular/core'
import { FormatService } from '@services/format.service'
import { get } from 'lodash-es'

@Pipe({
	name: 'colValue',
	pure: false,
})
export class colValuePipe implements PipeTransform {
	formatService = inject(FormatService)

	public transform<T extends object>(
		row?: string | number | T,
		column?: MS.Table.Column,
		format = true,
	) {
		const { field, type } = column || {}

		if (!field) return null

		const value = column?.valueFn ? column.valueFn(row) : get(row, field)

		return format ? this.formatService.format(value, type) : value
	}
}
