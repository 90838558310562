import {
	RowAlign,
	RowAlignResponsive,
	RowGap,
	RowGapResponsive,
	RowJustify,
	RowJustifyResponsive,
	RowWrap,
	RowWrapResponsive,
} from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { BREAKPOINTS } from '@constants/breakpoints'
import { Breakpoints } from '@models/util/Breakpoints'
import { getClassInfix } from '@utils/css'

@Component({
	selector: 'ms-row',
	host: {
		class: 'row',
	},
	template: ` <ng-content /> `,
	styleUrls: ['./row.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowComponent extends BaseComponent implements OnInit {
	@Input()
	public alignX?: RowJustify | RowJustifyResponsive

	@Input()
	public alignY?: RowAlign | RowAlignResponsive

	@Input()
	public gap?: RowGap | RowGapResponsive

	@Input()
	public wrap?: RowWrap | RowWrapResponsive

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setAlignX()
		this._setAlignY()
		this._setRowGap()
		this._setWrap()
	}

	@HostListener('document:resize-x', ['$event'])
	public onResizeX() {
		this._setRowGap()
	}

	// Private Methods
	// ----------------------------------------

	private _setAlignX() {
		if (!this.alignX) return

		if (typeof this.alignX === 'string') {
			this.renderer.addClass(this.el, `justify-content-${this.alignX}`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.alignX)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `justify-content-${value}${infix}`)
		}
	}

	private _setAlignY() {
		if (!this.alignY) return

		if (typeof this.alignY === 'string') {
			this.renderer.addClass(this.el, `align-items-${this.alignY}`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.alignY)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `align-items-${value}${infix}`)
		}
	}

	private _setRowGap() {
		if (!this.gap) return

		if (typeof this.gap === 'string' || typeof this.gap === 'number') {
			this.el.style.setProperty('--gap-x', this._convertRowGap(this.gap))
			this.el.style.setProperty('--gap-y', this._convertRowGap(this.gap))
			return
		}

		for (const [breakpoint, value] of Object.entries(BREAKPOINTS).reverse()) {
			if (!(breakpoint in this.gap)) continue
			if (!window.matchMedia(`(min-width: ${value}px)`).matches) continue

			const gap = this.gap[breakpoint as keyof Breakpoints]

			if (gap === undefined) continue

			this.el.style.setProperty('--gap-x', this._convertRowGap(gap))
			this.el.style.setProperty('--gap-y', this._convertRowGap(gap))
			return
		}
	}

	private _convertRowGap(value: RowGap) {
		return typeof value === 'string' ? value : `${value}px`
	}

	private _setWrap() {
		if (!this.wrap) return

		if (typeof this.wrap === 'boolean') {
			this.renderer.addClass(this.el, `flex-nowrap`)
			return
		}

		for (const [breakpoint, value] of Object.entries(this.wrap)) {
			const infix = getClassInfix(breakpoint)
			this.renderer.addClass(this.el, `flex-nowrap${infix}`)
		}
	}
}
