import { MenuService } from '../../services/menu.service'
import { NavDropdownComponent } from '../nav-dropdown/nav-dropdown.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router } from '@angular/router'
import { isLG } from '@utils/breakpoints'

@Component({
	selector: 'ms-nav-link',
	host: {
		class: 'nav-link',
	},
	template: `
		<button class="nav-link__btn" type="button" (click)="onClick()">
			@if (icon) {
				<ms-icon
					class="nav-link__icon"
					[name]="icon"
					[tooltip-disabled]="!collapsed"
					[tooltip-placement]="'right'"
					[tooltip]="label"
				/>
			}
			<span class="nav-link__label">{{ label }}</span>
		</button>
	`,
	styleUrls: ['./nav-link.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLinkComponent extends BaseComponent implements OnInit {
	public collapsed = false

	@Input()
	public icon?: string

	@Input()
	public label: string

	@Input()
	public navDropdown?: NavDropdownComponent

	@Output()
	public navLinkClicked = new EventEmitter()

	@Input()
	public path?: string

	constructor(
		private _router: Router,
		public menuService: MenuService,
	) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		//prettier-ignore
		this.menuService.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) => {
				this.collapsed = collapsed;

				this.collapsed
					? this.renderer.addClass(this.el, 'nav-link--collapsed')
					: this.renderer.removeClass(this.el, 'nav-link--collapsed');

				this.cdRef.detectChanges()
			});
	}

	// Public Methods
	// ----------------------------------------

	public onClick() {
		this.navLinkClicked.emit()

		if (this.path) {
			this._router.navigateByUrl(this.path)
		}
	}
}
