import { ColComponent } from './components/col/col.component'
import { RowComponent } from './components/row/row.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [ColComponent, RowComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class GridModule {}
