import {
	animate,
	animation,
	style,
	transition,
	trigger,
} from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Output,
	ViewEncapsulation,
} from '@angular/core'

// prettier-ignore
const rippleAnimation = trigger('rippleAnimation', [
	transition(':enter', animation([
		style({opacity: 1, transform: 'translate(-50%, -50%) scale(0)'}),
		animate(
			'600ms ease',
			style({opacity: 0, transform: 'translate(-50%, -50%) scale(4)'})
		)
	]))
]);

@Component({
	selector: 'ms-ripple',
	host: {
		'class': 'ripple',
		'[style.top]': 'top',
		'[style.left]': 'left',
		'[style.width]': 'size',
		'[style.height]': 'size',
	},
	template: ``,
	styleUrls: ['./ripple.component.scss'],
	animations: [rippleAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RippleComponent {
	public top: string

	public left: string

	public size: string

	@Output()
	public rippleStart: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	public rippleDone: EventEmitter<any> = new EventEmitter<any>()

	constructor() {}

	// Public Methods
	// ----------------------------------------

	@HostBinding('@rippleAnimation')
	public rippleAnimation() {
		return true
	}

	@HostListener('@rippleAnimation.start')
	public rippleAnimationStart() {
		this.rippleStart.emit()
	}

	@HostListener('@rippleAnimation.done')
	public rippleAnimationDone() {
		this.rippleDone.emit()
	}
}
