import { MenuService } from '../../services/menu.service'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-menu-hamburger',
	host: {
		'class': 'menu-hamburger',
		'[class.menu-hamburger--active]': 'active',
	},
	template: `
		<button class="menu-hamburger__btn" type="button" (click)="toggle()">
			<span aria-hidden class="menu-hamburger__lines"></span>
		</button>
	`,
	styleUrls: ['./menu-hamburger.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHamburgerComponent
	extends BaseComponent
	implements AfterViewInit
{
	public active = false

	constructor(private _menu: MenuService) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterViewInit() {
		// prettier-ignore
		this._menu.overlayMenu?.active$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((state) => {
				this.active = state;
			});
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		this._menu.overlayMenu?.toggle()
	}
}
