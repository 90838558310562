import { TooltipComponent } from '../components/tooltip/tooltip.component'
import { BaseDirective } from '@abstract/base.directive'
import {
	ChangeDetectorRef,
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core'
import { Placement, Strategy } from '@floating-ui/dom'

@Directive({
	selector: '[tooltip]',
})
export class TooltipDirective
	extends BaseDirective
	implements OnInit, OnChanges, OnDestroy
{
	@Input('tooltip')
	public label: string

	@Input('tooltip-offset')
	public offset = [0, 6]

	@Input('tooltip-placement')
	public placement: Placement = 'top'

	@Input('tooltip-disabled')
	public disabled = false

	public tooltip?: ComponentRef<TooltipComponent>

	constructor(
		private _vcRef: ViewContainerRef,
		public cdRef: ChangeDetectorRef,
		public elRef: ElementRef,
		public renderer: Renderer2,
	) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (
			this.el instanceof HTMLAnchorElement ||
			this.el instanceof HTMLButtonElement
		) {
			this.renderer.setAttribute(this.el, 'aria-label', this.label)
		}
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (!this.tooltip) return

		if (changes.label) {
			this.tooltip.instance.label = changes.label.currentValue
			// this.tooltip.instance.update()
		}
	}

	public ngOnDestroy() {
		this._destroyTooltip()
		this._vcRef.clear()
	}

	@HostListener('mouseenter')
	@HostListener('focus')
	public onMouseEnter() {
		this._createTooltip()
	}

	@HostListener('mouseleave')
	@HostListener('blur')
	public onMouseLeave() {
		this._destroyTooltip()
	}

	// Private Methods
	// ----------------------------------------

	private _createTooltip() {
		if (this.tooltip || this.disabled) return

		this.tooltip = this._vcRef.createComponent(TooltipComponent)
		this.tooltip.instance.label = this.label
		this.tooltip.instance.offset = this.offset
		this.tooltip.instance.placement = this.placement
		this.tooltip.instance.target = this.el

		this.renderer.setAttribute(
			this.el,
			'aria-describedby',
			this.tooltip.instance.id,
		)
	}

	private _destroyTooltip() {
		if (!this.tooltip) return

		this.tooltip.destroy()
		this.tooltip = undefined

		this.renderer.removeAttribute(this.el, 'aria-describedby')
	}
}
