import { PlatformModule } from '@angular/cdk/platform'
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
} from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AppComponent } from '@app/app.component'
import { AppRoutesModule } from '@app/app.routes'
import { VerifyUserModule } from '@common/verify-user/verify-user.module'
import { environment } from '@environments/environment'
import { AuthInterceptor } from '@interceptors/auth.interceptor'
import { CacheInterceptor } from '@interceptors/cache.interceptor'
import { CompressionInterceptor } from '@interceptors/compression.interceptor'
import { SharedModule } from '@modules/shared.module'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import * as Sentry from '@sentry/angular-ivy'

// ng-translate - AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http)
}

// Default providers
const providers: NgModule['providers'] = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: CacheInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: CompressionInterceptor,
		multi: true,
	},
]

// Production providers
if (environment.enableProdMode) {
	providers.push({
		provide: ErrorHandler,
		useValue: Sentry.createErrorHandler({
			showDialog: false,
		}),
	})

	providers.push({
		provide: Sentry.TraceService,
		deps: [Router],
	})

	providers.push({
		provide: APP_INITIALIZER,
		useFactory: () => () => {},
		deps: [Sentry.TraceService],
		multi: true,
	})
}

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutesModule,
		PlatformModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.enableWorker,
			registrationStrategy: 'registerImmediately',
		}),
		SharedModule,
		VerifyUserModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers,
})
export class AppModule {}
