import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-icon-filter',
	host: {
		'class': 'icon-filter',
		'[class.icon-filter--active]': 'active',
	},
	template: `
		<span></span>
		<span></span>
		<span></span>
	`,
	styleUrls: ['./icon-filter.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFilterComponent {
	@Input()
	public active = false
}
