import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-btn-group',
	host: {
		class: 'btn-group',
		role: 'group',
	},
	template: `<ng-content />`,
	styleUrls: ['./group.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent extends BaseComponent {}
