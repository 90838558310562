import { FormGroupState } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	EventEmitter,
	HostListener,
	Input,
	Optional,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AbstractControl, NgForm, NgModel } from '@angular/forms'
import { isPlainObject } from 'lodash-es'
import { BehaviorSubject, Observable, interval } from 'rxjs'

@Component({
	selector: 'ms-form-group',
	host: {
		class: 'form-group',
	},
	template: `
		<label class="form-group__wrapper">
			<ng-content select="ms-visually-hidden, [visually-hidden]"></ng-content>

			@if (label) {
				<div class="form-group__label">
					<ms-label [required]="required">{{ label }}</ms-label>
				</div>
			}

			<div class="form-group__control">
				<ng-content />

				@if (enableClearBtn && value) {
					<div class="form-group__btn">
						<button
							btn-color="brand"
							btn-link-icon
							type="button"
							[disabled]="disabled"
							(click)="clear()"
						>
							<ms-icon name="close" />
						</button>
					</div>
				}
			</div>

			@if ((messages$ | async)?.length) {
				<div class="form-group__messages">
					<ms-form-messages [messages$]="messages$"></ms-form-messages>
				</div>
			}
		</label>
	`,
	styleUrls: ['./form-group.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent
	extends BaseComponent
	implements AfterContentInit
{
	@ContentChild(NgModel)
	public ngModel?: NgModel

	@Input()
	public label?: string

	@Input()
	public validate = true

	@Input()
	public enableClearBtn = false

	@Output()
	public onUpdate = new EventEmitter()

	public messages$ = new BehaviorSubject<string[]>([])

	public autofill$?: Observable<number>

	// Computed Properties
	// ----------------------------------------

	public get autofill() {
		return this.inputEl?.matches(':-webkit-autofill') || false
	}

	public get disabled() {
		return this.ngModel?.isDisabled || false
	}

	public get dirty() {
		return (this.validate && this.ngModel?.control?.dirty) || false
	}

	public get empty() {
		const value = this.ngModel?.value || this.inputEl?.value
		return !value && value !== 0
	}

	public get errors() {
		if ((this.submitted || this.dirty) && this.ngModel?.errors) {
			return this.ngModel.errors
		} else {
			return {}
		}
	}

	public get inputEl() {
		return this.el.querySelector<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>('input, select, textarea')
	}

	public get invalid() {
		return (this.validate && this.ngModel?.control?.invalid) || false
	}

	public get messages() {
		return this.messages$.value
	}

	public set messages(value) {
		this.messages$.next([...value])
	}

	public get pristine() {
		return (this.validate && this.ngModel?.control?.pristine) || false
	}

	public get required() {
		if (this.ngModel?.validator) {
			const validator = this.ngModel.validator({} as AbstractControl)
			return validator && validator.required
		} else {
			return false
		}
	}

	public get state(): FormGroupState {
		return {
			autofill: this.autofill,
			dirty: this.dirty,
			empty: this.empty,
			invalid: this.invalid,
			pristine: this.pristine,
			submitted: this.submitted,
			touched: this.touched,
			untouched: this.untouched,
			valid: this.valid,
		}
	}

	public get submitted() {
		return (this.validate && this.ngForm?.submitted) || false
	}

	public get touched() {
		return (this.validate && this.ngModel?.control?.touched) || false
	}

	public get untouched() {
		return (this.validate && this.ngModel?.control?.untouched) || false
	}

	public get valid() {
		return (this.validate && this.ngModel?.control?.valid) || false
	}

	public get value() {
		return this.ngModel?.value || this.inputEl?.value
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(@Optional() public ngForm: NgForm) {
		super()
	}

	public ngAfterContentInit() {
		this.updateClasses()

		this.ngForm?.ngSubmit
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.updateClasses()
				this.updateErrors()
				this.onUpdate.emit()
			})

		this.ngModel?.statusChanges
			?.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.updateClasses()
				this.updateErrors()
				this.onUpdate.emit()
			})

		if (this.inputEl) {
			// Update classes when browser auto-fills the input
			if (this.inputEl.hasAttribute('autocomplete')) {
				this.autofill$ = interval(100).pipe(takeUntilDestroyed(this.destroyRef))

				const sub$ = this.autofill$.subscribe(() => {
					if (!this.inputEl?.matches(':-webkit-autofill')) return

					this.updateClasses()
					sub$.unsubscribe()
				})
			}

			// Manually update classes if ngModel is not set
			if (!this.ngModel) {
				this.renderer.listen(this.inputEl, 'change', (e: Event) => {
					this.updateClasses()
					this.onUpdate.emit()
				})
			}
		}
	}

	@HostListener('focusin', ['$event.target'])
	public onFocusIn(target: HTMLElement) {
		if (
			['input', 'select', 'textarea'].includes(target.tagName.toLowerCase())
		) {
			this.renderer.addClass(this.el, 'form-group--focused')
		}
	}

	@HostListener('focusout', ['$event.target'])
	public onFocusOut() {
		this.renderer.removeClass(this.el, 'form-group--focused')
	}

	// Public Methods
	// ----------------------------------------

	public updateClasses() {
		for (const [key, value] of Object.entries(this.state)) {
			value
				? this.renderer.addClass(this.el, `form-group--${key}`)
				: this.renderer.removeClass(this.el, `form-group--${key}`)
		}
	}

	public updateErrors() {
		const messages: string[] = []

		for (let [key, value] of Object.entries(this.errors)) {
			if (isPlainObject(value) && value.message) {
				messages.push(value.message)
				continue
			}

			switch (key) {
				case 'required':
					messages.push(this.$t('validators.required'))
					break

				default:
					messages.push(this.$t('validators.invalid'))
					break
			}
		}

		this.messages = messages
	}

	public clear() {
		this.ngModel?.reset(null)

		if (this.inputEl) {
			this.inputEl.value = ''
		}
	}
}
