import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-table-select',
	template: `
		<ms-radio class="table-select">
			<ms-visually-hidden>
				{{ 'components.table.select_row' | translate }}
			</ms-visually-hidden>

			<input
				name="select-row"
				type="checkbox"
				[checked]="isChecked"
				[disabled]="disabled"
				(click)="onClick($event)"
			/>
		</ms-radio>
	`,
	styleUrls: ['./table-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSelectComponent extends BaseComponent implements OnInit {
	@Input()
	public disabled = false

	@Input()
	public row: any

	@Input()
	public rowIndex: number

	// Computed Properties
	// ----------------------------------------

	public get isChecked() {
		return this.pTable?.isSelected(this.row)
	}

	public get pTable() {
		return this.tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(public tableService: TableService) {
		super()
	}

	public ngOnInit() {
		if (!this.row) {
			throw new Error('TableSelectComponent: No row provided')
		}

		if (!this.rowIndex && this.rowIndex !== 0) {
			throw new Error('TableSelectComponent: No rowIndex provided')
		}

		this.pTable?.tableService.selectionSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}

	// Public Methods
	// ----------------------------------------

	public onClick(event: Event) {
		if (this.disabled) return

		this.pTable?.toggleRowWithCheckbox(
			{
				originalEvent: event,
				rowIndex: this.rowIndex,
			},
			this.row,
		)
	}
}
