import { TableService } from '../../services/table.service'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-table-empty',
	template: `
		<tr tableRow [rowIndex]="0">
			<td tableCol [attr.colspan]="colSpan">
				{{ 'components.table.empty' | translate }}
			</td>
		</tr>
	`,
	styles: [
		`
			ms-table-empty {
				display: contents;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableEmptyComponent {
	@Input()
	public columnCount = 1

	// Computed Properties
	// ----------------------------------------

	public get colSpan() {
		return (
			this.columnCount +
			(this.enableExpand ? 1 : 0) +
			(this.enableSelect ? 1 : 0)
		)
	}

	public get enableExpand() {
		return this._tableService.isRowExpandEnabled
	}

	public get enableSelect() {
		return this._tableService.isRowSelectEnabled
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(private _tableService: TableService) {}
}
