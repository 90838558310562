import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ContentChildren,
	Input,
	OnInit,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'ms-step',
	host: {
		'class': 'step',
		'[class.step--active]': 'active',
	},
	template: `
		@if (active$ | async) {
			<ng-content />
			@if (tempContent) {
				<template [ngTemplateOutlet]="tempContent"></template>
			}
		}
	`,
	styleUrls: ['./step.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent implements OnInit, AfterContentInit {
	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates?: QueryList<NamedTemplateDirective>

	@Input()
	public name: string

	public active$ = new BehaviorSubject(false)

	public tempContent?: TemplateRef<any>

	constructor(private _cdRef: ChangeDetectorRef) {}

	// Computed Properties
	// --------------------------------------------------

	public get active() {
		return this.active$.value
	}

	@Input()
	public set active(value) {
		this.active$.next(value)
		this._cdRef.markForCheck()
	}

	// Lifecycle Methods
	// --------------------------------------------------

	public ngOnInit() {
		if (!this.name) {
			throw new Error('StepComponent: name property is required.')
		}
	}

	public ngAfterContentInit() {
		this._templates?.forEach((item) => {
			switch (item.name) {
				case 'content':
					this.tempContent = item.template
					break
			}
		})
	}
}
