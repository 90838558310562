import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { SessionService } from '@services/session.service'

@Injectable({
	providedIn: 'root',
})
export class IsAuthenticatedGuard {
	router = inject(Router)

	sessionService = inject(SessionService)

	public canActivate({ queryParams }: ActivatedRouteSnapshot) {
		if (this.sessionService.isLoggedIn()) return true

		this.router.navigate(['/login'], {
			queryParams,
		})

		return false
	}
}

// ?u=test
