import { MenuService } from '../../services/menu.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-menu-collapse',
	host: {
		class: 'menu-collapse',
	},
	template: `
		<ms-nav-link
			icon="menu-collapse"
			[label]="$t('common.collapse')"
			(navLinkClicked)="toggle()"
		></ms-nav-link>
	`,
	styleUrls: ['./menu-collapse.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCollapseComponent extends BaseComponent implements OnInit {
	constructor(private _menu: MenuService) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._menu.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) => {
				collapsed
					? this.renderer.addClass(this.el, 'menu-collapse--active')
					: this.renderer.removeClass(this.el, 'menu-collapse--active')
			})

		this.translate.onLangChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		this._menu.toggle()
	}
}
