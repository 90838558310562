@if (active$ | async) {
	<ms-backdrop
		[@backdropAnimation]
		class="modal__backdrop"
		(backdropClick)="close()"
	/>

	<article
		[@modalAnimation]
		class="modal__dialog"
		(@modalAnimation.done)="onAnimationDone($event)"
		(@modalAnimation.start)="onAnimationStart($event)"
	>
		<div class="modal__content">
			@if (tempHeader) {
				<header class="modal__header">
					<ng-container [ngTemplateOutlet]="tempHeader" />
				</header>
			}

			<main class="modal__body">
				<ng-content />
				@if (tempContent) {
					<ng-container [ngTemplateOutlet]="tempContent" />
				}
			</main>

			@if (tempFooter) {
				<footer class="modal__footer">
					<ng-container [ngTemplateOutlet]="tempFooter" />
				</footer>
			}
		</div>

		<div [@closeAnimation] class="modal__close">
			<button
				btn-color="brand"
				btn-solid-icon
				type="button"
				[attr.aria-label]="$t('common.close')"
				(click)="close()"
			>
				<ms-icon name="close" />
			</button>
		</div>
	</article>
}
