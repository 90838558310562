import { MenuService } from '../../services/menu.service'
import { NavItem } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import { transition, trigger, useAnimation } from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { collapse, expand } from '@animations/collapse-expand.animation'
import { uniqueId } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

const dropdownAnimation = trigger('dropdownAnimation', [
	transition(
		':leave',
		useAnimation(collapse, { params: { timings: '300ms ease' } }),
	),
	transition(
		':enter',
		useAnimation(expand, { params: { timings: '300ms ease' } }),
	),
])

@Component({
	selector: 'ms-nav-dropdown',
	host: {
		class: 'nav-dropdown',
	},
	template: `
		@if (active$ | async) {
			<div [@dropdownAnimation] class="nav-dropdown__body">
				<div class="nav-dropdown__content">
					<ms-nav [navItems]="navItems"></ms-nav>
				</div>
			</div>
		}
	`,
	styleUrls: ['./nav-dropdown.component.scss'],
	animations: [dropdownAnimation],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavDropdownComponent extends BaseComponent implements OnInit {
	public active$ = new BehaviorSubject(false)

	@Input()
	public id?: string

	@Input()
	public navItems: NavItem[]

	constructor(private _menu: MenuService) {
		super()
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		if (!this.id) {
			this.id = uniqueId('nav-dropdown-')
			this.renderer.setAttribute(this.el, 'id', this.id)
		}

		// prettier-ignore
		this._menu.collapsed$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((collapsed) => {
				if (collapsed) this.close();
			});
	}

	// Public Methods
	// ----------------------------------------

	public toggle() {
		if (this.active$.value) {
			this.close()
		} else {
			this.open()
		}
	}

	public open() {
		if (this._menu.collapsed) return

		this.active$.next(true)
	}

	public close() {
		this.active$.next(false)
	}
}
