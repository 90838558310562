import { Injectable, inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
	providedIn: 'root',
})
export class i18nService {
	public translate = inject(TranslateService)

	public dateFormatOptions = [
		'dd MMM yyyy',
		'MMM dd yyyy',
		'dd/MM/yy',
		'MM/dd/yy',
	]

	// Computed Properties
	// --------------------------------------------------

	public get dateFormat() {
		return localStorage.getItem('date-format')
	}

	public set dateFormat(format) {
		if (format) {
			localStorage.setItem('date-format', format)
		} else {
			localStorage.removeItem('date-format')
		}
	}

	public get language() {
		return localStorage.getItem('language')
	}

	public set language(language) {
		if (language) {
			this.translate.use(language)
			localStorage.setItem('language', language)
		} else {
			this.translate.use('en')
			localStorage.removeItem('language')
		}
	}

	public get languageOptions() {
		return [
			{ name: this.translate.instant('common.language_de'), value: 'de' },
			{ name: this.translate.instant('common.language_en'), value: 'en' },
			{ name: this.translate.instant('common.language_fr'), value: 'fr' },
			{ name: this.translate.instant('common.language_hu'), value: 'hu' },
		]
	}

	public get massUnit() {
		return localStorage.getItem('mass-unit')
	}

	public set massUnit(unit) {
		if (unit) {
			localStorage.setItem('mass-unit', unit)
		} else {
			localStorage.removeItem('mass-unit')
		}
	}

	public get massUnitOptions() {
		return [
			{ name: this.translate.instant('common.mass_unit_kg'), value: 'kg' },
			{ name: this.translate.instant('common.mass_unit_lb'), value: 'lb' },
		]
	}

	// Public Methods
	// --------------------------------------------------

	public init() {
		const langCodes = this.languageOptions.map((option) => option.value)

		this.translate.addLangs(langCodes)
		this.translate.setDefaultLang('en')

		if (!this.dateFormat || !this.dateFormatOptions.includes(this.dateFormat)) {
			this.dateFormat = this.dateFormatOptions[0]
		}

		if (!this.language) {
			const browserLang = this.translate.getBrowserLang()

			this.language =
				browserLang && new RegExp(langCodes.join('|')).test(browserLang)
					? browserLang
					: 'en'
		} else {
			this.translate.use(this.language)
		}

		if (!this.massUnit) {
			this.massUnit = 'kg'
		}
	}
}
