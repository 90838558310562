import { SortEvent, SortOption, SortOrder } from './types'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-sort',
	host: {
		class: 'sort',
	},
	template: `
		<button btn-color="brand" btn-link type="button" [dropdown]="menu">
			<ms-icon name="sort" />
			{{ 'common.sort_by' | translate }}
		</button>

		<ms-dropdown #menu [closeOnClickInside]="true">
			@for (option of options; track option) {
				<button
					dropdown-menu-item
					type="button"
					[active]="prop === option.prop && order === option.order"
					(click)="sort(option)"
				>
					{{ option.label | translate }}
				</button>
			}
		</ms-dropdown>
	`,
	styleUrls: ['./sort.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortComponent extends BaseComponent {
	@Input()
	public options?: SortOption[]

	@Input()
	public order?: SortOrder

	@Input()
	public prop?: string

	@Output()
	public onSort = new EventEmitter<SortEvent>()

	// Public Methods
	// ----------------------------------------

	public sort(option: SortOption) {
		this.onSort.emit({
			order: option.order,
			prop: option.prop,
		})
	}
}
