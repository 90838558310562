import { RippleComponent } from '../components/ripple.component'
import {
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	Renderer2,
	ViewContainerRef,
} from '@angular/core'
import { Subscription } from 'rxjs'

@Directive({
	selector: '[ripple]',
})
export class RippleDirective implements OnDestroy {
	private _ripple?: ComponentRef<RippleComponent>

	private _rippleDone$?: Subscription

	@Input()
	public disabled = false

	constructor(
		private _renderer: Renderer2,
		private _vcRef: ViewContainerRef,
		public elRef: ElementRef,
	) {}

	public get el(): HTMLElement {
		return this.elRef.nativeElement
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnDestroy() {
		this.destroyRipple()
	}

	// Public Methods
	// ----------------------------------------

	@HostListener('click', ['$event'])
	public createRipple(e: MouseEvent) {
		if (this.disabled) return

		const rect = this.el.getBoundingClientRect()
		const diameter = Math.max(rect.width, rect.height)

		this.destroyRipple()

		this._ripple = this._vcRef.createComponent(RippleComponent)
		this._ripple.instance.top = `${e.clientY - rect.top}px`
		this._ripple.instance.left = `${e.clientX - rect.left}px`
		this._ripple.instance.size = `${diameter}px`

		this._renderer.appendChild(this.el, this._ripple.location.nativeElement)

		this._rippleDone$ = this._ripple.instance.rippleDone.subscribe(() => {
			// this.destroyRipple();
		})
	}

	public destroyRipple() {
		this._rippleDone$?.unsubscribe()
		this._ripple?.destroy()
		this._ripple = undefined
	}
}
