import { StepperComponent } from '../stepper/stepper.component'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-stepper-controls',
	host: {
		class: 'stepper-controls',
	},
	template: `
		@if (!hideReset && !stepper?.isFirstStep) {
			<button
				btn-solid
				type="button"
				[disabled]="loading"
				(click)="onReset.emit()"
			>
				{{ resetLabel }}
			</button>
		}
		@if (!hidePrev && !stepper?.isFirstStep) {
			<button
				btn-color="brand"
				btn-outline
				type="button"
				[disabled]="loading"
				(click)="onPrev.emit()"
			>
				{{ prevLabel }}
			</button>
		}
		@if (!hideNext) {
			<button
				btn-color="success"
				btn-solid
				type="button"
				[disabled]="loading"
				[loading]="loading"
				(click)="onNext.emit()"
			>
				{{ nextLabel }}
			</button>
		}
	`,
	styleUrls: ['./stepper-controls.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperControlsComponent extends BaseComponent implements OnInit {
	@Input()
	public hideNext = false

	@Input()
	public hidePrev = false

	@Input()
	public hideReset = false

	@Input()
	public stepper?: StepperComponent

	@Input()
	public nextLabel = this.$t('common.next')

	@Input()
	public prevLabel = this.$t('common.previous')

	@Input()
	public resetLabel = this.$t('common.reset')

	@Input()
	public loading = false

	@Output()
	public onNext = new EventEmitter()

	@Output()
	public onPrev = new EventEmitter()

	@Output()
	public onReset = new EventEmitter()

	// Lifecycle Methods
	// --------------------------------------------------

	public ngOnInit() {
		this.stepper?.onStepChange
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}
}
