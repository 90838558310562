import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
	selector: 'ms-table-select-all',
	template: `
		<ms-radio class="table-select-all">
			<ms-visually-hidden>
				{{ 'components.table.select_all_rows' | translate }}
			</ms-visually-hidden>

			<input
				name="select-all"
				type="checkbox"
				[checked]="isChecked"
				[disabled]="disabled"
				(click)="onClick($event)"
			/>
		</ms-radio>
	`,
	styleUrls: ['./table-select-all.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSelectAllComponent extends BaseComponent implements OnInit {
	@Input()
	public disabled = false

	// Computed Properties
	// ----------------------------------------

	public get isChecked() {
		return this.pTable?.selection?.length >= 1
	}

	public get pTable() {
		return this.tableService.pTable
	}

	// Lifecycle Methods
	// ----------------------------------------

	constructor(public tableService: TableService) {
		super()
	}

	public ngOnInit() {
		this.pTable?.tableService.selectionSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdRef.markForCheck()
			})
	}

	// Public Properties
	// ----------------------------------------

	public onClick(event: Event) {
		if (this.disabled) return
		if (!this.pTable) return

		if (this.pTable.value && this.pTable.value.length > 0) {
			this.pTable.toggleRowsWithCheckbox(event, !this.isChecked)
		}
	}
}
