import { BaseDirective } from '@abstract/base.directive'
import { Directive, OnInit } from '@angular/core'
import {
	NG_VALIDATORS,
	UntypedFormControl,
	ValidationErrors,
	Validator,
} from '@angular/forms'
import { isEmpty } from '@utils/is-empty'

@Directive({
	selector: 'input[numeric]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: InputNumericDirective,
			multi: true,
		},
	],
})
export class InputNumericDirective
	extends BaseDirective<HTMLInputElement>
	implements Validator, OnInit
{
	public readonly pattern = /^-?\d+(\.\d+)?$/

	public readonly allowedKeys = [
		'Backspace',
		'Delete',
		'ArrowLeft',
		'ArrowRight',
		'Tab',
		'Enter',
	]

	public ngOnInit() {
		this.el.type = 'text'

		this.el.inputMode = 'numeric'

		this.el.addEventListener('keydown', (e: KeyboardEvent) => {
			if (RegExp(/[0-9.\-]/).test(e.key) || this.allowedKeys.includes(e.key))
				return
			e.preventDefault()
		})
	}

	public validate(control: UntypedFormControl): ValidationErrors | null {
		if (!control.root) return null
		if (isEmpty(control.value)) return null
		if (this.pattern.test(control.value)) return null

		return {
			numeric: {
				message: 'The value must be a valid number.',
			},
		}
	}
}
