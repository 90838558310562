<div class="menu__content">
	<a class="menu__logo" [routerLink]="['/']">
		<ms-logo class="d-none d-block:lg" [collapsed]="collapsed" />
		<ms-text-logo class="d-none:lg" />
	</a>

	<div class="menu__nav">
		<ms-menu-nav />
	</div>

	<ms-menu-collapse />

	<div class="menu__toggle">
		<ms-menu-title />

		<ms-menu-hamburger />
	</div>
</div>

<ms-drawer #overlayMenu class="mobile-menu">
	<ng-template name="content">
		<ms-btn-toolbar class="mb-3" position="end">
			<ms-search />

			<a
				btn-link-icon
				[routerLink]="['/profile']"
				[tooltip]="$t('common.profile')"
			>
				<ms-icon name="menu-account" />
			</a>

			<button
				btn-link-icon
				type="button"
				[tooltip]="$t('common.logout')"
				(click)="logout()"
			>
				<ms-icon name="menu-logout" />
			</button>
		</ms-btn-toolbar>

		<ms-menu-nav />
	</ng-template>
</ms-drawer>
