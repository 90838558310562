import { AlertComponent } from '.'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { A11yModule } from '@components/a11y/a11y.module'
import { ButtonModule } from '@components/button/button.module'
import { IconModule } from '@components/icon/icon.module'
import { ProgressBarModule } from '@components/progress-bar/progress-bar.module'

const components = [AlertComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		A11yModule,
		ButtonModule,
		IconModule,
		ProgressBarModule,
	],
})
export class AlertModule {}
