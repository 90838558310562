import { environment } from '@environments/environment'

export function logInfo(...values: any[]) {
	if (!environment.enableProdMode) console.info(...values)
}

export function log(...values: any[]) {
	if (!environment.enableProdMode) console.log(...values)
}

export function logWarning(...values: any[]) {
	if (!environment.enableProdMode) console.warn(...values)
}

export function logError(...values: any[]) {
	if (!environment.enableProdMode) console.error(...values)
}
