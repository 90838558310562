<button
	btn-color="none"
	btn-link-icon
	class="table-stat-picker"
	type="button"
	[dropdown]="menu"
	[tooltip]="$t('components.table.statistics')"
>
	<ms-icon name="settings" />
</button>

<ms-dropdown
	#menu
	placement="top-start"
	strategy="absolute"
	[closeOnClickInside]="true"
	[closeOnClickOutside]="true"
	[offset]="[0, 12]"
>
	<button dropdown-menu-item [active]="calc === 'sum'" (click)="calc = 'sum'">
		{{ 'common.sum' | translate }}
	</button>

	<button dropdown-menu-item [active]="calc === 'avg'" (click)="calc = 'avg'">
		{{ 'common.average' | translate }}
	</button>

	<button dropdown-menu-item [active]="calc === 'min'" (click)="calc = 'min'">
		{{ 'common.minimum' | translate }}
	</button>

	<button dropdown-menu-item [active]="calc === 'max'" (click)="calc = 'max'">
		{{ 'common.maximum' | translate }}
	</button>

	<button dropdown-menu-item [active]="calc === 'rng'" (click)="calc = 'rng'">
		{{ 'common.range' | translate }}
	</button>

	<button dropdown-menu-item [active]="calc === 'cnt'" (click)="calc = 'cnt'">
		{{ 'common.count' | translate }}
	</button>
</ms-dropdown>
