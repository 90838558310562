import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-alert',
	host: {
		class: 'alert',
		role: 'alert',
	},
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent extends BaseComponent implements OnInit {
	@Input()
	public theme: 'success' | 'brand' | 'warning' | 'danger' = 'brand'

	@Input()
	public dismissable = false

	@Input()
	public icon?: string

	@Input()
	public timer?: number

	@Output()
	public alertDismissed: EventEmitter<any> = new EventEmitter<any>()

	public progress = 100

	public interval?: number

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
		this._startCountdown()
	}

	// Public Methods
	// ----------------------------------------

	public dismiss() {
		clearInterval(this.interval)
		this.alertDismissed.emit()
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		this.renderer.addClass(this.el, `alert--${this.theme}`)
		this.timer
			? this.renderer.addClass(this.el, 'alert--timer')
			: this.renderer.removeClass(this.el, 'alert--timer')
	}

	private _startCountdown() {
		if (!this.timer) return

		let time = 0

		const target = this.timer || 0

		this.interval = setInterval(() => {
			time += 10

			this.progress = (1 - time / target) * 100

			if (time >= target) {
				this.dismiss()
			}

			this.cdRef.detectChanges()
		}, 10)
	}
}
