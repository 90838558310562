import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
	input,
	model,
} from '@angular/core'

@Component({
	selector: 'ms-range',
	host: {
		class: 'range',
	},
	template: `
		<ms-control class="range-value" [validate]="false">
			<ms-visually-hidden>Min</ms-visually-hidden>

			<input
				type="number"
				[ngModel]="values()[0]"
				(ngModelChange)="onMinChange($event)"
			/>
		</ms-control>

		<p-slider
			[max]="max()"
			[min]="min()"
			[range]="true"
			[step]="step()"
			[(ngModel)]="values"
		/>

		<ms-control class="range-value" [validate]="false">
			<ms-visually-hidden>Max</ms-visually-hidden>

			<input
				type="number"
				[ngModel]="values()[1]"
				(ngModelChange)="onMaxChange($event)"
			/>
		</ms-control>
	`,
	styleUrls: ['./range.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeComponent extends BaseComponent {
	min = input.required<number>()

	max = input.required<number>()

	step = input(1)

	values = model.required<[number, number]>()

	onMinChange(val: number) {
		const max = this.values()[1]
		const min = this.min()

		if (val < min) val = min
		if (val > max) val = max

		this.values.set([val, max])
	}

	onMaxChange(val: number) {
		const min = this.values()[0]
		const max = this.max()

		if (val < min) val = min
		if (val > max) val = max

		this.values.set([min, val])
	}
}
