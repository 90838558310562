import { BaseComponent } from '../../abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core'

type SpinnerTheme = 'brand' | 'success' | 'info' | 'warning' | 'danger'

@Component({
	selector: 'ms-progress-spinner',
	host: {
		class: 'spinner',
	},
	template: `
		<div class="spinner__wrap">
			<svg class="spinner__svg" viewBox="25 25 50 50">
				<circle
					class="spinner__path"
					cx="50"
					cy="50"
					fill="none"
					r="20"
					stroke-miterlimit="10"
				/>
			</svg>
		</div>
	`,
	styleUrls: ['./progress-spinner.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerComponent extends BaseComponent implements OnInit {
	@Input()
	public theme?: SpinnerTheme

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		if (this.theme) {
			this.renderer.addClass(this.el, `spinner--${this.theme}`)
		}
	}
}
