import { Breakpoints } from '@models/util/Breakpoints'

export const BREAKPOINTS: Breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
}
