import { StatusIndicatorComponent } from './status-indicator.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [StatusIndicatorComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class StatusIndicatorModule {}
