import { BaseComponent } from '@abstract/base.component'
import { FormComponent } from '@abstract/form.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'
import { OverlayComponent } from '@components/overlay/components/overlay/overlay.component'

@Component({
	selector: 'ms-form-btns',
	host: {
		class: 'form-btns',
	},
	template: `
		@if (
			{ loading: form?.loading$ | async, disabled: overlay?.disabled$ | async };
			as state
		) {
			@switch (type) {
				@case ('delete') {
					<button
						btn-color="danger"
						btn-solid
						type="submit"
						[disabled]="!!state.loading"
						[loading]="!!state.loading"
						(click)="submit()"
					>
						{{ label ? label : ('common.delete' | translate) }}
					</button>
				}
				@case ('save') {
					<button
						btn-color="success"
						btn-solid
						type="submit"
						[disabled]="!!state.loading"
						[loading]="!!state.loading"
						(click)="submit()"
					>
						{{ label ? label : ('common.save' | translate) }}
					</button>
				}
			}
			@if (overlay) {
				<button
					btn-solid
					type="button"
					[disabled]="!!state.loading || !!state.disabled"
					(click)="overlay.close()"
				>
					{{ 'common.cancel' | translate }}
				</button>
			}
		}
	`,
	styleUrls: ['./form-btns.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonsComponent extends BaseComponent {
	@Input()
	public form?: FormComponent

	@Input()
	public overlay?: OverlayComponent

	@Input()
	public type: 'delete' | 'save' = 'save'

	@Input()
	public label?: string

	// Public Methods
	// ----------------------------------------

	public submit() {
		if (this.form) {
			this.form?.form.onSubmit(new Event('submit'))
		} else {
			throw new Error(`FormButtonsComponent: Form is undefined.`)
		}
	}
}
